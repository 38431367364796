import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import AccountForm from '../types/account-form.types'
import Agreement from './Agreement'
import { Lang, AllMarkdownRemark, Frontmatter } from '../types/common-types'
import { State, Dispatch, actions } from '../state-actions-reducers'
import AcceptConditionsImage from '../images/anketa_img_10.svg'

import {
  profileType_Physical,
  profileType_Fiscal,
} from '../state-actions-reducers'

import styles from './AcceptConditions.module.scss'
import { INSTANCE } from '../server-parameters'
import { getCity } from '../hooks/useGetCityCustomHook'
import LoadingScreen from '../Layouts/LoadingScreen'

interface Props {
  lang: Lang
  accountForm: AccountForm
  state: State
  dispatch: Dispatch
  city: string
}

interface AgreementData extends Frontmatter {
  html: string
}

export default function AcceptConditions({
  lang,
  accountForm,
  state,
  dispatch,
  city
}: Props) {
  const result = useStaticQuery<AllMarkdownRemark>(graphql`
    {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            html
            frontmatter {
              path
              type
              title
              instance
              lang
            }
          }
        }
      }
    }
  `)

  const agreements = result.allMarkdownRemark.edges.reduce(
    (agreementsList: AgreementData[], { node }) => {
      console.log(node)
      console.log('instance', INSTANCE)
      if (node.frontmatter.lang !== lang || node.frontmatter.instance !== INSTANCE || node.frontmatter.instance !== INSTANCE) {
        return agreementsList
      }
      console.log('node', node)
      return [...agreementsList, { ...node.frontmatter, html: node.html }]
    },
    []
  )
  const [agreementToShow, setAgreementToShow] = useState<AgreementData | null>()
  const [hilightUnread, setHilightUnread] = useState(false)

  const [profileType, setProfileTypeIsFiscal] = useState(
    state.profileType == profileType_Fiscal
  )
  const [HilightprofileType, setHilightprofileType] = useState(false)

  const header = accountForm.agreementHeader[lang]
  const description = accountForm.agreementsDescription[lang]
  const confurmLabel = accountForm.agreementButtonConfurmLabel[lang]
  const confurmLabel1 = accountForm.agreementButtonConfurmLabel1[lang]
  const confurmLabel2 = accountForm.agreementButtonConfurmLabel2[lang]

  //const confurmButton = "gjllld"
  const declineButton = accountForm.gotoMainPage[lang]
  const buttonTitle = accountForm.agreementShowBtnTip[lang]
  const buttonText = accountForm.agreementShowBtnTipText[lang]
  useEffect(()=> {

  }, [state.agreedCheck1, state.agreedCheck2, state.agreedCheck3])
  return (
    <div className="row">
      {!agreementToShow ? (
        <>
          <div className="col-5 col-xs-12">
            <h2 className={styles.header}>{header} {city}</h2>

            {/* <div className={`${styles.buttonsContainer} ${HilightprofileType ? styles.highlighted : ''}`}>

              <button
                onClick={() => {
                  setProfileTypeIsFiscal(false);  
                  dispatch({
                    type: actions.PROFILE_TYPE,
                    data: state.profileType == profileType_Physical ? '': profileType_Physical,
                  })          
                }}
                className={[styles.physicalButton, state.profileType == profileType_Physical ? styles.ButtonHover : ""].join(" ")}
              >
                {accountForm.profileType_PhysicalButton[lang]}
              </button>

              <button
                onClick={() => {
                  setProfileTypeIsFiscal(true);
                  dispatch({
                    type: actions.PROFILE_TYPE,
                    data: state.profileType == profileType_Fiscal ? '': profileType_Fiscal,
                  })
                }}
                className={[styles.fiscalButton, state.profileType == profileType_Fiscal ? styles.ButtonHover : ""].join(" ")}
              >
                {accountForm.profileType_FiscalButton[lang]}
              </button>
            </div> */}
            {
              //state.profileType ?
              <React.Fragment>
                {agreements.length === 1 ? (
                  <AgreementListItem
                    state={state}
                    buttonTitle={buttonTitle}
                    agreement={agreements[0]}
                    hilightUnread={hilightUnread}
                    setAgreementToShow={setAgreementToShow}
                    description={description}
                    buttonText={buttonText}
                  />
                ) : (
                  <>
                    <p>{description}:</p>
                    <ul className={styles.list}>
                      {agreements.map(agreement => {
                        const { type } = agreement
                        return (
                          <li key={type} className={styles.listItem}>
                            <AgreementListItem
                              state={state}
                              buttonTitle={buttonTitle}
                              agreement={agreements[0]}
                              hilightUnread={hilightUnread}
                              setAgreementToShow={setAgreementToShow}
                              buttonText={buttonText}
                            />
                          </li>
                        )
                      })}
                    </ul>
                  </>
                )}
                {INSTANCE === 'second' ? 
                  <div>
                    {/* <label className={state.agreedCheck1 ? styles.checked : styles.label}>
                      <input
                        type="checkbox"
                        checked={state.agreedCheck1}
                        className={styles.checkbox}
                        onChange={() => {
                          dispatch({
                            type: actions.AGREED_CHECK1,
                            data: !state.agreedCheck1,
                          })
                        }}
                      />
                      <span className={styles.confirmText}>{confurmLabel1} <a href="https://kino.rent/images/agreementPersonalData/soglasie-IP-Borisov-K.N.pdf" target='_blank'>{confurmLabel2}</a></span>
                    </label> */}
                    {/* <label className={state.agreedCheck2 ? styles.checked : styles.label}>
                      <input
                        type="checkbox"
                        checked={state.agreedCheck2}
                        className={styles.checkbox}
                        onChange={() => {
                          dispatch({
                            type: actions.AGREED_CHECK2,
                            data: !state.agreedCheck2,
                          })
                        }}
                      />
                      <span className={styles.confirmText}>{confurmLabel1} <a href="https://kino.rent/images/agreementPersonalData/soglasie-IP-Kapitonov-V.V.pdf" target='_blank'>{confurmLabel2}</a></span>
                    </label> */}
                    <label className={state.agreedCheck ? styles.checked : styles.label}>
                      <input
                        type="checkbox"
                        checked={state.agreedCheck}
                        className={styles.checkbox}
                        onChange={() => {
                          dispatch({
                            type: actions.AGREED_CHECK,
                            data: !state.agreedCheck,
                          })
                        }}
                      />
                      <span className={styles.confirmText}>{confurmLabel1} <a href="https://kino.rent/images/agreementPersonalData/soglasie-OOO-Rentals.pdf" target='_blank'>{confurmLabel2}</a></span>
                    </label>
                  </div> :
                  INSTANCE === 'msk' ?
                    <div>
                      <label className={state.agreedCheck1 ? styles.checked : styles.label}>
                        <input
                          type="checkbox"
                          checked={state.agreedCheck1}
                          className={styles.checkbox}
                          onChange={() => {
                            dispatch({
                              type: actions.AGREED_CHECK1,
                              data: !state.agreedCheck1,
                            })
                          }}
                        />
                        <span className={styles.confirmText}>{confurmLabel1} <a href="https://kino.rent/msk/images/agreementPersonalData/soglasie-OOO-Rentals_Msk.pdf" target='_blank'>{confurmLabel2}</a></span>
                      </label>
                      <label className={state.agreedCheck2 ? styles.checked : styles.label}>
                        <input
                          type="checkbox"
                          checked={state.agreedCheck2}
                          className={styles.checkbox}
                          onChange={() => {
                            dispatch({
                              type: actions.AGREED_CHECK2,
                              data: !state.agreedCheck2,
                            })
                          }}
                        />
                        <span className={styles.confirmText}>{confurmLabel1} <a href="https://kino.rent/msk/images/agreementPersonalData/soglasie-IP-Poperechnyuk-O.V.pdf" target='_blank'>{confurmLabel2}</a></span>
                      </label>
                    </div> :
                    <label className={state.agreedCheck ? styles.checked : styles.label}>
                    <input
                      type="checkbox"
                      checked={state.agreedCheck}
                      className={styles.checkbox}
                      onChange={() => {
                        dispatch({
                          type: actions.AGREED_CHECK,
                          data: !state.agreedCheck,
                        })
                      }}
                    />
                    {confurmLabel}
                  </label>
                }
              </React.Fragment>
              //: null
            }
          </div>
          <div
            className={
              styles.AcceptConditionsImageBox + ' ' + 'col-7 col-xs-12'
            }
          >
            <img src={AcceptConditionsImage} alt="image" />
          </div>
        </>
      ) : (
        <Agreement
          html={agreementToShow.html}
          onAccept={() => {
            dispatch({
              type: actions.AGREED_CHECK,
              data: !state.agreedCheck,
            })
            setAgreementToShow(null)
          }}
          onClose={() => {
            setAgreementToShow(null)
          }}
          closeButtonText={accountForm.closeButton[lang]}
          goBackButtonText={accountForm.goBackButton[lang]}
          acceptButtonText={accountForm.acceptButton[lang]}
        />
      )}
    </div>
  )
}

interface AgreementListItemProps {
  state: State
  agreement: AgreementData
  description?: string
  hilightUnread: boolean
  setAgreementToShow: (a: AgreementData) => void
  buttonTitle: string
  buttonText: string
}

function AgreementListItem({
  state,
  agreement,
  hilightUnread,
  description,
  setAgreementToShow,
  buttonTitle,
  buttonText,
}: AgreementListItemProps) {
  const highlight = !state.agreedOn.includes(agreement.type) && hilightUnread
  const descriptionClassName = highlight
    ? `${styles.docDescriptionText} ${styles.highlighted}`
    : styles.docDescriptionText
  return (
    <div>
      <p className={descriptionClassName}>{description}</p>
      {INSTANCE === 'second' || INSTANCE === 'msk' ?
      <div className={styles.agreementContainer}>
        <span>{buttonText} {buttonTitle}</span>
      </div> :  
      <div className={styles.agreementContainer}>
        <span>{buttonText} </span>
        <a
          title={buttonTitle}
          className={styles.showAgreement}
          aria-label={agreement.title}
          onClick={() => setAgreementToShow(agreement)}
        >
          {buttonTitle}
        </a>
      </div>}
    </div>
  )
}

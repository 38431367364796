import { Country } from '../types/common-types'

export const citizenshipsKz: Country[] = [
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
  {
    value: '112',
    label: {
      rus: 'Беларусь',
      eng: 'Belarus',
      kz: 'Беларусь',
    },
  },
  {
    value: '804',
    label: {
      rus: 'Украина',
      eng: 'Ukraine',
      kz: 'Украина',
    },
  },
  {
    value: '440',
    label: {
      rus: 'Литва',
      eng: 'Lithuania',
      kz: 'Литва',
    },
  },
  {
    value: '356',
    label: {
      rus: 'Индия',
      eng: 'India',
      kz: 'Үндістан',
    },
  },
  {
    value: '752',
    label: {
      rus: 'Швеция',
      eng: 'Sweden',
      kz: 'Швеция',
    },
  },
  {
    value: '276',
    label: {
      rus: 'Германия',
      eng: 'Germany',
      kz: 'Германия',
    },
  },
  {
    value: '364',
    label: {
      rus: 'Иран, Исламская Республика',
      eng: 'Iran, Islamic Republic of',
      kz: 'Иран, Ислам Республикасы',
    },
  },
  {
    value: '398',
    label: {
      rus: 'Казахстан',
      eng: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
  {
    value: '417',
    label: {
      rus: 'Кыргызстан',
      eng: 'Kyrgyzstan',
      kz: 'Қырғызстан',
    },
  },
  {
    value: '642',
    label: {
      rus: 'Румыния',
      eng: 'Romania',
      kz: 'Румыния',
    },
  },
  {
    value: '826',
    label: {
      rus: 'Великобритания',
      eng: 'Great Britain',
      kz: 'Ұлыбритания'
    },
  },
  {
    value: '840',
    label: {
      rus: 'Соединенные Штаты',
      eng: 'United States',
      kz: 'АҚШ',
    },
  },
  // {
  //   value: '762',
  //   label: {
  //     rus: 'Таджикистан',
  //     eng: 'Tajikistan',
  //     kz: 'Тәжікстан',
  //   },
  // },
  {
    value: '792',
    label: {
      rus: 'Турция',
      eng: 'Turkey',
      kz: 'Түркия',
    },
  },
  {
    value: '860',
    label: {
      rus: 'Узбекистан',
      eng: 'Uzbekistan',
      kz: 'Өзбекстан',
    },
  },
  {
    value: '410',
    label: {
      rus: 'Корея, Республика',
      eng: 'Korea, Republic of',
      kz: 'Корея, Республика',
    },
  },
  {
    value: '566',
    label: {
      rus: 'Нигерия',
      eng: 'Nigeria',
      kz: 'Нигерия',
    },
  },
  {
    value: '156',
    label: {
      rus: 'Китай',
      eng: 'China',
      kz: 'Қытай',
    },
  },
  {
    value: '036',
    label: {
      rus: 'Австралия',
      eng: 'Australia',
      kz: 'Австралия',
    },
  },
  {
    value: '586',
    label: {
      rus: 'Пакистан',
      eng: 'Pakistan',
      kz: 'Пәкістан',
    },
  },
  {
    value: '096',
    label: {
      rus: 'Бруней-Даруссалам',
      eng: 'Brunei Darussalam',
      kz: 'Бруней-Даруссалам',
    },
  },
]
export const citizenshipsRf: Country[] = [
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
  {
    value: '398',
    label: {
      rus: 'Казахстан',
      eng: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
  {
    value: '112',
    label: {
      rus: 'Беларусь',
      eng: 'Belarus',
      kz: 'Беларусь',
    },
  },
  {
    value: '792',
    label: {
      rus: 'Турция',
      eng: 'Turkey',
      kz: 'Түркия',
    },
  },
  {
    value: '804',
    label: {
      rus: 'Украина',
      eng: 'Ukraine',
      kz: 'Украина',
    },
  },
]
export const allFiscalCountriesKz: Country[] = [
  // {
  //   value: '643',
  //   label: {
  //     rus: 'Россия',
  //     eng: 'Russian Federation',
  //     kz: 'Ресей Федерациясы',
  //   },
  // },
  {
    value: '398',
    label: {
      rus: 'Казахстан',
      eng: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
  // {
  //   value: '417',
  //   label: {
  //     rus: 'Кыргызстан',
  //     eng: 'Kyrgyzstan',
  //     kz: 'Қырғызстан',
  //   },
  // },
]
export const allFiscalCountriesRf: Country[] = [
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
  // {
  //   value: '398',
  //   label: {
  //     rus: 'Казахстан',
  //     eng: 'Kazakhstan',
  //     kz: 'Қазақстан',
  //   },
  // },
  // {
  //   value: '417',
  //   label: {
  //     rus: 'Кыргызстан',
  //     eng: 'Kyrgyzstan',
  //     kz: 'Қырғызстан',
  //   },
  // },
]
export const IpCitizenshipCountries: Country[] = [
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
]
export const allFiscalCitizenshipCountries: Country[] = [
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
  {
    value: '112',
    label: {
      rus: 'Беларусь',
      eng: 'Belarus',
      kz: 'Беларусь',
    },
  },
  {
    value: '398',
    label: {
      rus: 'Казахстан',
      eng: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
  {
    value: '417',
    label: {
      rus: 'Кыргызстан',
      eng: 'Kyrgyzstan',
      kz: 'Қырғызстан',
    },
  },
]
export const addressCountries: Country[] = [
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
  {
    value: '112',
    label: {
      rus: 'Беларусь',
      eng: 'Belarus',
      kz: 'Беларусь',
    },
  },
  {
    value: '036',
    label: {
      rus: 'Австралия',
      eng: 'Australia',
      kz: 'Австралия',
    },
  },
  {
    value: '031',
    label: {
      rus: 'Азербайджан',
      eng: 'Azerbaijan',
      kz: 'Әзірбайжан',
    },
  },
  {
    value: '051',
    label: {
      rus: 'Армения',
      eng: 'Armenia',
      kz: 'Армения',
    },
  },
  {
    value: '792',
    label: {
      rus: 'Турция',
      eng: 'Turkey',
      kz: 'Түркия',
    },
  },
  {
    value: '100',
    label: {
      rus: 'Болгария',
      eng: 'Bulgaria',
      kz: 'Болгария',
    },
  },
  {
    value: '076',
    label: {
      rus: 'Бразилия',
      eng: 'Brazil',
      kz: 'Бразилия',
    },
  },
  {
    value: '826',
    label: {
      rus: 'Соединенное Королевство',
      eng: 'United Kingdom',
      kz: 'Біріккен корольдігі',
    },
  },
  {
    value: '826',
    label: {
      rus: 'Великобритания',
      eng: 'Great Britain',
      kz: 'Ұлыбритания'
    },
  },
  {
    value: '704',
    label: {
      rus: 'Вьетнам',
      eng: 'Vietnam',
      kz: 'Вьетнам',
    },
  },
  {
    value: '276',
    label: {
      rus: 'Германия',
      eng: 'Germany',
      kz: 'Германия',
    },
  },
  {
    value: '268',
    label: {
      rus: 'Грузия',
      eng: 'Georgia',
      kz: 'Грузия',
    },
  },
  {
    value: '376',
    label: {
      rus: 'Израиль',
      eng: 'Israel',
      kz: 'Израиль',
    },
  },
  {
    value: '356',
    label: {
      rus: 'Индия',
      eng: 'India',
      kz: 'Үндістан',
    },
  },
  {
    value: '360',
    label: {
      rus: 'Индонезия',
      eng: 'Indonesia',
      kz: 'Индонезия',
    },
  },
  {
    value: '364',
    label: {
      rus: 'Иран, Исламская Республика',
      eng: 'Iran, Islamic Republic of',
      kz: 'Иран, Ислам Республикасы',
    },
  },
  {
    value: '352',
    label: {
      rus: 'Исландия',
      eng: 'Iceland',
      kz: 'Исландия',
    },
  },
  {
    value: '724',
    label: {
      rus: 'Испания',
      eng: 'Spain',
      kz: 'Испания',
    },
  },
  {
    value: '380',
    label: {
      rus: 'Италия',
      eng: 'Italy',
      kz: 'Италия',
    },
  },
  {
    value: '398',
    label: {
      rus: 'Казахстан',
      eng: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
  {
    value: '417',
    label: {
      rus: 'Кыргызстан',
      eng: 'Kyrgyzstan',
      kz: 'Қырғызстан',
    },
  },
  {
    value: '156',
    label: {
      rus: 'Китай',
      eng: 'China',
      kz: 'Қытай',
    },
  },
  {
    value: '440',
    label: {
      rus: 'Литва',
      eng: 'Lithuania',
      kz: 'Литва',
    },
  },
  {
    value: '498',
    label: {
      rus: 'Молдова, Республика',
      eng: 'Moldova',
      kz: 'Молдова',
    },
  },
  {
    value: '528',
    label: {
      rus: 'Нидерланды',
      eng: 'Netherlands',
      kz: 'Нидерланды',
    },
  },
  {
    value: '578',
    label: {
      rus: 'Норвегия',
      eng: 'Norway',
      kz: 'Норвегия',
    },
  },
  {
    value: '566',
    label: {
      rus: 'Нигерия',
      eng: 'Nigeria',
      kz: 'Нигерия',
    },
  },
  {
    value: '410',
    label: {
      rus: 'Корея, Республика',
      eng: 'Korea, Republic of',
      kz: 'Корея, Республика',
    },
  },
  {
    value: '642',
    label: {
      rus: 'Румыния',
      eng: 'Romania',
      kz: 'Румыния',
    },
  },
  {
    value: '760',
    label: {
      rus: 'Сирийская Арабская Республика',
      eng: 'Syrian Arab Republic',
      kz: 'Сирия Араб Республикасы',
    },
  },
  {
    value: '840',
    label: {
      rus: 'Соединенные Штаты',
      eng: 'United States',
      kz: 'АҚШ',
    },
  },
  {
    value: '762',
    label: {
      rus: 'Таджикистан',
      eng: 'Tajikistan',
      kz: 'Тәжікстан',
    },
  },
  {
    value: '860',
    label: {
      rus: 'Узбекистан',
      eng: 'Uzbekistan',
      kz: 'Өзбекстан',
    },
  },
  {
    value: '246',
    label: {
      rus: 'Финляндия',
      eng: 'Finland',
      kz: 'Финляндия',
    },
  },
  {
    value: '250',
    label: {
      rus: 'Франция',
      eng: 'France',
      kz: 'Франция',
    },
  },
  {
    value: '756',
    label: {
      rus: 'Швейцария',
      eng: 'Switzerland',
      kz: 'Швейцария',
    },
  },
  {
    value: '752',
    label: {
      rus: 'Швеция',
      eng: 'Sweden',
      kz: 'Швеция',
    },
  },
  {
    value: '710',
    label: {
      rus: 'Южная Африка',
      eng: 'South Africa',
      kz: 'Оңтүстік Африка',
    },
  },
  {
    value: '586',
    label: {
      rus: 'Пакистан',
      eng: 'Pakistan',
      kz: 'Пәкістан',
    },
  },
  {
    value: '096',
    label: {
      rus: 'Бруней-Даруссалам',
      eng: 'Brunei Darussalam',
      kz: 'Бруней-Даруссалам',
    },
  },
]
export const addressFiscalCountriesKz: Country[] = [
  {
    value: '398',
    label: {
      rus: 'Казахстан',
      eng: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
]
export const addressFiscalCountriesRf: Country[] = [
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
]
export const allCountriesList: Country[] = [
  {
    value: '612',
    label: {
      rus: 'Питкерн',
      eng: 'Pitcairn',
      kz: 'Питкерн',
    },
  },
  {
    value: '334',
    label: {
      rus: 'Остров Херд и острова Макдональд',
      eng: 'Heard Island and McDonald Islands',
      kz: 'Херд аралы және Макдональд аралдары',
    },
  },
  {
    value: '600',
    label: {
      rus: 'Парагвай',
      eng: 'Paraguay',
      kz: 'Парагвай',
    },
  },
  {
    value: '634',
    label: {
      rus: 'Катар',
      eng: 'Qatar',
      kz: 'Катар',
    },
  },
  {
    value: '162',
    label: {
      rus: 'Остров Рождества',
      eng: 'Christmas Island',
      kz: 'Рождество аралы',
    },
  },
  {
    value: '222',
    label: {
      rus: 'Эль-Сальвадор',
      eng: 'El Salvador',
      kz: 'Эль-Сальвадор',
    },
  },
  {
    value: '574',
    label: {
      rus: 'Остров Норфолк',
      eng: 'Norfolk Island',
      kz: 'Норфолк аралы',
    },
  },
  {
    value: '583',
    label: {
      rus: 'Микронезия, Федеративные Штаты',
      eng: 'Micronesia, Federated States of',
      kz: 'Микронезия, Федеративті Штаттар',
    },
  },
  {
    value: '705',
    label: {
      rus: 'Словения',
      eng: 'Slovenia',
      kz: 'Словения',
    },
  },
  {
    value: '578',
    label: {
      rus: 'Норвегия',
      eng: 'Norway',
      kz: 'Норвегия',
    },
  },
  {
    value: '570',
    label: {
      rus: 'Ниуэ',
      eng: 'Niue',
      kz: 'Ниуэ',
    },
  },
  {
    value: '520',
    label: {
      rus: 'Науру',
      eng: 'Nauru',
      kz: 'Науру',
    },
  },
  {
    value: '104',
    label: {
      rus: 'Мьянма',
      eng: 'Burma',
      kz: 'Мьянма',
    },
  },
  {
    value: '584',
    label: {
      rus: 'Маршалловы острова',
      eng: 'Marshall Islands',
      kz: 'Маршалл аралдары',
    },
  },
  {
    value: '376',
    label: {
      rus: 'Израиль',
      eng: 'Israel',
      kz: 'Израиль',
    },
  },
  {
    value: '174',
    label: {
      rus: 'Коморы',
      eng: 'Comoros',
      kz: 'Коморлар',
    },
  },
  {
    value: '446',
    label: {
      rus: 'Макао',
      eng: 'Macao',
      kz: 'Макао',
    },
  },
  {
    value: '764',
    label: {
      rus: 'Таиланд',
      eng: 'Thailand',
      kz: 'Тайланд',
    },
  },
  {
    value: '175',
    label: {
      rus: 'Майотта',
      eng: 'Mayotte',
      kz: 'Майотта',
    },
  },
  {
    value: '450',
    label: {
      rus: 'Мадагаскар',
      eng: 'Madagascar',
      kz: 'Мадагаскар',
    },
  },
  {
    value: '492',
    label: {
      rus: 'Монако',
      eng: 'Monaco',
      kz: 'Монако',
    },
  },
  {
    value: '462',
    label: {
      rus: 'Мальдивы',
      eng: 'Maldives',
      kz: 'Мальдив аралдары',
    },
  },
  {
    value: '442',
    label: {
      rus: 'Люксембург',
      eng: 'Luxembourg',
      kz: 'Люксембург',
    },
  },
  {
    value: '180',
    label: {
      rus: 'Конго, Демократическая Республика',
      eng: 'Congo, Democratic Republic of the',
      kz: 'Конго, Демократиялық Республика',
    },
  },
  {
    value: '558',
    label: {
      rus: 'Никарагуа',
      eng: 'Nicaragua',
      kz: 'Никарагуа',
    },
  },
  {
    value: '818',
    label: {
      rus: 'Египет',
      eng: 'Egypt',
      kz: 'Египет',
    },
  },
  {
    value: '426',
    label: {
      rus: 'Лесото',
      eng: 'Lesotho',
      kz: 'Лесото',
    },
  },
  {
    value: '428',
    label: {
      rus: 'Латвия',
      eng: 'Latvia',
      kz: 'Латвия',
    },
  },
  {
    value: '074',
    label: {
      rus: 'Остров Буве',
      eng: 'Bouvet Island',
      kz: 'Буве аралы',
    },
  },
  {
    value: '414',
    label: {
      rus: 'Кувейт',
      eng: 'Kuwait',
      kz: 'Кувейт',
    },
  },
  {
    value: '300',
    label: {
      rus: 'Греция',
      eng: 'Greece',
      kz: 'Греция',
    },
  },
  {
    value: '064',
    label: {
      rus: 'Бутан',
      eng: 'Bhutan',
      kz: 'Бутан',
    },
  },
  {
    value: '474',
    label: {
      rus: 'Мартиника',
      eng: 'Martinique',
      kz: 'Мартиника',
    },
  },
  {
    value: '356',
    label: {
      rus: 'Индия',
      eng: 'India',
      kz: 'Үндістан',
    },
  },
  {
    value: '646',
    label: {
      rus: 'Руанда',
      eng: 'Rwanda',
      kz: 'Руанда',
    },
  },
  {
    value: '404',
    label: {
      rus: 'Кения',
      eng: 'Kenya',
      kz: 'Кения',
    },
  },
  {
    value: '630',
    label: {
      rus: 'Пуэрто-Рико',
      eng: 'Puerto Rico',
      kz: 'Пуэрто-Рико',
    },
  },
  {
    value: '144',
    label: {
      rus: 'Шри-Ланка',
      eng: 'Sri Lanka',
      kz: 'Шри-Ланка',
    },
  },
  {
    value: '320',
    label: {
      rus: 'Гватемала',
      eng: 'Guatemala',
      kz: 'Гватемала',
    },
  },
  {
    value: '148',
    label: {
      rus: 'Чад',
      eng: 'Chad',
      kz: 'Чад',
    },
  },
  {
    value: '784',
    label: {
      rus: 'Объединенные Арабские Эмираты',
      eng: 'United Arab Emirates',
      kz: 'Біріккен Араб Әмірліктері',
    },
  },
  {
    value: '232',
    label: {
      rus: 'Эритрея',
      eng: 'Eritrea',
      kz: 'Эритрея',
    },
  },
  {
    value: '288',
    label: {
      rus: 'Гана',
      eng: 'Ghana',
      kz: 'Гана',
    },
  },
  {
    value: '533',
    label: {
      rus: 'Аруба',
      eng: 'Aruba',
      kz: 'Аруба',
    },
  },
  {
    value: '581',
    label: {
      rus: 'Малые Тихоокеанские отдаленные острова Соединенных Штатов',
      eng: 'United States Minor Outlying Islands',
      kz: 'Америка Құрама Штаттарының Кіші Тынық мұхиты шалғай аралдары',
    },
  },
  {
    value: '368',
    label: {
      rus: 'Ирак',
      eng: 'Iraq',
      kz: 'Ирак',
    },
  },
  {
    value: '800',
    label: {
      rus: 'Уганда',
      eng: 'Uganda',
      kz: 'Уганда',
    },
  },
  {
    value: '392',
    label: {
      rus: 'Япония',
      eng: 'Japan',
      kz: 'Жапония',
    },
  },
  {
    value: '031',
    label: {
      rus: 'Азербайджан',
      eng: 'Azerbaijan',
      kz: 'Әзірбайжан',
    },
  },
  {
    value: '440',
    label: {
      rus: 'Литва',
      eng: 'Lithuania',
      kz: 'Литва',
    },
  },
  {
    value: '262',
    label: {
      rus: 'Джибути',
      eng: 'Djibouti',
      kz: 'Джибути',
    },
  },
  {
    value: '554',
    label: {
      rus: 'Новая Зеландия',
      eng: 'New Zealand',
      kz: 'Жаңа Зеландия',
    },
  },
  {
    value: '887',
    label: {
      rus: 'Йемен',
      eng: 'Yemen',
      kz: 'Йемен',
    },
  },
  {
    value: '422',
    label: {
      rus: 'Ливан',
      eng: 'Lebanon',
      kz: 'Ливан',
    },
  },
  {
    value: '454',
    label: {
      rus: 'Малави',
      eng: 'Malawi',
      kz: 'Малави',
    },
  },
  {
    value: '051',
    label: {
      rus: 'Армения',
      eng: 'Armenia',
      kz: 'Армения',
    },
  },
  {
    value: '792',
    label: {
      rus: 'Турция',
      eng: 'Turkey',
      kz: 'Түркия',
    },
  },
  {
    value: '716',
    label: {
      rus: 'Зимбабве',
      eng: 'Zimbabwe',
      kz: 'Зимбабве',
    },
  },
  {
    value: '528',
    label: {
      rus: 'Нидерланды',
      eng: 'Netherlands',
      kz: 'Нидерланды',
    },
  },
  {
    value: '430',
    label: {
      rus: 'Либерия',
      eng: 'Liberia',
      kz: 'Либерия',
    },
  },
  {
    value: '218',
    label: {
      rus: 'Эквадор',
      eng: 'Ecuador',
      kz: 'Эквадор',
    },
  },
  {
    value: '626',
    label: {
      rus: 'Тимор-Лесте',
      eng: 'Timor-Leste',
      kz: 'Тимор-Лесте',
    },
  },
  {
    value: '096',
    label: {
      rus: 'Бруней-Даруссалам',
      eng: 'Brunei Darussalam',
      kz: 'Бруней-Даруссалам',
    },
  },
  {
    value: '400',
    label: {
      rus: 'Иордания',
      eng: 'Jordan',
      kz: 'Иордания',
    },
  },
  {
    value: '895',
    label: {
      rus: 'Абхазия',
      eng: 'Abkhazia',
      kz: 'Абхазия',
    },
  },
  {
    value: '807',
    label: {
      rus: 'Республика Македония',
      eng: 'Macedonia, The Former Yugoslav Republic Of',
      kz: 'Македония Республикасы',
    },
  },
  {
    value: '732',
    label: {
      rus: 'Западная Сахара',
      eng: 'Western Sahara',
      kz: 'Батыс Сахара',
    },
  },
  {
    value: '226',
    label: {
      rus: 'Экваториальная Гвинея',
      eng: 'Equatorial Guinea',
      kz: 'Экваторлық Гвинея',
    },
  },
  {
    value: '548',
    label: {
      rus: 'Вануату',
      eng: 'Vanuatu',
      kz: 'Вануату',
    },
  },
  {
    value: '352',
    label: {
      rus: 'Исландия',
      eng: 'Iceland',
      kz: 'Исландия',
    },
  },
  {
    value: '203',
    label: {
      rus: 'Чешская Республика',
      eng: 'Czech Republic',
      kz: 'Чехия',
    },
  },
  {
    value: '652',
    label: {
      rus: 'Сен-Бартельми',
      eng: 'Saint Barthélemy',
      kz: 'Сент-Бартелми',
    },
  },
  {
    value: '840',
    label: {
      rus: 'Соединенные Штаты',
      eng: 'United States',
      kz: 'Америка Құрама Штаттары',
    },
  },
  {
    value: '834',
    label: {
      rus: 'Танзания, Объединенная Республика',
      eng: 'Tanzania, United Republic Of',
      kz: 'Танзания, Біріккен Республика',
    },
  },
  {
    value: '036',
    label: {
      rus: 'Австралия',
      eng: 'Australia',
      kz: 'Австралия',
    },
  },
  {
    value: '239',
    label: {
      rus: 'Южная Джорджия и Южные Сандвичевы острова',
      eng: 'South Georgia and the South Sandwich Islands',
      kz: 'Оңтүстік Джорджия және Оңтүстік Сэндвич аралдары',
    },
  },
  {
    value: '585',
    label: {
      rus: 'Палау',
      eng: 'Palau',
      kz: 'Палау',
    },
  },
  {
    value: '498',
    label: {
      rus: 'Молдова, Республика',
      eng: 'Moldova',
      kz: 'Молдова, Республика',
    },
  },
  {
    value: '124',
    label: {
      rus: 'Канада',
      eng: 'Canada',
      kz: 'Канада',
    },
  },
  {
    value: '756',
    label: {
      rus: 'Швейцария',
      eng: 'Switzerland',
      kz: 'Швейцария',
    },
  },
  {
    value: '040',
    label: {
      rus: 'Австрия',
      eng: 'Austria',
      kz: 'Австрия',
    },
  },
  {
    value: '663',
    label: {
      rus: 'Сен-Мартен',
      eng: 'Saint Martin (French Part)',
      kz: 'Сент-Мартин',
    },
  },
  {
    value: '254',
    label: {
      rus: 'Французская Гвиана',
      eng: 'French Guiana',
      kz: 'Француз Гвианасы',
    },
  },
  {
    value: '690',
    label: {
      rus: 'Сейшелы',
      eng: 'Seychelles',
      kz: 'Сейшел аралдары',
    },
  },
  {
    value: '760',
    label: {
      rus: 'Сирийская Арабская Республика',
      eng: 'Syrian Arab Republic',
      kz: 'Сирия Араб Республикасы',
    },
  },
  {
    value: '068',
    label: {
      rus: 'Боливия, Многонациональное Государство',
      eng: 'Bolivia, plurinational state of',
      kz: 'Боливия, Көпұлтты Мемлекет',
    },
  },
  {
    value: '010',
    label: {
      rus: 'Антарктида',
      eng: 'Antarctica',
      kz: 'Антарктида',
    },
  },
  {
    value: '660',
    label: {
      rus: 'Ангилья',
      eng: 'Anguilla',
      kz: 'Ангилья',
    },
  },
  {
    value: '728',
    label: {
      rus: 'Южный Судан',
      eng: 'South Sudan',
      kz: 'Оңтүстік Судан',
    },
  },
  {
    value: '710',
    label: {
      rus: 'Южная Африка',
      eng: 'South Africa',
      kz: 'Оңтүстік Африка',
    },
  },
  {
    value: '268',
    label: {
      rus: 'Грузия',
      eng: 'Georgia',
      kz: 'Грузия',
    },
  },
  {
    value: '184',
    label: {
      rus: 'Острова Кука',
      eng: 'Cook Islands',
      kz: 'Кук аралдары',
    },
  },
  {
    value: '566',
    label: {
      rus: 'Нигерия',
      eng: 'Nigeria',
      kz: 'Нигерия',
    },
  },
  {
    value: '768',
    label: {
      rus: 'Того',
      eng: 'Togo',
      kz: 'Того',
    },
  },
  {
    value: '662',
    label: {
      rus: 'Сент-Люсия',
      eng: 'Saint Lucia',
      kz: 'Сент-Люсия',
    },
  },
  {
    value: '642',
    label: {
      rus: 'Румыния',
      eng: 'Romania',
      kz: 'Румыния',
    },
  },
  {
    value: '086',
    label: {
      rus: 'Британская территория в Индийском океане',
      eng: 'British Indian Ocean Territory',
      kz: 'Үнді Мұхитындағы Британдық территория',
    },
  },
  {
    value: '670',
    label: {
      rus: 'Сент-Винсент и Гренадины',
      eng: 'Saint Vincent and the Grenadines',
      kz: 'Сент-Винсент және Гренадиндер',
    },
  },
  {
    value: '056',
    label: {
      rus: 'Бельгия',
      eng: 'Belgium',
      kz: 'Бельгия',
    },
  },
  {
    value: '678',
    label: {
      rus: 'Сан-Томе и Принсипи',
      eng: 'Sao Tome and Principe',
      kz: 'Сан-Томе және Принсипи',
    },
  },
  {
    value: '795',
    label: {
      rus: 'Туркмения',
      eng: 'Turkmenistan',
      kz: 'Түрікменстан',
    },
  },
  {
    value: '052',
    label: {
      rus: 'Барбадос',
      eng: 'Barbados',
      kz: 'Барбадос',
    },
  },
  {
    value: '876',
    label: {
      rus: 'Уоллис и Футуна',
      eng: 'Wallis and Futuna',
      kz: 'Уоллис Иутуна',
    },
  },
  {
    value: '688',
    label: {
      rus: 'Сербия',
      eng: 'Serbia',
      kz: 'Сербия',
    },
  },
  {
    value: '242',
    label: {
      rus: 'Фиджи',
      eng: 'Fiji',
      kz: 'Фидджи',
    },
  },
  {
    value: '762',
    label: {
      rus: 'Таджикистан',
      eng: 'Tajikistan',
      kz: 'Тәжік-Стан',
    },
  },
  {
    value: '562',
    label: {
      rus: 'Нигер',
      eng: 'Niger',
      kz: 'Нигер',
    },
  },
  {
    value: '729',
    label: {
      rus: 'Судан',
      eng: 'Sudan',
      kz: 'Судан',
    },
  },
  {
    value: '858',
    label: {
      rus: 'Уругвай',
      eng: 'Uruguay',
      kz: 'Уругвай',
    },
  },
  {
    value: '140',
    label: {
      rus: 'Центрально-Африканская Республика',
      eng: 'Central African Republic',
      kz: 'Орталық Африка Республикасы',
    },
  },
  {
    value: '694',
    label: {
      rus: 'Сьерра-Леоне',
      eng: 'Sierra Leone',
      kz: 'Серра-Леоне',
    },
  },
  {
    value: '504',
    label: {
      rus: 'Марокко',
      eng: 'Morocco',
      kz: 'Марокко',
    },
  },
  {
    value: '654',
    label: {
      rus: 'Святая Елена, Остров вознесения, Тристан-да-Кунья',
      eng: 'Saint Helena, Ascension And Tristan Da Cunha',
      kz: 'Әулие Елена, хабардарлық аралы, Тристан да Кунья',
    },
  },
  {
    value: '238',
    label: {
      rus: 'Фолклендские острова (Мальвинские)',
      eng: 'Falkland Islands (Malvinas)',
      kz: 'Олкленд аралдары (Малвинский)',
    },
  },
  {
    value: '706',
    label: {
      rus: 'Сомали',
      eng: 'Somalia',
      kz: 'Сомали',
    },
  },
  {
    value: '048',
    label: {
      rus: 'Бахрейн',
      eng: 'Bahrain',
      kz: 'Барейн',
    },
  },
  {
    value: '772',
    label: {
      rus: 'Токелау',
      eng: 'Tokelau',
      kz: 'Токелау',
    },
  },
  {
    value: '604',
    label: {
      rus: 'Перу',
      eng: 'Peru',
      kz: 'Перу',
    },
  },
  {
    value: '744',
    label: {
      rus: 'Шпицберген и Ян Майен',
      eng: 'Svalbard and Jan Mayen',
      kz: 'Пипберген және Ян Майен',
    },
  },
  {
    value: '826',
    label: {
      rus: 'Соединенное Королевство',
      eng: 'United Kingdom',
      kz: 'Ұлыбритания',
    },
  },
  {
    value: '234',
    label: {
      rus: 'Фарерские острова',
      eng: 'Faroe Islands',
      kz: 'Фарер аралдары',
    },
  },
  {
    value: '158',
    label: {
      rus: 'Тайвань (Қытай)',
      eng: 'Taiwan, Province of China',
      kz: 'Тайвань (Қытай)',
    },
  },
  {
    value: '246',
    label: {
      rus: 'Финляндия',
      eng: 'Finland',
      kz: 'Финляндия',
    },
  },
  {
    value: '292',
    label: {
      rus: 'Гибралтар',
      eng: 'Gibraltar',
      kz: 'Гибралтар',
    },
  },
  {
    value: '191',
    label: {
      rus: 'Хорватия',
      eng: 'Croatia',
      kz: 'Хорватия',
    },
  },
  {
    value: '534',
    label: {
      rus: 'Синт-Мартен',
      eng: 'Sint Maarten',
      kz: 'Синт-Мартен',
    },
  },
  {
    value: '012',
    label: {
      rus: 'Алжир',
      eng: 'Algeria',
      kz: 'Алжир',
    },
  },
  {
    value: '512',
    label: {
      rus: 'Оман',
      eng: 'Oman',
      kz: 'Оман',
    },
  },
  {
    value: '580',
    label: {
      rus: 'Северные Марианские острова',
      eng: 'Northern Mariana Islands',
      kz: 'Солтүстік Мариана аралдары',
    },
  },
  {
    value: '100',
    label: {
      rus: 'Болгария',
      eng: 'Bulgaria',
      kz: 'Болгария',
    },
  },
  {
    value: '776',
    label: {
      rus: 'Тонга',
      eng: 'Tonga',
      kz: 'Тонга',
    },
  },
  {
    value: '499',
    label: {
      rus: 'Черногория',
      eng: 'Montenegro',
      kz: 'Черногория',
    },
  },
  {
    value: '624',
    label: {
      rus: 'Гвинея-Бисау',
      eng: 'Guinea-Bissau',
      kz: 'Гвинея-Бисау',
    },
  },
  {
    value: '360',
    label: {
      rus: 'Индонезия',
      eng: 'Indonesia',
      kz: 'Индонезия',
    },
  },
  {
    value: '703',
    label: {
      rus: 'Словакия',
      eng: 'Slovakia',
      kz: 'Словакия',
    },
  },
  {
    value: '170',
    label: {
      rus: 'Колумбия',
      eng: 'Colombia',
      kz: 'Колумбия',
    },
  },
  {
    value: '466',
    label: {
      rus: 'Мали',
      eng: 'Mali',
      kz: 'Мали',
    },
  },
  {
    value: '620',
    label: {
      rus: 'Португалия',
      eng: 'Portugal',
      kz: 'Португалия',
    },
  },
  {
    value: '780',
    label: {
      rus: 'Тринидад и Тобаго',
      eng: 'Trinidad and Tobago',
      kz: 'Тринидад және Тобаго',
    },
  },
  {
    value: '702',
    label: {
      rus: 'Сингапур',
      eng: 'Singapore',
      kz: 'Сингапур',
    },
  },
  {
    value: '531',
    label: {
      rus: 'Кюрасао',
      eng: 'Curaçao',
      kz: 'Кюрасао',
    },
  },
  {
    value: '850',
    label: {
      rus: 'Виргинские острова, США',
      eng: 'Virgin Islands, U.S.',
      kz: 'Виргин аралдары, АҚШ',
    },
  },
  {
    value: '260',
    label: {
      rus: 'Французские Южные территории',
      eng: 'French Southern Territories',
      kz: 'Францияның оңтүстік территориялары',
    },
  },
  {
    value: '388',
    label: {
      rus: 'Ямайка',
      eng: 'Jamaica',
      kz: 'Ямайка',
    },
  },
  {
    value: '659',
    label: {
      rus: 'Сент-Китс и Невис',
      eng: 'Saint Kitts and Nevis',
      kz: 'Сент-Китс және Невис',
    },
  },
  {
    value: '340',
    label: {
      rus: 'Гондурас',
      eng: 'Honduras',
      kz: 'Гондурас',
    },
  },
  {
    value: '348',
    label: {
      rus: 'Венгрия',
      eng: 'Hungary',
      kz: 'Венгрия',
    },
  },
  {
    value: '008',
    label: {
      rus: 'Албания',
      eng: 'Albania',
      kz: 'Албания',
    },
  },
  {
    value: '804',
    label: {
      rus: 'Украина',
      eng: 'Ukraine',
      kz: 'Украина',
    },
  },
  {
    value: '796',
    label: {
      rus: 'Острова Теркс и Кайкос',
      eng: 'Turks and Caicos Islands',
      kz: 'Түріктер мен Кайкос аралдары',
    },
  },
  {
    value: '032',
    label: {
      rus: 'Аргентина',
      eng: 'Argentina',
      kz: 'Аргентина',
    },
  },
  {
    value: '050',
    label: {
      rus: 'Бангладеш',
      eng: 'Bangladesh',
      kz: 'Бангладеш',
    },
  },
  {
    value: '833',
    label: {
      rus: 'Остров Мэн',
      eng: 'Isle of Man',
      kz: 'Мэн аралы',
    },
  },
  {
    value: '028',
    label: {
      rus: 'Антигуа и Барбуда',
      eng: 'Antigua and Barbuda',
      kz: 'Антигуа және Барбуда',
    },
  },
  {
    value: '212',
    label: {
      rus: 'Доминика',
      eng: 'Dominica',
      kz: 'Доминика',
    },
  },
  {
    value: '070',
    label: {
      rus: 'Босния и Герцеговина',
      eng: 'Bosnia and Herzegovina',
      kz: 'Босния және Герцеговина',
    },
  },
  {
    value: '500',
    label: {
      rus: 'Монтсеррат',
      eng: 'Montserrat',
      kz: 'Монтсеррат',
    },
  },
  {
    value: '248',
    label: {
      rus: 'Эландские острова',
      eng: 'Åland Islands',
      kz: 'Аланд аралдары',
    },
  },
  {
    value: '270',
    label: {
      rus: 'Гамбия',
      eng: 'Gambia',
      kz: 'Гамбия',
    },
  },
  {
    value: '591',
    label: {
      rus: 'Панама',
      eng: 'Panama',
      kz: 'Панама',
    },
  },
  {
    value: '156',
    label: {
      rus: 'Китай',
      eng: 'China',
      kz: 'Қытай',
    },
  },
  {
    value: '204',
    label: {
      rus: 'Бенин',
      eng: 'Benin',
      kz: 'Бенин',
    },
  },
  {
    value: '616',
    label: {
      rus: 'Польша',
      eng: 'Poland',
      kz: 'Польша',
    },
  },
  {
    value: '788',
    label: {
      rus: 'Тунис',
      eng: 'Tunisia',
      kz: 'Тунис',
    },
  },
  {
    value: '508',
    label: {
      rus: 'Мозамбик',
      eng: 'Mozambique',
      kz: 'Мозамбик',
    },
  },
  {
    value: '132',
    label: {
      rus: 'Кабо-Верде',
      eng: 'Cape Verde',
      kz: 'Кабо-Верде',
    },
  },
  {
    value: '470',
    label: {
      rus: 'Мальта',
      eng: 'Malta',
      kz: 'Мальта',
    },
  },
  {
    value: '598',
    label: {
      rus: 'Папуа-Новая Гвинея',
      eng: 'Papua New Guinea',
      kz: 'Папуа-Жаңа Гвинея',
    },
  },
  {
    value: '748',
    label: {
      rus: 'Свазиленд',
      eng: 'Swaziland',
      kz: 'Свазиленд',
    },
  },
  {
    value: '120',
    label: {
      rus: 'Камерун',
      eng: 'Cameroon',
      kz: 'Камерун',
    },
  },
  {
    value: '084',
    label: {
      rus: 'Белиз',
      eng: 'Belize',
      kz: 'Белиз',
    },
  },
  {
    value: '090',
    label: {
      rus: 'Соломоновы острова',
      eng: 'Solomon Islands',
      kz: 'Соломон аралдары',
    },
  },
  {
    value: '016',
    label: {
      rus: 'Американское Самоа',
      eng: 'American Samoa',
      kz: 'Американдық Самоа',
    },
  },
  {
    value: '384',
    label: {
      rus: "Кот д'Ивуар",
      eng: "Cote d'Ivoire",
      kz: "Кот-д 'Ивуар",
    },
  },
  {
    value: '540',
    label: {
      rus: 'Новая Каледония',
      eng: 'New Caledonia',
      kz: 'Жаңа Каледония',
    },
  },
  {
    value: '304',
    label: {
      rus: 'Гренландия',
      eng: 'Greenland',
      kz: 'Гренландия',
    },
  },
  {
    value: '372',
    label: {
      rus: 'Ирландия',
      eng: 'Ireland',
      kz: 'Ирландия',
    },
  },
  {
    value: '214',
    label: {
      rus: 'Доминиканская Республика',
      eng: 'Dominican Republic',
      kz: 'Доминикан Республикасы',
    },
  },
  {
    value: '024',
    label: {
      rus: 'Ангола',
      eng: 'Angola',
      kz: 'Ангола',
    },
  },
  {
    value: '276',
    label: {
      rus: 'Германия',
      eng: 'Germany',
      kz: 'Германия',
    },
  },
  {
    value: '178',
    label: {
      rus: 'Конго',
      eng: 'Congo',
      kz: 'Конго',
    },
  },
  {
    value: '296',
    label: {
      rus: 'Кирибати',
      eng: 'Kiribati',
      kz: 'Кирибати',
    },
  },
  {
    value: '682',
    label: {
      rus: 'Саудовская Аравия',
      eng: 'Saudi Arabia',
      kz: 'Сауд Арабиясы',
    },
  },
  {
    value: '831',
    label: {
      rus: 'Гернси',
      eng: 'Guernsey',
      kz: 'Гернси',
    },
  },
  {
    value: '328',
    label: {
      rus: 'Гайана',
      eng: 'Guyana',
      kz: 'Гайана',
    },
  },
  {
    value: '275',
    label: {
      rus: 'Палестинская территория, оккупированная',
      eng: 'Palestinian Territory, Occupied',
      kz: 'Палестина территориясы, тоқтатылды',
    },
  },
  {
    value: '060',
    label: {
      rus: 'Бермуды',
      eng: 'Bermuda',
      kz: 'Бермуд аралдары',
    },
  },
  {
    value: '116',
    label: {
      rus: 'Камбоджа',
      eng: 'Cambodia',
      kz: 'Камбоджа',
    },
  },
  {
    value: '666',
    label: {
      rus: 'Сент-Пьер и Микелон',
      eng: 'Saint Pierre and Miquelon',
      kz: 'Сент-Пьер және Микелон',
    },
  },
  {
    value: '308',
    label: {
      rus: 'Гренада',
      eng: 'Grenada',
      kz: 'Гренада',
    },
  },
  {
    value: '408',
    label: {
      rus: 'Корея, Народно-Демократическая Республика',
      eng: "Korea, Democratic People's republic of",
      kz: 'Корея, Халықтық Демократиялық республика',
    },
  },
  {
    value: '854',
    label: {
      rus: 'Буркина-Фасо',
      eng: 'Burkina Faso',
      kz: 'Буркина-Фасо',
    },
  },
  {
    value: '410',
    label: {
      rus: 'Корея, Республика',
      eng: 'Korea, Republic of',
      kz: 'Корея, Республика',
    },
  },
  {
    value: '332',
    label: {
      rus: 'Гаити',
      eng: 'Haiti',
      kz: 'Гаити',
    },
  },
  {
    value: '535',
    label: {
      rus: 'Бонайре, Саба и Синт-Эстатиус',
      eng: 'Bonaire, Sint Eustatius and Saba',
      kz: 'Бонайре, Синт Эстатиус және Саба',
    },
  },
  {
    value: '418',
    label: {
      rus: 'Лаос',
      eng: "Lao People's Democratic Republic",
      kz: 'Лаос Халықтық Демократиялық Республикасы',
    },
  },
  {
    value: '458',
    label: {
      rus: 'Малайзия',
      eng: 'Malaysia',
      kz: 'Малайзия',
    },
  },
  {
    value: '196',
    label: {
      rus: 'Кипр',
      eng: 'Cyprus',
      kz: 'Кипр',
    },
  },
  {
    value: '316',
    label: {
      rus: 'Гуам',
      eng: 'Guam',
      kz: 'Гуам',
    },
  },
  {
    value: '092',
    label: {
      rus: 'Виргинские острова, Британские',
      eng: 'Virgin Islands, British',
      kz: 'Виргин аралдары, Ұлыбритания',
    },
  },
  {
    value: '336',
    label: {
      rus: 'Папский Престол (Государство &mdash; город Ватикан)',
      eng: 'Holy See (Vatican City State)',
      kz: 'Қасиетті Тақ (Ватикан қаласы)',
    },
  },
  {
    value: '434',
    label: {
      rus: 'Ливийская Арабская Джамахирия',
      eng: 'Libyan Arab Jamahiriya',
      kz: 'Ливия Араб Жамахириясы',
    },
  },
  {
    value: '192',
    label: {
      rus: 'Куба',
      eng: 'Cuba',
      kz: 'Куба',
    },
  },
  {
    value: '324',
    label: {
      rus: 'Гвинея',
      eng: 'Guinea',
      kz: 'Гвинея',
    },
  },
  {
    value: '638',
    label: {
      rus: 'Реюньон',
      eng: 'Reunion',
      kz: 'Реюньон',
    },
  },
  {
    value: '344',
    label: {
      rus: 'Гонконг',
      eng: 'Hong Kong',
      kz: 'Гонконг',
    },
  },
  {
    value: '686',
    label: {
      rus: 'Сенегал',
      eng: 'Senegal',
      kz: 'Сенегал',
    },
  },
  {
    value: '020',
    label: {
      rus: 'Андорра',
      eng: 'Andorra',
      kz: 'Андорра',
    },
  },
  {
    value: '438',
    label: {
      rus: 'Лихтенштейн',
      eng: 'Liechtenstein',
      kz: 'Литтен-Тейн',
    },
  },
  {
    value: '496',
    label: {
      rus: 'Монголия',
      eng: 'Mongolia',
      kz: 'Моңғолия',
    },
  },
  {
    value: '258',
    label: {
      rus: 'Французская Полинезия',
      eng: 'French Polynesia',
      kz: 'Француз Полинезиясы',
    },
  },
  {
    value: '674',
    label: {
      rus: 'Сан-Марино',
      eng: 'San Marino',
      kz: 'Сан-Марино',
    },
  },
  {
    value: '152',
    label: {
      rus: 'Чили',
      eng: 'Chile',
      kz: 'Чили',
    },
  },
  {
    value: '740',
    label: {
      rus: 'Суринам',
      eng: 'Suriname',
      kz: 'Суринам',
    },
  },
  {
    value: '250',
    label: {
      rus: 'Франция',
      eng: 'France',
      kz: 'Франция',
    },
  },
  {
    value: '233',
    label: {
      rus: 'Эстония',
      eng: 'Estonia',
      kz: 'Эстония',
    },
  },
  {
    value: '516',
    label: {
      rus: 'Намибия',
      eng: 'Namibia',
      kz: 'Намибия',
    },
  },
  {
    value: '798',
    label: {
      rus: 'Тувалу',
      eng: 'Tuvalu',
      kz: 'Тувалу',
    },
  },
  {
    value: '896',
    label: {
      rus: 'Южная Осетия',
      eng: 'South Ossetia',
      kz: 'Оңтүстік Осетия',
    },
  },
  {
    value: '862',
    label: {
      rus: 'Венесуэла Боливарианская Республика',
      eng: 'Venezuela',
      kz: 'Венесуэла',
    },
  },
  {
    value: '752',
    label: {
      rus: 'Швеция',
      eng: 'Sweden',
      kz: 'Швеция',
    },
  },
  {
    value: '586',
    label: {
      rus: 'Пакистан',
      eng: 'Pakistan',
      kz: 'Пәкістан',
    },
  },
  {
    value: '524',
    label: {
      rus: 'Непал',
      eng: 'Nepal',
      kz: 'Непал',
    },
  },
  {
    value: '312',
    label: {
      rus: 'Гваделупа',
      eng: 'Guadeloupe',
      kz: 'Гвадалупа',
    },
  },
  {
    value: '882',
    label: {
      rus: 'Самоа',
      eng: 'Samoa',
      kz: 'Самоа',
    },
  },
  {
    value: '380',
    label: {
      rus: 'Италия',
      eng: 'Italy',
      kz: 'Италия',
    },
  },
  {
    value: '072',
    label: {
      rus: 'Ботсвана',
      eng: 'Botswana',
      kz: 'Ботсвана',
    },
  },
  {
    value: '724',
    label: {
      rus: 'Испания',
      eng: 'Spain',
      kz: 'Испания',
    },
  },
  {
    value: '480',
    label: {
      rus: 'Маврикий',
      eng: 'Mauritius',
      kz: 'Маврикий',
    },
  },
  {
    value: '478',
    label: {
      rus: 'Мавритания',
      eng: 'Mauritania',
      kz: 'Мавритания',
    },
  },
  {
    value: '860',
    label: {
      rus: 'Узбекистан',
      eng: 'Uzbekistan',
      kz: 'Өзбекстан',
    },
  },
  {
    value: '004',
    label: {
      rus: 'Афганистан',
      eng: 'Afghanistan',
      kz: 'Ауғанстан',
    },
  },
  {
    value: '704',
    label: {
      rus: 'Вьетнам',
      eng: 'Vietnam',
      kz: 'Вьетнам',
    },
  },
  {
    value: '484',
    label: {
      rus: 'Мексика',
      eng: 'Mexico',
      kz: 'Мексика',
    },
  },
  {
    value: '231',
    label: {
      rus: 'Эфиопия',
      eng: 'Ethiopia',
      kz: 'Эфиопия',
    },
  },
  {
    value: '266',
    label: {
      rus: 'Габон',
      eng: 'Gabon',
      kz: 'Габон',
    },
  },
  {
    value: '076',
    label: {
      rus: 'Бразилия',
      eng: 'Brazil',
      kz: 'Бразилия',
    },
  },
  {
    value: '188',
    label: {
      rus: 'Коста-Рика',
      eng: 'Costa Rica',
      kz: 'Коста-Рика',
    },
  },
  {
    value: '044',
    label: {
      rus: 'Багамы',
      eng: 'Bahamas',
      kz: 'Багам аралдары',
    },
  },
  {
    value: '136',
    label: {
      rus: 'Острова Кайман',
      eng: 'Cayman Islands',
      kz: 'Кайман аралдары',
    },
  },
  {
    value: '364',
    label: {
      rus: 'Иран, Исламская Республика',
      eng: 'Iran, Islamic Republic of',
      kz: 'Иран, Ислам Республикасы',
    },
  },
  {
    value: '208',
    label: {
      rus: 'Дания',
      eng: 'Denmark',
      kz: 'Дания',
    },
  },
  {
    value: '608',
    label: {
      rus: 'Филиппины',
      eng: 'Philippines',
      kz: 'Филиппиндер',
    },
  },
  {
    value: '108',
    label: {
      rus: 'Бурунди',
      eng: 'Burundi',
      kz: 'Бурунди',
    },
  },
  {
    value: '894',
    label: {
      rus: 'Замбия',
      eng: 'Zambia',
      kz: 'Замбия',
    },
  },
  {
    value: '112',
    label: {
      rus: 'Беларусь',
      eng: 'Belarus',
      kz: 'Беларусь',
    },
  },
  {
    value: '398',
    label: {
      rus: 'Казахстан',
      eng: 'Kazakhstan',
      kz: 'Қазақстан',
    },
  },
  {
    value: '832',
    label: {
      rus: 'Джерси',
      eng: 'Jersey',
      kz: 'Джерси',
    },
  },
  {
    value: '643',
    label: {
      rus: 'Россия',
      eng: 'Russian Federation',
      kz: 'Ресей Федерациясы',
    },
  },
  {
    value: '417',
    label: {
      rus: 'Кыргызстан',
      eng: 'Kyrgyzstan',
      kz: 'Қырғызстан',
    },
  },
  {
    value: '166',
    label: {
      rus: 'Кокосовые (Килинг) острова',
      eng: 'Cocos (Keeling) Islands',
      kz: 'Кокос (Килинг) аралдары',
    },
  },
]

import React, { useEffect, useRef, useState } from 'react'
import TextInput from '../../../../components/Inputs/TextInput'
import SelectInput from '../../../../components/Inputs/SelectInput'
import FileInput from '../../../../components/Inputs/FileInput'
import { INSTANCE, UPLOADS_ENDPOINT } from '../../../../server-parameters'
import {
  validateName,
  stringDigitsLength,
  validateString,
  smartValidateString,
} from '../../../../validate/Fiscal/Company/validateStepCompanyRus'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import warningImage from '../../../../images/warning.svg'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { getCompany } from '../../../../hooks/useCompanyCustomHook'
import styles from '../../FiscalScreenOne.module.scss'
import AddressInputs from '../../AddressInputs/AddressInputs'
import { getFiscalFullName } from '../../../../utils/TranslateFiscalRf/getFiscalFullName'
import { getFiscalShortName } from '../../../../utils/TranslateFiscalRf/getFiscalShortName'
import { foundingDocumentOptions } from '../../../../content/foundingDocument'
import UrExist from '../../../../components/UrExist'
import { rfCity } from '../../../../content/cities'
import { inclineFirstname, inclineLastname, inclineMiddlename } from 'lvovich';

interface Props extends ScreenProps {
  countriesList: Country[]
  addressCountries: Country[]
  regionCity: any
  regionCityRegistered: any
  regionCityFactual: any
}

/* REACT COMPONENT */
function CompanyScreenOne({
  lang,
  state,
  dispatch,
  accountForm,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
}: Props) {
  
  const [focus, setFocus] = useState('')

  async function autoFill() {
    let response = await getCompany(state.F_INN),
      fullName = response.suggestions[0]?.data.name.full_with_opf,
      shortName = response.suggestions[0]?.data.name.short_with_opf,
      ogrn = response.suggestions[0]?.data.ogrn
    if (
      (fullName && !state.F_FullName) ||
      (fullName && state.F_FullName && !validateName(state.F_FullName, false))
    )
      dispatch({ type: actions.F_FullName, data: fullName })
    if (
      (shortName && !state.F_ShortName) ||
      (shortName &&
        state.F_ShortName &&
        !validateName(state.F_ShortName, false))
    )
      dispatch({ type: actions.F_ShortName, data: shortName })
    if (
      (ogrn && !state.F_OGRN) ||
      (ogrn && state.F_OGRN && !stringDigitsLength(state.F_OGRN, 13, false))
    )
      dispatch({ type: actions.F_OGRN, data: ogrn })
  }
  useEffect(() => {
    if (state.F_INN && stringDigitsLength(state.F_INN, 10, false)) autoFill()
  }, [state.F_INN])
  const fullNameObject = getFiscalFullName(state.F_FiscalType)
  const shortNameObject = getFiscalShortName(state.F_FiscalType)
  const [shortName, setShortName] = useState(shortNameObject)
  const [fullName, setFullName] = useState(fullNameObject)

  useEffect(() => {
    setShortName(shortNameObject)
    setFullName(fullNameObject)
  }, [state.F_FiscalType])
  useEffect(() => {
    dispatch({
      type: actions.F_FiscalType,
      data: '2',
    })
  }, [state.countryRegistration])

  useEffect(() => {
    state.mainCity = INSTANCE === 'second' ? rfCity[0].value : rfCity[1].value
  }, [lang])
  return (
    <>
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-6 col-xs-12">
              {/* F_FullName */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={fullName}
                onChange={F_FullName =>
                  dispatch({ type: actions.F_FullName, data: F_FullName })
                }
                value={state.F_FullName || ''}
                valid={
                  !invalidFields.includes('F_FullName')
                }
                required
                onBlur={() => setFocus('')}
                focus={focus === 'F_FullName' || undefined}
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* F_ShortName */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={shortName}
                onChange={F_ShortName =>
                  dispatch({ type: actions.F_ShortName, data: F_ShortName })
                }
                value={state.F_ShortName || ''}
                valid={
                  !invalidFields.includes('F_ShortName')
                }
                required
                onBlur={() => setFocus('')}
                focus={focus === 'F_ShortName' || undefined}
              />
            </div>
            {state.countryRegistration !== '398' ? (
              <div className="col-2 col-xs-12">
                <DatePicker
                  lang={lang}
                  accountForm={accountForm}
                  accountFormKey="registeredDate"
                  onChange={data => {
                    dispatch({
                      type: actions.REGISTERED_DATE,
                      data,
                    })
                  }}
                  value={state.registeredDate}
                  focus={focus === 'registeredDate' || undefined}
                  onBlur={() => setFocus('')}
                  valid={!invalidFields.includes('registeredDate')}
                  required
                  min={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 67)
                    )
                  }
                  max={new Date()}
                />
              </div>
            ) : null}
            <UrExist
              state={state}
              accountForm={accountForm}
              lang={lang}
              dispatch={dispatch}
              show={!state.isIdentityOk}
            />
            <div className="col-2 col-xs-12">
              {/* F_OGRN */}
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_OGRN}
                onChange={F_OGRN => {
                  dispatch({
                    type: actions.F_OGRN,
                    data: F_OGRN.replace(/\D/gim, '').substr(0, 13),
                  })
                }}
                value={state.F_OGRN || ''}
                valid={
                  stringDigitsLength(state.F_OGRN, 13, false) &&
                  !invalidFields.includes('F_OGRN')
                }
                mask={
                  state.F_FiscalType === '1'
                    ? '999999999999999'
                    : '9999999999999'
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_EGRUL_Image}
                dispatch={dispatch}
                onChange={F_EGRUL_Image => {
                  dispatch({
                    type: actions.F_EGRUL_IMAGE_FILE,
                    data: F_EGRUL_Image,
                  })
                }}
                valid={!invalidFields.includes('F_EGRUL_Image')}
                documentType="EGRYUL"
                value={state.F_EGRUL_Image[state.F_EGRUL_Image.length - 1]}
                imageErrors={state.imageError}
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={state.noLeaseAgreement}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Rent_Contract_Image}
                dispatch={dispatch}
                onChange={F_Rent_Contract_Image => {
                  dispatch({
                    type: actions.F_RENT_CONTRACT_FILE,
                    data: F_Rent_Contract_Image,
                  })
                }}
                valid={!invalidFields.includes('F_Rent_Contract_Image')}
                documentType="Lease"
                value={
                  state.F_Rent_Contract_Image[
                    state.F_Rent_Contract_Image.length - 1
                  ]
                }
                imageErrors={state.imageError}
              />
            </div>
            <div className={styles.inputBox + ' ' + 'col-3 col-xs-12'}>
              <div>
                <input
                  id="hideContractInput"
                  name="hideContractInput"
                  type="checkBox"
                  onChange={() =>
                    dispatch({
                      type: actions.NO_LEASE_AGREEMENT,
                      data: !state.noLeaseAgreement,
                    })
                  }
                  value={false}
                />
                <label htmlFor="hideContractInput">
                  {accountForm.noRentContractBtnDesc[lang]}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3 className={styles.header}>
                {accountForm.Fiscal_Current_Leader_Header[lang]}
              </h3>
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headLastname}
                onChange={(headLastname: string) => {
                  dispatch({
                    type: actions.HEAD_LASTNAME,
                    data: headLastname,
                  })
                  dispatch({
                    type: actions.HEAD_LASTNAME_GENITIVE,
                    data: inclineLastname(headLastname, 'genitive'),
                  })
                }}
                value={state.headLastname || ''}
                valid={
                  state.headLastname.length > 0
                    ? smartValidateString(state.headLastname) &&
                      !invalidFields.includes('headLastname')
                    : !invalidFields.includes('headLastname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headFirstname}
                onChange={(headFirstname: string) => {
                  dispatch({
                    type: actions.HEAD_FIRSTNAME,
                    data: headFirstname,
                  })
                  dispatch({
                    type: actions.HEAD_FIRSTNAME_GENITIVE,
                    data: inclineFirstname(headFirstname, 'genitive'),
                  })
                }}
                value={state.headFirstname || ''}
                valid={
                  state.headFirstname.length > 0
                    ? smartValidateString(state.headFirstname) &&
                      !invalidFields.includes('headFirstname')
                    : !invalidFields.includes('headFirstname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.headPatronymic}
                onChange={(headPatronymic: string) => {
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: headPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: inclineMiddlename(headPatronymic, 'genitive'),
                  })
                }}
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={state.noPatronymic}
                checkChange={() => {
                  dispatch({
                    type: actions.NO_PATRONYMIC,
                    data: !state.noPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: '',
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: '',
                  })
                }}
                value={state.headPatronymic || ''}
                valid={
                  state.headPatronymic.length > 0
                    ? smartValidateString(state.headPatronymic) &&
                      !invalidFields.includes('headPatronymic')
                    : !invalidFields.includes('headPatronymic')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headLastnameGenitive}
                onChange={(headLastnameGenitive: string) =>
                  dispatch({
                    type: actions.HEAD_LASTNAME_GENITIVE,
                    data: headLastnameGenitive,
                  })
                }
                value={state.headLastnameGenitive || ''}
                valid={
                  state.headLastnameGenitive.length > 0
                    ? smartValidateString(state.headLastnameGenitive) &&
                      !invalidFields.includes('headLastnameGenitive')
                    : !invalidFields.includes('headLastnameGenitive')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headFirstnameGenitive}
                onChange={(headFirstnameGenitive: string) =>
                  dispatch({
                    type: actions.HEAD_FIRSTNAME_GENITIVE,
                    data: headFirstnameGenitive,
                  })
                }
                value={state.headFirstnameGenitive || ''}
                valid={
                  state.headFirstnameGenitive.length > 0
                    ? smartValidateString(state.headFirstnameGenitive) &&
                      !invalidFields.includes('headFirstnameGenitive')
                    : !invalidFields.includes('headFirstnameGenitive')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.headPatronymicGenitive}
                onChange={(headPatronymicGenitive: string) =>
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: headPatronymicGenitive,
                  })
                }
                value={state.headPatronymicGenitive || ''}
                valid={
                  state.headPatronymicGenitive.length > 0
                    ? smartValidateString(state.headPatronymicGenitive) &&
                      !invalidFields.includes('headPatronymicGenitive')
                    : !invalidFields.includes('headPatronymicGenitive')
                }
              />
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Genitive[lang]}
                <span>
                  {' '}
                  {accountForm.Fiscal_Warning_Genitive_Example[lang]}
                </span>
              </p>
            </div>
            <div className="col-4 col-xs-12">
              <SelectInput
                testName={'foundingDocument'}
                testErrorName={'error-foundingDocument'}
                lang={lang}
                noOptionsMessage={accountForm.foundingDocument.placeholder}
                content={accountForm.foundingDocument}
                onChange={data => {
                  dispatch({
                    type: actions.FOUNDING_DOCUMENT,
                    data,
                  })
                }}
                value={state.foundingDocument}
                options={foundingDocumentOptions}
                valid={
                  !invalidFields.includes('foundingDocument') &&
                  validateString(state.foundingDocument, 100)
                }
                className={styles.inputl}
              />
            </div>
            <div className="col-4 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headPosition}
                onChange={(headPosition: string) =>
                  dispatch({
                    type: actions.HEADPOSITION,
                    data: headPosition,
                  })
                }
                value={state.headPosition || ''}
                valid={
                  state.headPosition.length > 0
                    ? smartValidateString(state.headPosition) &&
                      !invalidFields.includes('headPosition')
                    : !invalidFields.includes('headPosition')
                }
              />
            </div>
            {lang !== 'eng' && (
              <div className="col-4 col-xs-12">
                <TextInput
                  disabled={!state.agreed}
                  lang={lang}
                  content={accountForm.headPositionGenitive}
                  onChange={(headPositionGenitive: string) =>
                    dispatch({
                      type: actions.HEADPOSITION_GENITIVE,
                      data: headPositionGenitive,
                    })
                  }
                  value={state.headPositionGenitive || ''}
                  valid={
                    state.headPositionGenitive.length > 0
                      ? smartValidateString(state.headPositionGenitive) &&
                        !invalidFields.includes('headPositionGenitive')
                      : !invalidFields.includes('headPositionGenitive')
                  }
                />
              </div>
            )}
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Scan_Directors_Passport}
                dispatch={dispatch}
                onChange={data => {
                  dispatch({
                    type: actions.F_SCAN_DIRECTORS_PASSPORT,
                    data: data,
                  })
                }}
                valid={!invalidFields.includes('F_Scan_Directors_Passport')}
                documentType="Passport1"
                value={
                  state.F_Scan_Directors_Passport[
                    state.F_Scan_Directors_Passport.length - 1
                  ]
                }
                imageErrors={state.imageError}
              />
            </div>
            <div className="col-8 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.foundingDocument_Image}
                dispatch={dispatch}
                onChange={data => {
                  dispatch({
                    type: actions.FOUNDING_DOCUMENT_IMAGE_FILE,
                    data: data,
                  })
                }}
                valid={!invalidFields.includes('foundingDocument_Image')}
                documentType="FoundingDocument"
                value={
                  state.foundingDocument_Image[
                    state.foundingDocument_Image.length - 1
                  ]
                }
                imageErrors={state.imageError}
              />
            </div>
            <div className="col-4 col-xs-12">
              <FileInput
                disabled={!state.agreed}
                lang={lang}
                url={UPLOADS_ENDPOINT}
                autorization={state.formId}
                target={state.documentsImages}
                content={accountForm.F_Scan_Directors_Passport_Address}
                dispatch={dispatch}
                onChange={data => {
                  dispatch({
                    type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
                    data: data,
                  })
                }}
                valid={
                  !invalidFields.includes('F_Scan_Directors_Passport_Address')
                }
                documentType="Passport2"
                value={
                  state.F_Scan_Directors_Passport_Address[
                    state.F_Scan_Directors_Passport_Address.length - 1
                  ]
                }
                imageErrors={state.imageError}
              />
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Scan_Directors_Passport[lang]}
              </p>
            </div>
          </div>
        </div>
        <AddressInputs
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          addressCountries={addressCountries}
          regionCityRegistered={regionCityRegistered}
          regionCityFactual={regionCityFactual}
        />
      </div>
    </>
  )
}

export default CompanyScreenOne

import React, { useEffect, useState } from 'react'

import ProgressBar from './ProgressBar'
import AccountForm from '../../types/account-form.types'
import { Lang } from '../../types/common-types'
import { generateId } from '../../utils/helpers'

import styles from './TopSide.module.scss'

interface Props {
  step: number
  totalsteps: number
  accountForm: AccountForm
  lang: Lang
  className?: string
  stepImage?: string
  profileType: any
  city: string
}

const id = generateId(6)

const TopSide: React.FC<Props> = ({
  step,
  totalsteps,
  accountForm,
  lang,
  profileType,
  city
}: Props) => {
  const [currentStep, ofSteps] = accountForm.stepsText[lang].split('%step%')
  const currentStepText = `${currentStep} ${step}`
  const ofStepsText = ofSteps.replace('%steps%', `${totalsteps}`) //'6')
  const stepsText = `${currentStepText} ${ofStepsText}`

  return (
    <div className={styles.TopSide + ' ' + 'container'}>
      <h1>{accountForm.agreementHeader[lang]} {city}</h1>
      {lang === 'eng' ? (
        <p>
          This process <span>is not a reservation!</span>
          <br /> After reviewing the Questionnaire, the manager will contact you and provide information about personal collateral conditions.
        </p>
      ) : lang === 'kz' ? (
        <p>
          Енгізілетін ақпарат <span>брондау</span> емес және анкеталық ақпарат болып табылады!
          <br /> Анкетаны менеджердің қарастыруынан кейін сізбен байланысқа шығып кепілдік шарттары жайлы ақпарат береді</p>
      ) : (
        <p>
          Данная информация <span>не является бронированием</span> и
          предоставляется только как анкетные данные!
          <br /> После рассмотрения Анкеты с вами свяжется менеджер и
          предоставит информацию о персональных залоговых условиях.
        </p>
      )}
      <ProgressBar
        profileType={profileType}
        step={step}
        totalsteps={totalsteps}
        stepsText={stepsText}
        stepsAriaLabel={accountForm.stepsAriaLabel[lang]}
        accountForm={accountForm}
        lang={lang}
      />
    </div>
  )
}

export default TopSide

import React, { useState, useEffect, useRef, useMemo } from 'react'

import { actions } from '../../state-actions-reducers'
import { Lang, ScreenProps, Country } from '../../types/common-types'
import { State, Dispatch } from '../../state-actions-reducers'
import { validateString, smartValidateString, validatePhone } from '../../validate/validators'
import TextInput from '../../components/Inputs/TextInput'
import PhoneInput from '../../components/Inputs/PhoneInput'
import SelectInput from '../../components/Inputs/SelectInput'
import DatePicker from '../../components/Inputs/DatePicker'
import styles from './ScreenTwo.module.scss'
import warningImage from '../../images/warning.svg'
import SocialLinksInput from '../../components/Inputs/SocialLinksInput'
import EmailInput from '../../components/Inputs/EmailInput'
import MessengersInput from '../../components/Inputs/MessengersInput'
import CheckBoxInput from '../../components/Inputs/CheckBoxInput'
import { checkPhoneEmail } from '../../hooks/useCheckPhoneEmailCustomHook'
import useDebounce from '../../utils/useDebounce'

interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: any
  invalidFields: string[]
}
interface Props extends ScreenProps {
  allCitizenships: Country[]
}
/* THE COMPONENT */
function ScreenTwo({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
  allCitizenships,
}: Props) {
  const [focus, setFocus] = useState('')

  useEffect(() => {
    state.jobOrStudy = accountForm.jobOrStudyValues[0][lang]
  }, [lang])

  useEffect(() => {
    if (state.jobOrStudy === "I'm working" || state.jobOrStudy === "Работаю" || state.jobOrStudy === "Жұмыс істеу") {
      state.collegeName = ""
      state.collegeProfession = ""
      state.collegeStartedAt = ""
      state.collegeEndedAt = ""
    }
    if (state.jobOrStudy === "I'm studying" || state.jobOrStudy === "Учусь" || state.jobOrStudy === "Мен оқып жатырмын") {
      state.occupationInstitution = ""
      state.occupationPosition = ""
      state.workPhoneNumbers = []
    }
  }, [state.jobOrStudy])

  const countriesRef = useRef(
    allCitizenships?.reduce(
      (object, country) => ({
        ...object,
        [country.alpha2]: country[lang],
      }),
      {}
    )
  )
  const countries = countriesRef.current
  const debouncedPhone = useDebounce(state.mobilePhoneNumbers[0], 1500)
  const debouncedMail = useDebounce(state.emails[0], 1500)

  async function phoneMessage() {
    let response = await checkPhoneEmail(state, debouncedPhone, debouncedMail)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_PHONE_OK, data: response.isPhoneOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }
  async function emailMessage() {
    let response = await checkPhoneEmail(state, debouncedPhone, debouncedMail)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_EMAIL_OK, data: response.isEmailOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }
  useEffect(() => {
    if (debouncedPhone) phoneMessage()
    if (debouncedMail) emailMessage()
  }, [debouncedPhone, debouncedMail])
  useEffect(() => {
    let messengers = state.messengers
    if (state.whatsappCheck) {
      messengers = messengers.find((e) => { return e.type === "WhatsApp" })
        ? messengers.map((item) => {
          return (item.type === "WhatsApp") ? {
            type: "WhatsApp",
            description: "WhatsApp",
            link: `https://wa.me/${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
          } : item
        }) : messengers.concat(
          {
            type: "WhatsApp",
            description: "WhatsApp",
            link: `https://wa.me/${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
          },
        ),
        dispatch({
          type: actions.MESSENGERS,
          data: messengers
        })
    }
    if (state.telegramCheck) {
      messengers = messengers.find((e) => { return e.type === "Telegram" })
        ? messengers.map((item) => {
          return (item.type === "Telegram") ? {
            type: "Telegram",
            description: "Telegram",
            link: `https://t.me/+${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
          } : item
        }) : messengers.concat(
          {
            type: "Telegram",
            description: "Telegram",
            link: `https://t.me/+${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
          },
        ),
        dispatch({
          type: actions.MESSENGERS,
          data: messengers
        })
    }
  }, [state.mobilePhoneNumbers])
  return (
    <>
      <main className={styles.main}>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepTwoContactsTitle[lang]}</h3>
            </div>
            <div className="col-4 col-xs-12">
              {/* MOBILE PHONE NUMBER */}
              <PhoneInput
                testName="mobilePhoneNumbers"
                testErrorName="error-mobilePhoneNumbers"
                label={accountForm.mobilePhoneNumber.label[lang]}
                lang={lang}
                numbers={
                  state.mobilePhoneNumbers.length === 0
                    ? ['']
                    : state.mobilePhoneNumbers
                }
                setNumbers={data => {
                  dispatch({
                    type: actions.MOBILE_PHONE_NUMBERS,
                    data,
                  })
                  if (!state.Confirm_phone_ok)
                    //&& !accountForm.Confirm_phone)
                    dispatch({
                      type: actions.Confirm_phone,
                      data: data[0] || '',
                    })
                }}
                invalidText={accountForm.mobilePhoneNumber.invalidText[lang]}
                countries={countries}
                required
                firstlocked={state.Confirm_phone_ok}
                valid={
                  !invalidFields.includes('mobilePhoneNumbers') &&
                  state.isPhoneOk
                }
                phoneCheck={!state.isPhoneOk}
              />
              <div className={styles.messengersCheckBox}>
                <span>{accountForm.contactsMessengersTitle[lang]}</span>
                <div className={styles.messengersCheck}>
                  <CheckBoxInput
                    testId={'telegramCheck'}
                    name={'telegramCheck'}
                    label={accountForm.telegramCheck}
                    lang={lang}
                    onChange={() => {
                      dispatch({
                        type: actions.TELEGRAM_CHECK,
                        data: !state.telegramCheck
                      });
                      !state.telegramCheck ?
                        dispatch({
                          type: actions.MESSENGERS,
                          data: state.messengers.find((e) => { return e.type === "Telegram" })
                            ? state.messengers.map((item) => {
                              return (item.type === "Telegram") ? {
                                type: "Telegram",
                                description: "Telegram",
                                link: `https://t.me/+${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
                              } : item
                            }) : state.messengers.concat(
                              {
                                type: "Telegram",
                                description: "Telegram",
                                link: `https://t.me/+${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
                              },
                            ),
                        })
                        : dispatch({
                          type: actions.MESSENGERS,
                          data: state.messengers.filter((item) => {
                            return item.type !== "Telegram"
                          }),
                        })
                    }}
                    valid={true}
                    value={state.telegramCheck}
                  />
                  <CheckBoxInput
                    testId={'whatsappCheck'}
                    name={'whatsappCheck'}
                    label={accountForm.whatsappCheck}
                    lang={lang}
                    onChange={() => {
                      dispatch({
                        type: actions.WHATSAPP_CHECK,
                        data: !state.whatsappCheck
                      });
                      !state.whatsappCheck ?
                        dispatch({
                          type: actions.MESSENGERS,
                          data: state.messengers.find((e) => { return e.type === "WhatsApp" })
                            ? state.messengers.map((item) => {
                              return (item.type === "WhatsApp") ? {
                                type: "WhatsApp",
                                description: "WhatsApp",
                                link: `https://wa.me/${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
                              } : item
                            }) : state.messengers.concat(
                              {
                                type: "WhatsApp",
                                description: "WhatsApp",
                                link: `https://wa.me/${state.mobilePhoneNumbers[0].replace(/[\D]/gim, '')}`,
                              },
                            ),
                        })
                        : dispatch({
                          type: actions.MESSENGERS,
                          data: state.messengers.filter((item) => {
                            return item.type !== "WhatsApp"
                          }),
                        })
                    }}
                    value={state.whatsappCheck}
                    valid={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-4 col-xs-12">
              <PhoneInput
                testName="contactPhoneSeconds"
                testErrorName="error-contactPhoneSeconds"
                label={accountForm.additionalPhoneNumbers.label[lang]}
                lang={lang}
                numbers={
                  state.contactPhoneSeconds.length === 0
                    ? ['']
                    : state.contactPhoneSeconds
                }
                setNumbers={data => {
                  dispatch({
                    type: actions.CONTACT_PHONE_SECOND,
                    data,
                  })
                }}
                valid={!invalidFields.includes('contactPhoneSeconds')}
              />
            </div>
          </div>
          <div className='row'>
            <div className="col-6 col-xs-12">
              {/* EMAIL */}
              <EmailInput
                testName="email"
                testErrorName="error-email"
                emails={state.emails.length === 0 ? [''] : state.emails}
                setEmails={data => {
                  dispatch({
                    type: actions.EMAILS,
                    data,
                  })
                  if (!state.Confirm_mail_ok)
                    //&& !accountForm.Confirm_mail)
                    dispatch({
                      type: actions.Confirm_mail,
                      data: data[0] || '',
                    })
                }}
                placeholder={accountForm.email.placeholder[lang]}
                invalidText={accountForm.email.invalidText[lang]}
                invalidTextCorrect={accountForm.email.invalidTextCorrect[lang]}
                accountForm={accountForm}
                lang={lang}
                required
                firstlocked={state.Confirm_mail_ok}
                valid={!invalidFields.includes('emails')}
                mailCheck={!state.isEmailOk}
              />
            </div>
            <div className="col-6 col-xs-12">
              <TextInput
                testName="personalWebsite"
                testErrorName="error-personalWebsite"
                lang={lang}
                content={accountForm.personalWebsite}
                value={state.personalWebsite[0]}
                onChange={data => (
                  dispatch({
                    type: actions.PERSONAL_WEBSITE,
                    data: [data],
                  }),
                  console.log(state.personalWebsite)
                )}
                valid={!invalidFields.includes('personalWebsite')}
              />
            </div>
          </div>
        </div>
        <MessengersInput
          lang={lang}
          state={state}
          invalidFields={invalidFields}
          dispatch={dispatch}
          allCitizenships={allCitizenships}
        />
        <SocialLinksInput
          lang={lang}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
        />
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepTwoWorkOrStudyTitle[lang]}</h3>
            </div>
            <div className="col-4 col-xs-12">
              <SelectInput
                testName={'jobOrStudy'}
                testErrorName={'error-jobOrStudy'}
                lang={lang}
                noOptionsMessage={accountForm.jobOrStudy.placeholder}
                content={accountForm.jobOrStudy}
                value={
                  state.jobOrStudy.length > 0
                    ? state.jobOrStudy
                    : accountForm.jobOrStudyValues[0][lang]
                }
                options={accountForm.jobOrStudyValues.map(
                  (e: { rus: string; kz: string; eng: string }) => {
                    return {
                      label: e,
                      value: e[lang],
                    }
                  }
                )}
                onChange={data =>
                  dispatch({
                    type: actions.JOB_OR_STUDY,
                    data: data,
                  })
                }
                className={styles.input}
                valid={
                  !invalidFields.includes('jobOrStudy') &&
                  validateString(state.jobOrStudy, 30)
                }
                required
              />
            </div>
          </div>
          {(state.jobOrStudy === 'Работаю' ||
            state.jobOrStudy === 'Работаю и учусь' ||
            state.jobOrStudy === "I'm working" ||
            state.jobOrStudy === 'I work and study' ||
            state.jobOrStudy === 'Жұмыс істеу' ||
            state.jobOrStudy === 'Жұмыс және оқу') && (
              <div className="row">
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName="occupationInstitution"
                    testErrorName="error-occupationInstitution"
                    lang={lang}
                    content={accountForm.occupationInstitution}
                    value={state.occupationInstitution}
                    onChange={data =>
                      dispatch({
                        type: actions.OCCUPATION_INSTITUTION,
                        data,
                      })
                    }
                    valid={
                      state.occupationInstitution.length > 0
                        ? !invalidFields.includes('occupationInstitution') &&
                        smartValidateString(state.occupationInstitution)
                        : !invalidFields.includes('occupationInstitution')
                    }
                    required
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName="occupationPosition"
                    testErrorName="error-occupationPosition"
                    lang={lang}
                    content={accountForm.occupationPosition}
                    value={state.occupationPosition}
                    onChange={data =>
                      dispatch({
                        type: actions.OCCUPATION_POSITION,
                        data,
                      })
                    }
                    valid={
                      state.occupationPosition.length > 0
                        ? !invalidFields.includes('occupationPosition') &&
                        smartValidateString(state.occupationPosition)
                        : !invalidFields.includes('occupationPosition')
                    }
                    required
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <PhoneInput
                    testName="workPhoneNumbers"
                    testErrorName="error-workPhoneNumbers"
                    label={accountForm.workPhoneNumber.label[lang]}
                    lang={lang}
                    numbers={
                      state.workPhoneNumbers.length === 0
                        ? ['']
                        : state.workPhoneNumbers
                    }
                    setNumbers={data =>
                      dispatch({
                        type: actions.WORK_PHONE_NUMBERS,
                        data,
                      })
                    }
                    invalidText={accountForm.workPhoneNumber.invalidText[lang]}
                    deleteText={accountForm.removeFieldButton[lang]}
                    addField={accountForm.addFieldButton[lang]}
                    valid={!invalidFields.includes('workPhoneNumbers')}
                    required
                  />
                </div>
              </div>
            )}
          {(state.jobOrStudy === 'Учусь' ||
            state.jobOrStudy === 'Работаю и учусь' ||
            state.jobOrStudy === "I'm studying" ||
            state.jobOrStudy === 'I work and study' ||
            state.jobOrStudy === 'Мен оқып жатырмын' ||
            state.jobOrStudy === 'Жұмыс және оқу') && (
              <div className="row">
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName="collegeName"
                    testErrorName="error-collegeName"
                    lang={lang}
                    content={accountForm.collegeName}
                    value={state.collegeName}
                    onChange={data =>
                      dispatch({
                        type: actions.COLLEGE_NAME,
                        data,
                      })
                    }
                    valid={
                      state.collegeName.length > 0
                        ? !invalidFields.includes('collegeName') &&
                        smartValidateString(state.collegeName)
                        : !invalidFields.includes('collegeName')
                    }
                    required
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName="collegeProfession"
                    testErrorName="error-collegeProfession"
                    lang={lang}
                    content={accountForm.collegeProfession}
                    value={state.collegeProfession}
                    onChange={data =>
                      dispatch({
                        type: actions.COLLEGE_PROFESSION,
                        data,
                      })
                    }
                    valid={
                      state.collegeProfession.length > 0
                        ? !invalidFields.includes('collegeProfession') &&
                        smartValidateString(state.collegeProfession)
                        : !invalidFields.includes('collegeProfession')
                    }
                    required
                  />
                </div>
                <div className="col-2 col-xs-12">
                  <DatePicker
                    testName="collegeStartedAt"
                    testErrorName="error-collegeStartedAt"
                    lang={lang}
                    accountForm={accountForm}
                    accountFormKey="collegeStartedAt"
                    onChange={data => {
                      dispatch({
                        type: actions.COLLEGE_STARTED_AT,
                        data,
                      })
                    }}
                    value={state.collegeStartedAt}
                    min={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 10)
                      )
                    }
                    curr={
                      state.collegeEndedAt
                        ? new Date(
                          new Date().setDate(
                            new Date(state.collegeEndedAt).getDate() - 1
                          )
                        )
                        : new Date()
                    }
                    max={new Date()}
                    focus={focus === 'collegeStartedAt' || undefined}
                    onBlur={() => setFocus('')}
                    valid={!invalidFields.includes('collegeStartedAt')}
                    required
                  />
                </div>
                <div className="col-2 col-xs-12">
                  <DatePicker
                    testName="collegeEndedAt"
                    testErrorName="error-collegeEndedAt"
                    lang={lang}
                    accountForm={accountForm}
                    accountFormKey="collegeEndedAt"
                    onChange={data => {
                      dispatch({
                        type: actions.COLLEGE_ENDED_AT,
                        data,
                      })
                    }}
                    value={state.collegeEndedAt}
                    curr={
                      state.collegeStartedAt
                        ? new Date(
                          new Date().setDate(
                            new Date(state.collegeStartedAt).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    min={
                      new Date(
                        new Date(
                          new Date().setFullYear(new Date().getFullYear())
                        ).setDate(new Date().getDate() + 1)
                      )
                    }
                    max={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 10)
                      )
                    }
                    focus={focus === 'collegeEndedAt' || undefined}
                    onBlur={() => setFocus('')}
                    valid={!invalidFields.includes('collegeEndedAt')}
                    required
                  />
                </div>
              </div>
            )}
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepTwoRecomendation[lang]}</h3>
            </div>
            <div className="col-6 col-xs-12">
              <TextInput
                testName="recommendations"
                testErrorName="error-recommendations"
                lang={lang}
                content={accountForm.recommendations}
                value={state.recommendations}
                onChange={data =>
                  dispatch({
                    type: actions.RECOMMENDATIONS,
                    data,
                  })
                }
                valid={!invalidFields.includes('recommendations')}
                required
              />
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.stepRecomendationWarning[lang]}
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ScreenTwo

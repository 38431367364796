import React, { useMemo } from 'react'

import { generateId } from '../../utils/helpers'
import { CheckBoxProps } from '../../types/common-types'

import styles from './CheckBoxInput.module.scss'
import cn from 'classnames'

export default function CheckBoxInput({
  lang,
  label,
  className,
  onChange,
  value,
  name,
  testId
}: CheckBoxProps) {
  return (
    <div className={cn(styles.checkInputBox, className)}>
      <input
        data-test-id={testId}
        id={name}
        name={name}
        type="checkBox"
        onChange={() => {
          onChange(!value)
        }}
        checked={value}
      />
      <label htmlFor={name}>
        {label[lang]}
      </label>
    </div>
  )
}

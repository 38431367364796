import React, { useRef, useState, useEffect, useMemo } from 'react'
import useDebounce from '../../../../utils/useDebounce'
import TextInput from '../../../../components/Inputs/TextInput'
import SelectInput from '../../../../components/Inputs/SelectInput'
import FileInput from '../../../../components/Inputs/FileInput'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { copyContactPerson, yearsAgo } from '../../../../utils/helpers'
import {
  validateName,
  validateString,
  validateDate,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { checkPhoneEmail } from '../../../../hooks/useCheckPhoneEmailCustomHook'
import styles from '../../FiscalScreenOne.module.scss'
import warningImage from '../../../../images/warning.svg'
import RussianForm from '../KzInstance/Citizenships/RussianForm'
import KyrgyzstanForm from '../KzInstance/Citizenships/KyrgyzstanForm'
import RomanianForm from '../KzInstance/Citizenships/RomanianForm'
import ChinaForm from '../KzInstance/Citizenships/ChinaForm'
import KazakhstanForm from '../KzInstance/Citizenships/KazakhstanForm'
import BelarusForm from '../KzInstance/Citizenships/BelarusForm'
import UzbekistanForm from '../KzInstance/Citizenships/UzbekistanForm'
import VietnamForm from '../KzInstance/Citizenships/VietnamForm'
import AddressInputs from '../../AddressInputs/AddressInputs'
import {
  passportTypeKz,
  passportTypeRfForKz,
  passportTypeRom,
  passportTypeKgz,
  passportTypeGer,
  passportTypeUsa,
  passportTypeTaj,
  passportTypeTur,
  passportTypeUzb,
  DocumentTypeValuesUdvAbroad,
  DocumentTypeValuesTypePersonalities,
  DocumentTypeValuesTypePassport,
} from '../../../../content/passportTypeFiscal'
import { stringDigitsLength } from '../../../../validate/validators'
import { soleTraderType } from '../../../../content/fiscalType'
import { getFiscalFullName } from '../../../../utils/TranslateFiscalKzt/getFiscalFullName'
import { getFiscalShortName } from '../../../../utils/TranslateFiscalKzt/getFiscalShortName'
import { getCertificateRegistrationNumber } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationNumber'
import { getCertificateRegistrationDate } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationDate'
import { getCertificateRegistrationImage } from '../../../../utils/TranslateFiscalKgz/getCertificateRegistrationImage'
import AddCitizenships from '../../../../components/AddCitizenships'
import { kzCity } from '../../../../content/cities'
import UrExist from '../../../../components/UrExist'
import { inclineFirstname, inclineLastname, inclineMiddlename } from 'lvovich';

interface Props extends ScreenProps {
  citizenshipCountriesList: Country[]
  allFiscalCountry: Country[]
  countriesList: Country[]
  addressCountries: Country[]
  regionCity: any
  regionCityRegistered: any
  regionCityFactual: any
  regionCityResidence: any
}

/* REACT COMPONENT */
function IpScreenOne({
  lang,
  state,
  dispatch,
  accountForm,
  allFiscalCountry,
  citizenshipCountriesList,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
  regionCityResidence,
}: Props) {
  const getCitizenshipFormat = (citizenship: string) => {
    return citizenshipCountriesList.find((country: Country) => {
      return country.value === citizenship
    })?.label?.rus
  }
  const selectedCitizenship = useMemo(() => {
    return getCitizenshipFormat(state.citizenship)
  }, [state.citizenship])

  const [focus, setFocus] = useState('')
  const debouncedPhone = useDebounce(state.mobilePhoneNumbers[0], 1500)
  const debouncedMail = useDebounce(state.emails[0], 1500)

  async function phoneEmailMessage() {
    let response = await checkPhoneEmail(state, debouncedPhone, debouncedMail)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_PHONE_OK, data: response.isPhoneOk })
      dispatch({ type: actions.IS_EMAIL_OK, data: response.isEmailOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }
  useEffect(() => {
    if (debouncedPhone || debouncedMail) phoneEmailMessage()
  }, [debouncedPhone, debouncedMail])

  const fullNameObject = getFiscalFullName(
    state.F_FiscalType,
    state.countryRegistration
  )
  const shortNameObject = getFiscalShortName(
    state.F_FiscalType,
    state.countryRegistration
  )

  const [shortName, setShortName] = useState(shortNameObject)
  const [fullName, setFullName] = useState(fullNameObject)

  useEffect(() => {
    setShortName(shortNameObject)
    setFullName(fullNameObject)
  }, [state.F_FiscalType])
  useEffect(() => {
    dispatch({
      type: actions.F_FiscalType,
      data: '1',
    })
  }, [state.countryRegistration])

  useEffect(() => {
    state.mainCity = kzCity[0].value
  }, [lang])

  useEffect(() => {
    if (state.passportType?.length === 0) {
      dispatch({
        type: actions.PASSPORT_TYPE,
        data:
          selectedCitizenship === 'Казахстан'
            ? passportTypeKz[0].value
            : passportTypeRfForKz[0].value,
      })
    }
  }, [selectedCitizenship, state.F_FiscalType])

  const certificateRegistrationNumber = getCertificateRegistrationNumber(
    state.F_FiscalType
  )
  const certificateRegistrationDate = getCertificateRegistrationDate(
    state.F_FiscalType
  )
  const certificateRegistrationImage = getCertificateRegistrationImage(
    state.F_FiscalType
  )
  const [showAddCitizenships, setShowAddCitizenships] = useState(false)
  console.log('invalidFields', invalidFields)
  return (
    <>
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-8 col-xs-12">
              <SelectInput
                testName={'soleTraderType'}
                testErrorName={'error-soleTraderType'}
                lang={lang}
                noOptionsMessage={accountForm.noOptionsMessage}
                content={accountForm.soleTraderType}
                onChange={data => {
                  dispatch({
                    type: actions.SOLE_TRADER_TYPE,
                    data,
                  })
                }}
                value={state.soleTraderType}
                options={soleTraderType}
                valid={
                  !invalidFields.includes('soleTraderType') &&
                  validateName(state.soleTraderType)
                }
                className={styles.inputl}
              />
            </div>
            <div className="col-2 col-xs-12">
              <TextInput
                testName={`F_INN`}
                testErrorName={`error-F_INN`}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_IIN}
                onChange={F_INN => {
                  dispatch({
                    type: actions.F_INN,
                    data: F_INN.replace(/\D/gim, '').substr(0, 12),
                  })
                }}
                value={state.F_INN || ''}
                required
                valid={
                  state.F_INN.replace(/[^0-9]/g, '').length > 0 &&
                  invalidFields.length > 0
                    ? state.F_INN.replace(/[^0-9]/g, '').length === 12
                    : validateName(state.F_INN, false) &&
                      !invalidFields.includes('F_INN')
                }
                mask={'999999999999'}
              />
            </div>
            <UrExist
              state={state}
              accountForm={accountForm}
              lang={lang}
              dispatch={dispatch}
              show={!state.isIdentityOk}
            />
            {state.soleTraderType === '1' ? (
              <div className="col-5 col-xs-12">
                {/* certificateRegistrationNumber */}
                <TextInput
                  testName={`certificateRegistrationNumber`}
                  testErrorName={`error-certificateRegistrationNumber`}
                  disabled={!state.agreed}
                  lang={lang}
                  content={certificateRegistrationNumber}
                  onChange={certificateRegistrationNumber => {
                    dispatch({
                      type: actions.CERTIFICATE_REGISTRATION_NUMBER,
                      data: certificateRegistrationNumber.replace(/\D/gim, ''),
                    })
                  }}
                  required
                  value={state.certificateRegistrationNumber || ''}
                  valid={
                    validateString(
                      state.certificateRegistrationNumber,
                      30,
                      false
                    ) &&
                    !invalidFields.includes('certificateRegistrationNumber')
                  }
                  mask={'9999 9999999'}
                />
              </div>
            ) : (
              <>
                <div className="col-5 col-xs-12">
                  {/* registrationTicket */}
                  <TextInput
                    testName={`registrationTicket`}
                    testErrorName={`error-registrationTicket`}
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.registrationTicket}
                    onChange={registrationTicket => {
                      dispatch({
                        type: actions.REGISTRATION_TICKET,
                        data: registrationTicket,
                      })
                    }}
                    required
                    value={state.registrationTicket || ''}
                    mask={'***************'}
                    valid={!invalidFields.includes('registrationTicket')}
                  />
                </div>
                <div className="col-5 col-xs-12">
                  {/* registrationNotice */}
                  <TextInput
                    testName={`registrationNotice`}
                    testErrorName={`error-registrationNotice`}
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.registrationNotice}
                    onChange={registrationNotice => {
                      dispatch({
                        type: actions.REGISTRATION_NOTICE,
                        data: registrationNotice,
                      })
                    }}
                    required
                    value={state.registrationNotice || ''}
                    mask={'***************'}
                    valid={!invalidFields.includes('registrationNotice')}
                  />
                </div>
              </>
            )}

            <div className="col-8 col-xs-12">
              {/* F_FullName */}
              <TextInput
                testName={`F_FullName`}
                testErrorName={`error-F_FullName`}
                disabled={!state.agreed}
                lang={lang}
                content={fullName}
                onChange={F_FullName =>
                  dispatch({ type: actions.F_FullName, data: F_FullName })
                }
                value={state.F_FullName || ''}
                valid={
                  !invalidFields.includes('F_FullName')
                }
                required
                onBlur={() => setFocus('')}
                focus={focus === 'F_FullName' || undefined}
              />
            </div>
            {state.soleTraderType === '1' ? (
              <>
                <div className="col-3 col-xs-12">
                  <DatePicker
                    testName={`registeredDate`}
                    testErrorName={`error-registeredDate`}
                    lang={lang}
                    accountForm={certificateRegistrationDate}
                    accountFormKey="date"
                    onChange={data => {
                      dispatch({
                        type: actions.REGISTERED_DATE,
                        data,
                      })
                    }}
                    value={state.registeredDate}
                    valid={!invalidFields.includes('registeredDate')}
                    min={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 67)
                      )
                    }
                    max={new Date()}
                    required
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <FileInput
                    testName={`stateRegistrationCertificate`}
                    testErrorName={`error-stateRegistrationCertificate`}
                    disabled={!state.agreed}
                    lang={lang}
                    url={UPLOADS_ENDPOINT}
                    autorization={state.formId}
                    target={state.documentsImages}
                    content={certificateRegistrationImage}
                    dispatch={dispatch}
                    onChange={stateRegistrationCertificate => {
                      dispatch({
                        type: actions.STATE_REGISTRATION_CERTIFICATE,
                        data: stateRegistrationCertificate,
                      })
                    }}
                    value={state.stateRegistrationCertificate[0]}
                    valid={
                      !invalidFields.includes('stateRegistrationCertificate')
                    }
                    documentType="stateRegistrationCertificate"
                    imageErrors={state.imageError}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-3 col-xs-12">
                  <DatePicker
                    testName={`receptionNoticeDate`}
                    testErrorName={`error-receptionNoticeDate`}
                    lang={lang}
                    accountForm={accountForm}
                    accountFormKey="receptionNoticeDate"
                    onChange={data => {
                      dispatch({
                        type: actions.RECEPTION_NOTICE_DATE,
                        data,
                      })
                    }}
                    value={state.receptionNoticeDate}
                    valid={!invalidFields.includes('receptionNoticeDate')}
                    min={new Date(2017, 0, 1)}
                    max={new Date()}
                    required
                  />
                </div>
                <div className="col-3 col-xs-12">
                  <DatePicker
                    testName={`registrationNoticeDate`}
                    testErrorName={`error-registrationNoticeDate`}
                    lang={lang}
                    accountForm={accountForm}
                    accountFormKey="registrationNoticeDate"
                    onChange={data => {
                      dispatch({
                        type: actions.REGISTRATION_NOTICE_DATE,
                        data,
                      })
                    }}
                    value={state.registrationNoticeDate}
                    valid={!invalidFields.includes('registrationNoticeDate')}
                    min={
                      state.receptionNoticeDate
                        ? new Date(state.receptionNoticeDate)
                        : new Date(2017, 0, 1)
                    }
                    max={new Date()}
                    required
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <FileInput
                    testName={`scanSoleRegistrationTicket`}
                    testErrorName={`error-scanSoleRegistrationTicket`}
                    disabled={!state.agreed}
                    lang={lang}
                    url={UPLOADS_ENDPOINT}
                    autorization={state.formId}
                    target={state.documentsImages}
                    content={accountForm.scanSoleRegistrationTicket}
                    dispatch={dispatch}
                    onChange={scanSoleRegistrationTicket => {
                      dispatch({
                        type: actions.SCAN_SOLE_REGISTRATION_TICKET,
                        data: scanSoleRegistrationTicket,
                      })
                    }}
                    value={state.scanSoleRegistrationTicket[0]}
                    valid={
                      !invalidFields.includes('scanSoleRegistrationTicket')
                    }
                    documentType="aboutcompanydump"
                    singleType="aboutcompanydump1"
                    imageErrors={state.imageError}
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <FileInput
                    testName={'scanSoleRegistrationTicketSecond'}
                    testErrorName={'error-scanSoleRegistrationTicketSecond'}
                    disabled={!state.agreed}
                    lang={lang}
                    url={UPLOADS_ENDPOINT}
                    autorization={state.formId}
                    target={state.documentsImages}
                    content={accountForm.scanSoleRegistrationTicketSecond}
                    dispatch={dispatch}
                    onChange={scanSoleRegistrationTicketSecond => {
                      dispatch({
                        type: actions.SCAN_SOLE_REGISTRATION_TICKET_SECOND,
                        data: scanSoleRegistrationTicketSecond,
                      })
                    }}
                    value={state.scanSoleRegistrationTicketSecond[0]}
                    valid={
                      !invalidFields.includes(
                        'scanSoleRegistrationTicketSecond'
                      )
                    }
                    documentType="aboutcompanydump"
                    singleType="aboutcompanydump2"
                    imageErrors={state.imageError}
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <FileInput
                    testName={'scanOfNotificationFirst'}
                    testErrorName={'error-scanOfNotificationFirst'}
                    disabled={!state.agreed}
                    lang={lang}
                    url={UPLOADS_ENDPOINT}
                    autorization={state.formId}
                    target={state.documentsImages}
                    content={accountForm.scanOfNotificationFirst}
                    dispatch={dispatch}
                    onChange={scanOfNotification => {
                      dispatch({
                        type: actions.SCAN_NOTIFICATION_FIRST,
                        data: scanOfNotification,
                      })
                    }}
                    value={state.scanOfNotificationFirst[0]}
                    valid={!invalidFields.includes('scanOfNotificationFirst')}
                    documentType="aboutcompanydump"
                    singleType="aboutcompanydump3"
                    imageErrors={state.imageError}
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <FileInput
                    testName={'scanOfNotificationSecond'}
                    testErrorName={'error-scanOfNotificationSecond'}
                    disabled={!state.agreed}
                    lang={lang}
                    url={UPLOADS_ENDPOINT}
                    autorization={state.formId}
                    target={state.documentsImages}
                    content={accountForm.scanOfNotificationSecond}
                    dispatch={dispatch}
                    onChange={scanOfNotification => {
                      dispatch({
                        type: actions.SCAN_NOTIFICATION_SECOND,
                        data: scanOfNotification,
                      })
                    }}
                    value={state.scanOfNotificationSecond[0]}
                    valid={!invalidFields.includes('scanOfNotificationSecond')}
                    documentType="aboutcompanydump"
                    singleType="aboutcompanydump4"
                    imageErrors={state.imageError}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-6 col-xs-12">
              {/* oKED */}
              <TextInput
                testName={'oKED'}
                testErrorName={'error-oKED'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.oKED}
                onChange={oKED => {
                  dispatch({
                    type: actions.OKED,
                    data: oKED,
                  })
                }}
                required
                mask="99999"
                value={state.oKED || ''}
                valid={!invalidFields.includes('oKED')}
              />
            </div>
            <div className="col-1 col-xs-12">
              {/* F_KBe */}
              <TextInput
                testName={'F_KBe'}
                testErrorName={'error-F_KBe'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_KBe}
                onKeyDown={(event: any) => {
                  if (
                    state.F_KBe.replace(/_/gim, '').length === 0 &&
                    event.key > 2
                  ) {
                    event.preventDefault()
                  }
                }}
                onChange={F_KBe => {
                  dispatch({
                    type: actions.F_KBe,
                    data: F_KBe,
                  })
                }}
                mask="99"
                value={state.F_KBe}
                valid={!invalidFields.includes('F_KBe')}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepOneIdentification[lang]}</h3>
            </div>
            <div className="col-4 col-xs-12">
              {/* LAST NAME */}
              <TextInput
                testName={'headLastname'}
                testErrorName={'error-headLastname'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headLastname}
                onChange={headLastname => {
                  dispatch({ type: actions.HEAD_LASTNAME, data: headLastname })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_LASTNAME,
                      data: headLastname,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_LASTNAME_GENITIVE,
                      data: headLastname,
                    })
                  } else {
                    dispatch({
                      type: actions.HEAD_LASTNAME_GENITIVE,
                      data: inclineLastname(headLastname, 'genitive'),
                    })
                  }
                }}
                value={state.headLastname || ''}
                required
                valid={
                  state.headLastname.length > 0
                    ? smartValidateString(state.headLastname) &&
                      !invalidFields.includes('headLastname')
                    : !invalidFields.includes('headLastname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* FIRST NAME */}
              <TextInput
                testName={'headFirstname'}
                testErrorName={'error-headFirstname'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.headFirstname}
                onChange={headFirstname => {
                  dispatch({
                    type: actions.HEAD_FIRSTNAME,
                    data: headFirstname,
                  })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_FIRSTNAME,
                      data: headFirstname,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_FIRSTNAME_GENITIVE,
                      data: headFirstname,
                    })
                  } else {
                    dispatch({
                      type: actions.HEAD_FIRSTNAME_GENITIVE,
                      data: inclineFirstname(headFirstname, 'genitive'),
                    })
                  }
                }}
                value={state.headFirstname || ''}
                required
                valid={
                  state.headFirstname.length > 0
                    ? smartValidateString(state.headFirstname) &&
                      !invalidFields.includes('headFirstname')
                    : !invalidFields.includes('headFirstname')
                }
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* PATRONYMIC */}
              <TextInput
                testName={'headPatronymic'}
                testErrorName={'error-headPatronymic'}
                testCheckName={'headNoPatronymic'}
                disabled={!state.agreed || state.noPatronymic}
                lang={lang}
                content={accountForm.headPatronymic}
                onChange={headPatronymic => {
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: headPatronymic,
                  })
                  if (state.copyContactPerson)
                    dispatch({
                      type: actions.CONTACT_PERSON_PATRONYMIC,
                      data: headPatronymic,
                    })
                  if (lang === 'eng') {
                    dispatch({
                      type: actions.HEAD_PATRONYMIC_GENITIVE,
                      data: headPatronymic,
                    })
                  } else {
                    dispatch({
                      type: actions.HEAD_PATRONYMIC_GENITIVE,
                      data: inclineMiddlename(headPatronymic, 'genitive'),
                    })
                  }
                }}
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={state.noPatronymic}
                checkChange={() => {
                  dispatch({
                    type: actions.NO_PATRONYMIC,
                    data: !state.noPatronymic,
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC,
                    data: '',
                  })
                  dispatch({
                    type: actions.HEAD_PATRONYMIC_GENITIVE,
                    data: '',
                  })
                }}
                required
                value={state.headPatronymic || ''}
                valid={
                  state.headPatronymic.length > 0
                    ? smartValidateString(state.headPatronymic) &&
                      !invalidFields.includes('headPatronymic')
                    : !invalidFields.includes('headPatronymic')
                }
              />
            </div>
            {lang !== 'eng' ? (
              <>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName={'headLastnameGenitive'}
                    testErrorName={'error-headLastnameGenitive'}
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.headLastnameGenitive}
                    onChange={(headLastnameGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_LASTNAME_GENITIVE,
                        data: headLastnameGenitive,
                      })
                    }
                    value={state.headLastnameGenitive || ''}
                    valid={
                      state.headLastnameGenitive.length > 0
                        ? smartValidateString(state.headLastnameGenitive) &&
                          !invalidFields.includes('headLastnameGenitive')
                        : !invalidFields.includes('headLastnameGenitive')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName={'headFirstnameGenitive'}
                    testErrorName={'error-headFirstnameGenitive'}
                    disabled={!state.agreed}
                    lang={lang}
                    content={accountForm.headFirstnameGenitive}
                    onChange={(headFirstnameGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_FIRSTNAME_GENITIVE,
                        data: headFirstnameGenitive,
                      })
                    }
                    value={state.headFirstnameGenitive || ''}
                    valid={
                      state.headFirstnameGenitive.length > 0
                        ? smartValidateString(state.headFirstnameGenitive) &&
                          !invalidFields.includes('headFirstnameGenitive')
                        : !invalidFields.includes('headFirstnameGenitive')
                    }
                  />
                </div>
                <div className="col-4 col-xs-12">
                  <TextInput
                    testName={'headPatronymicGenitive'}
                    testErrorName={'error-headPatronymicGenitive'}
                    testCheckName={'headNoPatronymic'}
                    disabled={!state.agreed || state.noPatronymic}
                    lang={lang}
                    content={accountForm.headPatronymicGenitive}
                    onChange={(headPatronymicGenitive: string) =>
                      dispatch({
                        type: actions.HEAD_PATRONYMIC_GENITIVE,
                        data: headPatronymicGenitive,
                      })
                    }
                    checkLabel={accountForm.noPatronymic[lang]}
                    checkValue={state.noPatronymic}
                    checkChange={() => {
                      dispatch({
                        type: actions.NO_PATRONYMIC,
                        data: !state.noPatronymic,
                      })
                      dispatch({
                        type: actions.HEAD_PATRONYMIC,
                        data: '',
                      })
                      dispatch({
                        type: actions.HEAD_PATRONYMIC_GENITIVE,
                        data: '',
                      })
                    }}
                    value={state.headPatronymicGenitive || ''}
                    valid={
                      state.headPatronymicGenitive.length > 0
                        ? smartValidateString(state.headPatronymicGenitive) &&
                          !invalidFields.includes('headPatronymicGenitive')
                        : !invalidFields.includes('headPatronymicGenitive')
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="row">
            <div className="col-4 col-xs-12">
              {/* CITIZENSHIP */}
              <SelectInput
                testName={'citizenship'}
                testErrorName={'error-citizenship'}
                noOptionsMessage={accountForm.citizenshipInput.placeholder}
                lang={lang}
                disabled={!state.agreed}
                content={accountForm.citizenshipInput}
                options={citizenshipCountriesList}
                onChange={citizenship => {
                  dispatch({
                    type: actions.CITIZENSHIP,
                    data: citizenship,
                  })
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data:
                      getCitizenshipFormat(citizenship) === 'Казахстан'
                        ? passportTypeKz[0].value
                        : passportTypeRfForKz[0].value,
                  })
                }}
                valid={!invalidFields.includes('citizenship')}
                value={state.citizenship}
                required
                prefix="Citizenships"
              />
              <span
                className={styles.addCitizenships}
                onClick={() => setShowAddCitizenships(!showAddCitizenships)}
              >
                {accountForm.noCitizenshipsLink[lang]}
              </span>
            </div>
            <div className="col-3 col-xs-12">
              {/* BIRTH DATE */}
              <DatePicker
                testName={'birthDate'}
                testErrorName={'error-birthDate'}
                disabled={!state.agreed}
                lang={lang}
                accountForm={accountForm}
                accountFormKey="birthDate"
                onChange={birthDate =>
                  dispatch({ type: actions.BIRTH_DATE, data: birthDate })
                }
                value={state.birthDate}
                valid={
                  !invalidFields.includes('birthDate') &&
                  validateDate({
                    current: state.birthDate,
                    min: yearsAgo(130),
                    max: yearsAgo(14),
                  })
                }
                curr={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                min={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 85)
                  )
                }
                max={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                required
              />
            </div>
            <div className="col-5 col-xs-12">
              {/* BIRTH PLACE */}
              <TextInput
                testName={'birthPlace'}
                testErrorName={'error-birthPlace'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.birthPlace}
                onChange={birthPlace => {
                  dispatch({
                    type: actions.BIRTH_PLACE,
                    data: birthPlace,
                  })
                }}
                value={state.birthPlace}
                required
                valid={
                  state.birthPlace.length > 0
                    ? smartValidateString(state.birthPlace) &&
                      !invalidFields.includes('birthPlace')
                    : !invalidFields.includes('birthPlace')
                }
                onBlur={() => setFocus('')}
              />
            </div>
            {showAddCitizenships ? (
              <div className="col-12">
                <AddCitizenships
                  state={state}
                  accountForm={accountForm}
                  lang={lang}
                  handlerChange={() => setShowAddCitizenships(false)}
                  valid={
                    state.mobilePhoneNumbers.length > 0 &&
                    state.emails.length > 0 &&
                    state.isPhoneOk &&
                    state.isEmailOk
                  }
                  noContacts={true}
                  dispatch={dispatch}
                  invalidFields={invalidFields}
                />
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-3 col-xs-12">
              <SelectInput
                testName={'passportType'}
                testErrorName={'error-passportType'}
                lang={lang}
                noOptionsMessage={accountForm.passportTypeInput.placeholder}
                options={
                  selectedCitizenship === 'Казахстан'
                    ? passportTypeKz
                    : selectedCitizenship === 'Россия'
                    ? passportTypeRfForKz
                    : selectedCitizenship === 'Беларусь'
                    ? passportTypeRfForKz
                    : selectedCitizenship === 'Кыргызстан'
                    ? passportTypeKgz
                    : selectedCitizenship === 'Румыния'
                    ? passportTypeRom
                    : selectedCitizenship === 'Германия'
                    ? passportTypeGer
                    : selectedCitizenship === 'Соединенные Штаты'
                    ? passportTypeUsa
                    : selectedCitizenship === 'Таджикистан'
                    ? passportTypeTaj
                    : selectedCitizenship === 'Турция'
                    ? passportTypeTur
                    : selectedCitizenship === 'Узбекистан'
                    ? passportTypeUzb
                    : DocumentTypeValuesTypePassport
                }
                content={accountForm.passportTypeInput}
                onChange={data => {
                  dispatch({
                    type: actions.PASSPORT_TYPE,
                    data,
                  })
                }}
                value={state.passportType}
                valid={
                  !invalidFields.includes('passportType') &&
                  validateString(state.passportType, 300, true)
                }
                required
              />
            </div>
            {selectedCitizenship === 'Россия' ? (
              <RussianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Китай' ? (
              <ChinaForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Казахстан' ? (
              <KazakhstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Кыргызстан' ? (
              <KyrgyzstanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Румыния' ? (
              <RomanianForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Беларусь' ? (
              <BelarusForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Бразилия' ||
            selectedCitizenship === 'Италия' ? (
              <BelarusForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Австралия' ||
            selectedCitizenship === 'Азербайджан' ||
            selectedCitizenship === 'Армения' ||
            selectedCitizenship === 'Соединенное Королевство' ||
            selectedCitizenship === 'Германия' ||
            selectedCitizenship === 'Испания' ||
            selectedCitizenship === 'Сирийская Арабская Республика' ||
            selectedCitizenship === 'Соединенные Штаты' ||
            selectedCitizenship === 'Таджикистан' ||
            selectedCitizenship === 'Узбекистан' ||
            selectedCitizenship === 'Франция' ||
            selectedCitizenship === 'Швейцария' ? (
              <UzbekistanForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            {selectedCitizenship === 'Болгария' ||
            selectedCitizenship === 'Израиль' ||
            selectedCitizenship === 'Исландия' ||
            selectedCitizenship === 'Молдова, Республика' ||
            selectedCitizenship === 'Нидерланды' ||
            selectedCitizenship === 'Норвегия' ||
            selectedCitizenship === 'Швеция' ||
            selectedCitizenship === 'Южная Африка' ||
            selectedCitizenship === 'Вьетнам' ||
            selectedCitizenship === 'Грузия' ||
            selectedCitizenship === 'Индонезия' ||
            selectedCitizenship === 'Индия' ||
            selectedCitizenship === 'Корея, Республика' ||
            selectedCitizenship === 'Финляндия' ? (
              <VietnamForm
                initialState={state}
                state={state}
                lang={lang}
                invalidFields={invalidFields}
                dispatch={dispatch}
                accountForm={accountForm}
              />
            ) : null}
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.Fiscal_Warning_Scan_Directors_Passport_KZT[lang]}
              </p>
            </div>
          </div>
        </div>
        <AddressInputs
          lang={lang}
          initialState={state}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
          addressCountries={addressCountries}
          regionCityRegistered={regionCityRegistered}
          regionCityFactual={regionCityFactual}
          regionCityResidence={regionCityResidence}
        />
      </div>
    </>
  )
}

export default IpScreenOne

import { Option } from "../types/common-types";

export const messengersLinksOptions: Option[] = [
  {
    value: "WhatsApp",
    label: { 
      eng: "WhatsApp", 
      rus: "WhatsApp", 
      kz: "WhatsApp" },
  },
  {
    value: "Telegram",
    label: {
      eng: "Telegram",
      rus: "Telegram",
      kz: "Telegram",
    },
  },
];

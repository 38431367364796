import React, { useRef } from "react";
import SelectInput from "./SelectInput";
import { Country, Lang } from "../../types/common-types";
import { State, Dispatch, actions } from '../../state-actions-reducers'
import { messengersLinksOptions } from "../../content/messengersLinks";
import TextInput from "./TextInput";
import PhoneInput from "./PhoneInput";
import accountForm from "../../content/account-form";

interface Props {
    lang: Lang;
    state: State;
    invalidFields: (string | false)[];
    dispatch: Dispatch;
    index: number
    allCitizenships: Country[]
    disabled: boolean
}

export default function MessengersItem({ lang, state, invalidFields, dispatch, index, allCitizenships, disabled }: Props) {
    const countriesRef = useRef(
        allCitizenships?.reduce(
          (object, country) => ({
            ...object,
            [country.alpha2]: country[lang],
          }),
          {}
        )
      )
      const countries = countriesRef.current
      console.log(state?.messengers[index]?.type, state?.messengers[index]?.type)
    return <>
        <div className={'col-6'}>
            <SelectInput
                testName={`messengersType-${index}`}
                testErrorName={`error-messengersType-${index}`}
                noOptionsMessage={
                    accountForm.messengerLinksDescription.placeholder
                }
                options={messengersLinksOptions.filter((item)=> {
                    return !state.messengers.find((e)=> {
                        return e.type === item.value
                    })
                })}
                disabled={disabled}
                lang={lang}
                content={accountForm.messengerLinksDescription}
                onChange={(e: any) => {
                    console.log('e', e)
                    dispatch({
                        type: actions.MESSENGERS,
                        data: index < state.messengers.length
                            ? state.messengers.map((item, i) => {
                                return (index === i) ? {
                                    type: e,
                                    description: e,
                                    link: state.messengers[index]?.link ?? "",
                                } : item
                            }) : state.messengers.concat({
                                type: e,
                                description: e,
                                link: state.messengers[index]?.link ?? "",
                            })
                    })
                }}
                valid={!invalidFields.includes('messengersType')}
                value={state?.messengers[index]?.type}
                optionValue={{
                    label: state?.messengers[index]?.type,
                    value: state?.messengers[index]?.type
                }}
            />
        </div>
        <div className={'col-6'}>
            {state?.messengers[index]?.type === 'Telegram' ||
                state.whatsappCheck && state?.messengers[index]?.type === 'WhatsApp' ?
                <TextInput
                    testName={`messengersLink${index}`}
                    testErrorName={`error-messengersLink${index}`}
                    lang={lang}
                    content={state?.messengers[index]?.type === 'Telegram' ? accountForm.telegramLink : accountForm.mobilePhoneNumber}
                    onChange={(e) => {
                        const telegram = e.replace(/[^A-Za-z0-9_]/gi, '')
                        dispatch({
                            type: actions.MESSENGERS,
                            data: index < state.messengers.length
                                ? state.messengers.map((item, i) => {
                                    return (index === i) ? {
                                        type: state.messengers[index]?.type,
                                        description: state.messengers[index]?.description,
                                        link: telegram,
                                    } : item
                                }) : state.messengers.concat({
                                    type: state.messengers[index]?.type,
                                    description: state.messengers[index]?.description,
                                    link: telegram
                                })
                        })
                    }}
                    value={
                        state.telegramCheck && state?.messengers[index]?.type === 'Telegram' ||
                            state.whatsappCheck && state?.messengers[index]?.type === 'WhatsApp' ?
                            state?.messengers[index]?.link :
                            `@${state?.messengers[index]?.link}`
                    }
                    valid={!invalidFields.includes(`messengersLink`)}
                    disabled={disabled}
                />
                :
                <PhoneInput
                    testName={`messengersLink${index}`}
                    testErrorName={`error-messengersLink${index}`}
                    label={accountForm.mobilePhoneNumber.label[lang]}
                    lang={lang}
                    numbers={
                        state.messengers.length === 0
                            ? ['']
                            : [state.messengers[index]?.link]
                    }
                    setNumbers={(e) => {
                        dispatch({
                            type: actions.MESSENGERS,
                            data: index < state.messengers.length
                                ? state.messengers.map((item, i) => {
                                    return (index === i) ? {
                                        type: state.messengers[index]?.type,
                                        description: state.messengers[index]?.description,
                                        link: e[0],
                                    } : item
                                }) : state.messengers.concat({
                                    type: state.messengers[index]?.type,
                                    description: state.messengers[index]?.description,
                                    link: e[0]
                                })
                        })
                    }}
                    invalidText={accountForm.mobilePhoneNumber.invalidText[lang]}
                    countries={countries}
                    required
                    firstlocked={state.Confirm_phone_ok}
                    valid={!invalidFields.includes(`messengersLink`)}
                    disabled={disabled}
                />}
        </div>
    </>
}
import Placeholder from 'react-select/src/components/Placeholder'
import AccountForm from '../types/account-form.types'

const accountForm: AccountForm = {
  stepPassportTitle: {
    eng: 'Passport data',
    rus: 'Паспортные данные',
    kz: 'Төлқұжат туралы мәліметтер',
  },
  stepContactTitle: {
    eng: 'Contact Information',
    rus: 'Контактная информация',
    kz: 'Байланыс ақпараты',
  },
  stepRentTitle: {
    eng: 'What do you want to rent',
    rus: 'Что хотите взять в аренду',
    kz: 'Нені жалға алғыңыз келеді',
  },
  stepFiscalTitleOne: {
    eng: 'General information',
    rus: 'Общие сведения',
    kz: 'Негізгі ақпарат',
  },
  stepFiscalTitleTwo: {
    eng: 'Payment details',
    rus: 'Платежные данные',
    kz: 'Төлем мәліметтері',
  },
  stepFiscalTitleThree: {
    eng: 'Contact Information',
    rus: 'Контактная информация',
    kz: 'Байланыс ақпараты',
  },
  stepFiscalTitleFour: {
    eng: 'What to rent',
    rus: 'Что взять в аренду',
    kz: 'Нені жалға беру керек',
  },
  stepOnePersonalTitle: {
    eng: 'Personal Information',
    rus: 'Персональные данные',
    kz: 'Жеке мәліметтер',
  },
  stepOneIdentification: {
    eng: 'Identification',
    rus: 'Удостоверение личности',
    kz: 'Жеке куәлік',
  },
  stepAddressRegistered: {
    eng: 'Registration address',
    rus: 'Адрес регистрации',
    kz: 'Тіркеу мекенжайы',
  },
  stepAddressFactual: {
    eng: 'Address of residence',
    rus: 'Адрес проживания',
    kz: 'Тұрғын үй мекен-жайы',
  },
  stepAddressRegisteredFiscal: {
    eng: 'Legal address',
    rus: 'Юридический адрес',
    kz: 'Заңды мекен-жайы',
  },
  stepAddressFactualFiscal: {
    eng: 'Actual address (postal)',
    rus: 'Фактический адрес (почтовый)',
    kz: 'Нақты мекенжайы (пошталық)',
  },
  stepAddressResidenceFiscal: {
    eng: 'Address of residence',
    rus: 'Адрес проживания',
    kz: 'Тұрғын үй мекен-жайы',
  },
  stepOneAdditional: {
    eng: 'Additional data',
    rus: 'Дополнительные данные',
    kz: 'Қосымша деректер',
  },
  stepTwoContactsTitle: {
    eng: 'Contacts',
    rus: 'Контакты',
    kz: 'Контактілер',
  },
  stepTwoSocialTitle: {
    eng: 'Social media',
    rus: 'Социальные сети',
    kz: 'Әлеуметтік желілер',
  },
  stepTwoMessengerTitle: {
    eng: 'Messengers',
    rus: 'Мессенджеры',
    kz: 'Мессенджеры',
  },
  stepTwoMessengerDescription: {
    rus: "Укажите хотя бы один мессенджер для связи:",
    eng: "Specify at least one messenger for communication:",
    kz: "Байланыс үшін кем дегенде бір мессенджерді көрсетіңіз:",
  },
  contactsMessengersTitle: {
    rus: "К номеру привязаны:",
    eng: "Linked to the number:",
    kz: "Нөмірге байланысты:",
  },
  stepTwoContactTitle: {
    eng: 'The contact person',
    rus: 'Контактное лицо',
    kz: 'Байланыстағы адам',
  },
  stepTwoWorkOrStudyTitle: {
    eng: 'Work and study',
    rus: 'Работа и учеба',
    kz: 'Жұмыс және оқу',
  },
  stepTwoRecomendation: {
    eng: 'Recommendations',
    rus: 'Рекомендации',
    kz: 'Ұсыныстар',
  },
  stepRecomendationWarning: {
    eng:
      'Take recommendations seriously, they can significantly improve collateral conditions',
    rus:
      'Отнеситесь к рекомендациям серьезно, они могут существенно улучшить залоговые условия',
    kz:
      'Ұсыныстарды байыппен қабылдаңыз, олар кепіл шарттарын айтарлықтай жақсарта алады',
  },
  stepThreeHeader: {
    eng: 'List of desired equipment',
    rus: 'Список желаемого оборудования',
    kz: 'Қажетті жабдықтың тізімі',
  },
  stepThreeHeaderDesc: {
    eng:
      'Please indicate which equipment you usually use or would like to rent, but no more than 10 items',
    rus:
      'Отметьте, пожалуйста, какое оборудование вы обычно используете или хотели бы взять в аренду, но не более 10 позиций',
    kz:
      'Әдетте қандай жабдықты пайдаланатыныңызды немесе жалға алғыңыз келетінін, бірақ 10 элементтен аспайтынын көрсетіңіз',
  },
  requiredDesc: {
    eng: '* required fields',
    rus: '* поля обязательные для заполнения',
    kz: '* міндетті түрде толтыратын алаң',
  },
  title: {
    eng: 'INQUIRY FORM for a Customer card',
    rus: 'Анкета на получение карты клиента',
    kz: 'Тұтынушы картасына өтініш',
  },
  logo: {
    eng: 'logo',
    rus: 'Логотип',
    kz: 'логотипі',
  },
  yes: {
    eng: 'yes',
    rus: 'да',
    kz: 'Иә',
  },
  no: {
    eng: 'no',
    rus: 'нет',
    kz: 'Жоқ',
  },
  stepsText: {
    eng: 'Step %step% of %steps%',
    rus: 'Шаг %step% из %steps%',
    kz: '%steps% қадамнан %step%-қадам',
  },
  stepsAriaLabel: {
    eng: 'Progress of filling out the questionnaire',
    rus: 'Прогресс заполнения анкеты',
    kz: 'Қолдану барысы',
  },
  cancelButton: {
    eng: 'Cancel',
    rus: 'Отмена',
    kz: 'Доғару',
  },
  prevButton: {
    eng: 'Previous',
    rus: 'Назад',
    kz: 'Артқа',
  },
  nextButton: {
    eng: 'Next',
    rus: 'Вперед',
    kz: 'Алға',
  },
  submitButton: {
    eng: 'Submit',
    rus: 'Отправить',
    kz: 'Жіберу',
  },
  addFieldButton: {
    eng: 'Add another one',
    rus: 'Добавить ещё',
    kz: 'Көбірек қосыңыз',
  },
  removeFieldButton: {
    eng: 'Remove',
    rus: 'Удалить',
    kz: 'Жою',
  },

  summaryReferencesLabel: {
    eng: 'How did you hear about us',
    rus: 'Откуда вы узнали о нас',
    kz: 'Біз туралы қалай естідіңіз',
  },
  referencesNoOptions: {
    eng: 'Enter your choice',
    rus: 'Введите свой вариант',
    kz: 'Таңдауыңызды енгізіңіз',
  },
  referencesCreateOption: {
    eng: 'Add',
    rus: 'Добавить',
    kz: 'қосу',
  },
  createOption: {
    eng: 'Add',
    rus: 'Добавить',
    kz: 'қосу',
  },
  productsInputText: {
    label: {
      eng: 'Equipment of interest*',
      rus: 'Интересующая техника*',
      kz: 'Қызығушылықты ояту техникасы*',
    },
    placeholder: {
      eng: 'Choose from the list or enter your choice',
      rus: 'Выберите из списка или введите свой вариант',
      kz: 'Тізімнен таңдаңыз немесе таңдауыңызды енгізіңіз',
    },
  },
  productsNoOptions: {
    eng: 'Enter your choice',
    rus: 'Введите свой вариант',
    kz: 'Таңдауыңызды енгізіңіз',
  },
  productsCreateOption: {
    eng: 'Add',
    rus: 'Добавить',
    kz: 'қосу',
  },
  rentStartedAt: {
    label: {
      eng: 'I want to take',
      rus: 'Хочу взять',
      kz: 'Мен алғым келеді',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  rentEndedAt: {
    label: {
      eng: 'When I bring',
      rus: 'Когда принесу',
      kz: 'Мен әкелгенде',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  additionalInfoTitle: {
    eng: 'Additional Information',
    rus: 'Дополнительная информация',
    kz: 'қосымша ақпарат',
  },
  additionalInfoNote: {
    eng:
      'Important: when specifying your social networks, it will be easier for us to approve a larger limit for you.',
    rus:
      'Важно: при указании ваших соцсетей, нам будет проще одобрить вам больший лимит.',
    kz:
      'Маңызды: әлеуметтік желілерді көрсету кезінде сізге үлкенірек шектеуді бекіту оңайырақ болады.',
  },
  socialLinksButton: {
    eng: 'Add social network',
    rus: 'Добавить социальную сеть',
    kz: 'Әлеуметтік желіні қосыңыз',
  },
  addBanksButton: {
    eng: 'Add another bank',
    rus: 'Добавить еще один банк',
    kz: 'Басқа банк қосыңыз',
  },
  autoFill: {
    eng: 'Fill',
    rus: 'Заполнить',
    kz: 'Толтыру',
  },
  organizationsAutoFill: {
    eng: "If there is an organization in the database by the specified INN, the remaining fields will be filled in automatically.",
    rus: "При наличии организации в базе данных по указанному ИНН, остальные поля будут заполнены автоматически.",
    kz: "Көрсетілген СТН бойынша дерекқорда ұйым болған жағдайда, қалған өрістер автоматты түрде толтырылады."
  },
  socialLinks: [
    {
      label: {
        eng: 'VK',
        rus: 'VK',
        kz: 'VK',
      },
      placeholder: {
        eng: 'https://vk.com/...',
        rus: 'https://vk.com/...',
        kz: 'https://vk.com/...',
      },
    },
    {
      label: {
        eng: 'Facebook',
        rus: 'Facebook',
        kz: 'Facebook',
      },
      placeholder: {
        eng: 'https://facebook.com/...',
        rus: 'https://facebook.com/...',
        kz: 'https://facebook.com/...',
      },
    },
    {
      label: {
        eng: 'Youtube',
        rus: 'Youtube',
        kz: 'Youtube',
      },
      placeholder: {
        eng: 'https://youtube.com/...',
        rus: 'https://youtube.com/...',
        kz: 'https://youtube.com/...',
      },
    },
    {
      label: {
        eng: 'Vimeo',
        rus: 'Vimeo',
        kz: 'Vimeo',
      },
      placeholder: {
        eng: 'https://vimeo.com/...',
        rus: 'https://vimeo.com/...',
        kz: 'https://vimeo.com/...',
      },
    },
    {
      label: {
        eng: 'Instagram',
        rus: 'Instagram',
        kz: 'Instagram',
      },
      placeholder: {
        eng: 'https://www.instagram.com/...',
        rus: 'https://www.instagram.com/...',
        kz: 'https://www.instagram.com/...',
      },
    },
    {
      label: {
        eng: 'Working site',
        rus: 'Рабочий сайт',
        kz: 'Жұмыс алаңы',
      },
      placeholder: {
        eng: 'https://...',
        rus: 'https://...',
        kz: 'https://...',
      },
    },
    {
      label: {
        eng: 'Personal site',
        rus: 'Персональный сайт',
        kz: 'Жеке сайт',
      },
      placeholder: {
        eng: 'https://...',
        rus: 'https://...',
        kz: 'https://...',
      },
    },
  ],
  additionalInfoExtraInput: {
    label: {
      eng: 'Other (here you can add links to "file storage")',
      rus: 'Другое (здесь можно добавить ссылки на "файловое хранилище")',
      kz: 'Басқа (мұнда «файл қоймасына» сілтемелер қосуға болады)',
    },
    placeholder: {
      eng: 'https://',
      rus: 'https://',
      kz: 'https://',
    },
  },
  additionalLinksDescription: {
    label: {
      eng: 'Әлеуметтік желі *',
      rus: 'Соц сеть *',
      kz: 'Social network *',
    },
    placeholder: {
      eng: 'Select',
      rus: 'Выбрать',
      kz: 'Таңдау',
    },
    invalidText: {
      eng: 'Enter a name',
      rus: 'Укажите название',
      kz: 'Атын енгізіңіз',
    },
  },
  messengerLinksDescription: {
    label: {
      eng: 'Messenger *',
      rus: 'Мессенджер *',
      kz: 'Мессенджер *',
    },
    placeholder: {
      eng: 'Select',
      rus: 'Выбрать',
      kz: 'Таңдау',
    },
    invalidText: {
      eng: 'Enter a name',
      rus: 'Укажите название',
      kz: 'Атын енгізіңіз',
    },
  },
  telegramCheck: {
    eng: "Telegram",
    rus: "Telegram",
    kz: "Telegram",
  },
  whatsappCheck: { 
    eng: "WhatsApp",
    rus: "WhatsApp",
    kz: "WhatsApp"
  },
  preferredCommunicationType: {
    label: {
      rus: "Удобный способ связи",
      eng: "A convenient way of communication", 
      kz: "Байланыстың ыңғайлы тәсілі" 
    },
    placeholder: {
      eng: 'Select',
      rus: 'Выбрать',
      kz: 'Таңдау',
    },
    invalidText: {
      eng: 'Enter a name',
      rus: 'Укажите название',
      kz: 'Атын енгізіңіз',
    },
  },
  preferredCommunicationTime: {
    label: {
      rus: "Удобное время для разговора", 
      eng: "A convenient time to talk", 
      kz: "Сөйлесуге ыңғайлы уақыт"
    },
    placeholder: {
      eng: 'Select',
      rus: 'Выбрать',
      kz: 'Таңдау',
    },
    invalidText: {
      eng: 'Enter a name',
      rus: 'Укажите название',
      kz: 'Атын енгізіңіз',
    },
  },
  additionalLinksOptions: [
    {
      eng: 'VK',
      rus: 'ВКонтакте',
      kz: 'VK',
    },
    {
      eng: 'Instagram',
      rus: 'Instagram',
      kz: 'Instagram',
    },
    {
      eng: 'WhatsApp',
      rus: 'WhatsApp',
      kz: 'WhatsApp',
    },
    {
      eng: 'YouTube',
      rus: 'YouTube',
      kz: 'YouTube',
    },
    {
      eng: 'OK',
      rus: 'Одноклассники',
      kz: 'OK',
    },
    {
      eng: 'Facebook',
      rus: 'Facebook',
      kz: 'Facebook',
    },
    {
      eng: 'Viber',
      rus: 'Viber',
      kz: 'Viber',
    },
    {
      eng: 'X (Twitter)',
      rus: 'X (Twitter)',
      kz: 'X (Twitter)',
    },
    {
      eng: 'Vimeo',
      rus: 'Vimeo',
      kz: 'Vimeo',
    },
    {
      eng: 'Skype',
      rus: 'Skype',
      kz: 'Skype',
    },
  ],
  firstName: {
    label: {
      eng: 'First name*',
      rus: 'Имя*',
      kz: 'Аты*',
    },
    placeholder: {
      eng: 'John',
      rus: 'Иван',
      kz: 'Паленше',
    },
    invalidText: {
      eng: 'Enter your name correctly',
      rus: 'Введите имя корректно',
      kz: 'Атыңызды дұрыс енгізіңіз',
    },
  },
  lastName: {
    label: {
      eng: 'Last name*',
      rus: 'Фамилия*',
      kz: 'Тегі*',
    },
    placeholder: {
      eng: 'Doe',
      rus: 'Иванов',
      kz: 'Паленшеев',
    },
    invalidText: {
      eng: 'Enter your last name correctly',
      rus: 'Введите фамилию корректно',
      kz: 'Фамилияңызды дұрыс енгізіңіз',
    },
  },
  patronymic: {
    label: {
      eng: 'Patronymic',
      rus: 'Отчество',
      kz: 'Әкесінің аты',
    },
    placeholder: {
      eng: 'John',
      rus: 'Иванович',
      kz: 'Паленшеулы',
    },
    invalidText: {
      eng: 'Enter your middle name correctly',
      rus: 'Введите отчество корректно',
      kz: 'Атаңыздың атын дұрыс енгізіңіз',
    },
  },
  latinFirstname: {
    label: {
      eng: 'First name in Latin*',
      rus: 'Имя латиницей*',
      kz: 'Латынша аты*',
    },
    placeholder: {
      eng: 'John',
      rus: 'Ivan',
      kz: 'Palenshe',
    },
    invalidText: {
      eng: 'Enter your name correctly in Latin',
      rus: 'Введите имя корректно на латинице',
      kz: 'Атыңызды латынша дұрыс енгізіңіз',
    },
  },
  latinLastname: {
    label: {
      eng: 'Last name in Latin*',
      rus: 'Фамилия латиницей*',
      kz: 'Латынша тегі*',
    },
    placeholder: {
      eng: 'Doe',
      rus: 'Ivanov',
      kz: 'Palensheev',
    },
    invalidText: {
      eng: 'Enter your last name correctly in Latin',
      rus: 'Введите фамилию корректно на латинице',
      kz: 'Фамилияңызды латынша дұрыс енгізіңіз',
    },
  },
  latinPatronymic: {
    label: {
      eng: 'Patronymic in latin letters',
      rus: 'Отчество латиницей',
      kz: 'Латынша әкесінің аты',
    },
    placeholder: {
      eng: 'John',
      rus: 'Ivanovich',
      kz: 'Palensheuly',
    },
    invalidText: {
      eng: 'Enter your middle name correctly in Latin',
      rus: 'Введите отчество корректно на латинице',
      kz: 'Әкесінің аты-жөніңізді латынша дұрыс енгізіңіз',
    },
  },
  noPatronymic: {
    eng: 'No patronymic',
    rus: 'Нет отчества',
    kz: 'Әкесінің аты жоқ',
  },
  occupationInstitution: {
    label: {
      eng: 'Place of work*',
      rus: 'Место работы*',
      kz: 'Жұмыс орны*',
    },
    placeholder: {
      eng: 'Enter your place of work',
      rus: 'Введите место работы',
      kz: 'Жұмыс орныңызды енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your place of work correctly',
      rus: 'Введите место работы корректно',
      kz: 'Жұмыс орныңызды дұрыс енгізіңіз',
    },
  },
  occupationPosition: {
    label: {
      eng: 'Job title*',
      rus: 'Должность*',
      kz: 'Қызмет атауы*',
    },
    placeholder: {
      eng: 'Enter position',
      rus: 'Введите должность',
      kz: 'Позицияны енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your position correctly',
      rus: 'Введите должность корректно',
      kz: 'Позицияңызды дұрыс енгізіңіз',
    },
  },
  workPhoneNumber: {
    label: {
      eng: 'Work phone number*',
      rus: 'Рабочий телефон*',
      kz: 'Жұмыс телефоны*',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
    invalidText: {
      eng: 'Enter your work phone number',
      rus: 'Укажите рабочий телефон',
      kz: 'Жұмыс телефоныңызды енгізіңіз',
    },
  },
  contactPersonPhone: {
    label: {
      eng: 'Contact number*',
      rus: 'Контактный телефон*',
      kz: 'Байланыс телефоны*',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
    invalidText: {
      eng: 'Enter contact phone number correctly',
      rus: 'Введите контактный телефон корректно',
      kz: 'Байланыс телефон нөміріңізді дұрыс енгізіңіз',
    },
  },
  monthlyIncomeInputText: {
    label: {
      eng: 'Monthly income',
      rus: 'Ежемесячный доход',
      kz: 'Айлық табыс',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  monthlyIncomeInputValues: [
    {
      eng: 'up to 30 000',
      rus: 'до 30 000',
      kz: '30000 дейін',
    },
    {
      eng: '31 000 - 70 000',
      rus: '31 000 - 70 000',
      kz: '31 000 - 70 000',
    },
    {
      eng: '71 000 - 100 000',
      rus: '71 000 - 100 000',
      kz: '71 000 - 100 000',
    },
    {
      eng: '101 000 - 150 000',
      rus: '101 000 - 150 000',
      kz: '101 000 - 150 000',
    },
    {
      eng: '151 000 - 250 000',
      rus: '151 000 - 250 000',
      kz: '151 000 - 250 000',
    },
    {
      eng: '251 000 - 350 000',
      rus: '251 000 - 350 000',
      kz: '251 000 - 350 000',
    },
    {
      eng: '351 000 - 1 000 000',
      rus: '351 000 - 1 000 000',
      kz: '351 000 - 1 000 000',
    },
    {
      eng: 'over 1 000 000',
      rus: 'более 1 000 000',
      kz: '1 000 000 астам',
    },
  ],
  isStudent: {
    label: {
      eng: 'I am a student',
      rus: 'Являюсь студентом/студенткой',
      kz: 'Мен студентпін',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  collegeName: {
    label: {
      eng: 'Place of study*',
      rus: 'Место учебы*',
      kz: 'Оқу орны*',
    },
    placeholder: {
      eng: 'Enter your place of study',
      rus: 'Введите место учебы',
      kz: 'Оқу орныңызды енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your place of study correctly',
      rus: 'Введите место учёбы корректно',
      kz: 'Оқу орныңызды дұрыс енгізіңіз',
    },
  },
  collegeProfession: {
    label: {
      eng: 'Speciality*',
      rus: 'Специальность*',
      kz: 'Мамандығы*',
    },
    placeholder: {
      eng: 'Enter specialty',
      rus: 'Введите специальность',
      kz: 'Мамандық енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your specialty correctly',
      rus: 'Введите специальность корректно',
      kz: 'Мамандығыңызды дұрыс енгізіңіз',
    },
  },
  collegeStartedAt: {
    label: {
      eng: 'Receipt date*',
      rus: 'Дата поступления*',
      kz: 'Қабылдау күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of receipt',
      rus: 'Укажите дату поступления',
      kz: 'Қабылдау күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  collegeEndedAt: {
    label: {
      eng: 'Expiration date*',
      rus: 'Дата окончания*',
      kz: 'Мерзімнің өту күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify an end date',
      rus: 'Укажите дату окончания',
      kz: 'Аяқталу күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  mobilePhoneNumber: {
    label: {
      eng: 'Mobile phone number*',
      rus: 'Мобильный телефон*',
      kz: 'Ұялы телефон*',
    },
    placeholder: {
      eng: 'Mobile phone number',
      rus: 'Мобильный телефон',
      kz: 'Ұялы телефон',
    },
    invalidText: {
      eng: 'Enter mobile phone',
      rus: 'Укажите мобильный телефон',
      kz: 'Ұялы телефонды енгізіңіз',
    },
  },
  telegramLink: {
    label: {
      eng: '@username',
      rus: '@username',
      kz: '@username',
    },
    placeholder: {
      eng: '@username',
      rus: '@username',
      kz: 'Ұялы телефон',
    },
    invalidText: {
      eng: 'Specify the link',
      rus: 'Укажите сслыку',
      kz: 'Сілтемені көрсетіңіз',
    },
  },
  mobileWarningMessage: {
    eng: 'A client with this number already exists',
    rus: 'Клиент с таким номером уже существует',
    kz: 'Мұндай нөмірі бар Клиент қазірдің өзінде бар',
  },
  emailWarningMessage: {
    eng: 'A client with such an email already exists',
    rus: 'Клиент с такой почтой уже существует',
    kz: 'Мұндай поштасы бар Клиент қазірдің өзінде бар',
  },
  additionalPhoneNumbers: {
    label: {
      eng: 'Additional phone number',
      rus: 'Дополнительный телефон',
      kz: 'Қосымша телефон',
    },
    placeholder: {
      eng: 'Additional phone number',
      rus: 'Дополнительный телефон',
      kz: 'Қосымша телефон',
    },
  },
  addressRegisteredPhone: {
    label: {
      eng: 'Telephone at the place of residence (landline)',
      rus: 'Телефон по месту прописки (городской)',
      kz: 'Тұрғылықты жері бойынша телефон (стационарлық)',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  addressFactualPhone: {
    label: {
      eng: 'Telephone at the place of residence (landline)',
      rus: 'Телефон по месту проживания (городской)',
      kz: 'Тұрғылықты жері бойынша телефон (стационарлық)',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  email: {
    label: {
      eng: 'E-mail*',
      rus: 'E-mail*',
      kz: 'E-mail*',
    },
    placeholder: {
      eng: 'example@example.com',
      rus: 'example@example.ru',
      kz: 'example@example.ru',
    },
    invalidText: {
      eng: 'Enter email correctly',
      rus: 'Введите электронную почту корректно',
      kz: 'Электрондық поштаңызды дұрыс енгізіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid email',
      rus: 'Некорректно указана электронная почта',
      kz: 'Жарамсыз электрондық пошта',
    },
  },
  agreementHeader: {
    eng: 'Customer profile',
    rus: 'Анкета клиента',
    kz: 'Клиент анкетасы',
  },
  agreementsDescription: {
    eng:
      'To become our client, fill out the client questionnaire and sign the Equipment Rental Agreement for professional commercial use',
    rus:
      'Чтобы стать нашим клиентом заполните анкету клиента и подпишите Договор аренды аппаратуры для профессионального коммерческого использования',
    kz:
      'Біздің клиент болу үшін клиент сауалнамасын толтырыңыз және кәсіби коммерциялық мақсатта жабдықты жалға беру келісіміне қол қойыңыз.',
  },
  agreementShowBtnTipText: {
    eng: '*Before you start, please read the',
    rus: '*Прежде чем начать, пожалуйста, ознакомьтесь с',
    kz: '*Бастамас бұрын оқып шығыңыз',
  },
  agreementShowBtnTip: {
    eng: 'personal data processing policy',
    rus: 'политикой обработки персональных данных',
    kz: 'жеке деректерді өңдеу саясаты',
  },
  agreementButtonConfurmLabel: {
    eng: 'I agree to the processing of personal data',
    rus: 'Я согласен на обработку персональных данных',
    kz: 'Мен жеке деректерді өңдеуге келісемін',
  },
  agreementButtonConfurmLabel1: {
    eng: 'I agree to the processing of',
    rus: 'Я согласен на обработку',
    kz: 'Мен жеке деректерді',
  },
  agreementButtonConfurmLabel2: {
    eng: 'personal data',
    rus: 'персональных данных',
    kz: 'өңдеуге келісемін',
  },
  agreementButtonConfurm: {
    eng: 'Confirm',
    rus: 'Подтверждаю',
    kz: 'растаймын',
  },
  gotoMainPage: {
    eng: 'Go back to the main page',
    rus: 'Вернуться на главную',
    kz: 'Басты бетке оралу',
  },
  citizenshipInput: {
    label: {
      eng: 'Citizenship*',
      rus: 'Гражданство*',
      kz: 'Азаматтық*',
    },
    noOptionsMessage: {
      eng: 'not found',
      rus: 'не найдено',
      kz: 'табылмады',
    },
    placeholder: {
      eng: '....select a country',
      rus: '...выберите страну',
      kz: '...Елді таңдаңыз',
    },
  },
  mainCity: {
    label: {
      eng: 'Core city *',
      rus: 'Основной город аренды*',
      kz: 'Негізгі қала *',
    },
    placeholder: {
      eng: 'select city',
      rus: 'выберите город',
      kz: 'қаланы таңдаңыз',
    },
    help: {
      eng:
        'Select the city where the rental service you are registering for is located',
      rus:
        'Выберите город, где находится сервис проката, в котором Вы оформляете учетную запись',
      kz: 'Сіз тіркелген жалға алу қызметі орналасқан қаланы таңдаңыз',
    },
  },
  mainCityValues: [
    {
      eng: 'Almaty',
      rus: 'Алматы',
      kz: 'Алматы',
    },
  ],
  sex: {
    label: {
      eng: 'Gender *',
      rus: 'Пол *',
      kz: 'Жынысы *',
    },
    placeholder: {
      eng: '...select gender',
      rus: '...выберите пол',
      kz: '... жынысты таңдаңыз',
    },
  },
  sexValues: [
    {
      eng: 'F',
      rus: 'Ж',
      kz: 'Әйел',
    },
    {
      eng: 'M',
      rus: 'М',
      kz: 'Еркек',
    },
  ],
  familyStatus: {
    label: {
      eng: 'Marital status',
      rus: 'Семейное положение',
      kz: 'Отбасы жағдайы',
    },
    placeholder: {
      eng: 'Не выбрано',
      rus: 'Не выбрано',
      kz: 'Не выбрано',
    },
    invalidText: {
      eng: 'Specify your marital status',
      rus: 'Укажите семейное положение',
      kz: 'Отбасылық жағдайды көрсетіңіз',
    },
  },
  passportIAt: {
    label: {
      eng: 'Date of issue*',
      rus: 'Дата выдачи*',
      kz: 'Берілген күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'дд.мм.гггг',
    },
    invalidText: {
      eng: 'Specify date of issue',
      rus: 'Укажите дату выдачи',
      kz: 'Шығарылған күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  passportExpiryDate: {
    label: {
      eng: 'Date of Expiry*',
      rus: 'Срок действия*',
      kz: 'Мерзімі аяқталған Күн*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify an expiration date',
      rus: 'Укажите дату истечения срока',
      kz: 'Жарамдылық мерзімін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  birthDate: {
    label: {
      eng: 'Date of birth*',
      rus: 'Дата рождения*',
      kz: 'Туылған күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'дд.мм.гггг',
    },
    invalidText: {
      eng: 'Enter date of birth',
      rus: 'Укажите дату рождения',
      kz: 'Туған күнін енгізіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  birthPlace: {
    label: {
      eng: 'Place of birth*',
      rus: 'Место рождения*',
      kz: 'Туылған жері*',
    },
    placeholder: {
      eng: 'Enter the name of the region and locality',
      rus: 'Введите наименование региона и населенного пункта',
      kz: 'Аймақтың және елді мекеннің атын енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your place of birth correctly',
      rus: 'Введите место рождения корректно',
      kz: 'Туған жеріңізді дұрыс енгізіңіз',
    },
  },
  passportSeries: {
    label: {
      eng: 'Series',
      rus: 'Серия',
      kz: 'Сериялар',
    },
    placeholder: {
      eng: '',
      rus: 'Введите серию документа',
      kz: 'Құжат сериясын енгізіңіз',
    },
    invalidText: {
      eng: 'Enter document series',
      rus: 'Введите серию документа',
      kz: 'Құжат сериясын енгізіңіз',
    },
  },
  passportNumber: {
    label: {
      eng: 'Series and number*',
      rus: 'Серия и номер*',
      kz: 'Серия және нөмір',
    },
    placeholder: {
      eng: 'хххх хххххх',
      rus: 'хххх хххххх',
      kz: 'хххх хххххх',
    },
    invalidText: {
      eng: 'Specify series and number',
      rus: 'Укажите серию и номер',
      kz: 'Серия мен нөмірді көрсетіңіз',
    },
  },
  passportPermanentResidencyNumber: {
    label: {
      eng: 'Number*',
      rus: 'Номер*',
      kz: 'Нөмір*',
    },
    placeholder: {
      eng: 'xxxxxxxxx',
      rus: 'xxxxxxxxx',
      kz: 'xxxxxxxxx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberGer: {
    label: {
      eng: 'Number*',
      rus: 'Номер*',
      kz: 'Нөмірі*',
    },
    placeholder: {
      eng: 'ххххххххx',
      rus: 'ххххххххx',
      kz: 'ххххххххx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberUkr: {
    label: {
      eng: 'Number*',
      rus: 'Номер*',
      kz: 'Нөмірі*',
    },
    placeholder: {
      eng: 'хх хххххх',
      rus: 'хх хххххх',
      kz: 'хх хххххх',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberKgz: {
    label: {
      eng: 'Number*',
      rus: 'Номер*',
      kz: 'Нөмірі*',
    },
    placeholder: {
      eng: 'хх ххххххx',
      rus: 'хх ххххххx',
      kz: 'хх ххххххx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberGbr: {
    label: {
      eng: 'Passport No*',
      rus: 'Номер паспорта*',
      kz: 'Паспорт нөмірі*',
    },
    placeholder: {
      eng: 'ххххххххx',
      rus: 'ххххххххx',
      kz: 'ххххххххx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberBel: {
    label: {
      eng: 'Series and number*',
      rus: 'Серия и номер*',
      kz: 'Серия және нөмір',
    },
    placeholder: {
      eng: 'хх ххххххx',
      rus: 'хх ххххххx',
      kz: 'хх ххххххx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberTur: {
    label: {
      eng: 'Number*',
      rus: 'Номер*',
      kz: 'Нөмірі*',
    },
    placeholder: {
      eng: 'х ххххххxx',
      rus: 'х ххххххxx',
      kz: 'х ххххххxx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberRom: {
    label: {
      eng: 'Number*',
      rus: 'Номер*',
      kz: 'Нөмірі*',
    },
    placeholder: {
      eng: 'хххххххх',
      rus: 'хххххххx',
      kz: 'хххххххx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  documentNumberSwe: {
    label: {
      eng: 'Number*',
      rus: 'Номер*',
      kz: 'Нөмірі*',
    },
    placeholder: {
      eng: 'хххххххх',
      rus: 'хххххххx',
      kz: 'хххххххx',
    },
    invalidText: {
      eng: 'Specify number',
      rus: 'Укажите номер',
      kz: 'Нөміріңізді енгізіңіз',
    },
  },
  passportInternationalNumber: {
    label: {
      eng: 'Series and number*',
      rus: 'Серия и номер*',
      kz: 'Сериясы мен нөмірі*',
    },
    placeholder: {
      eng: 'ххххххххх',
      rus: 'ххххххххх',
      kz: 'ххххххххх',
    },
    invalidText: {
      eng: 'Specify series and number',
      rus: 'Укажите серию и номер',
      kz: 'Серия мен нөмірді көрсетіңіз',
    },
  },
  passportInternationalNumberRus: {
    label: {
      eng: 'Series and number*',
      rus: 'Серия и номер*',
      kz: 'Сериясы мен нөмірі*',
    },
    placeholder: {
      eng: 'хх ххххххх',
      rus: 'хх ххххххх',
      kz: 'хх ххххххх',
    },
    invalidText: {
      eng: 'Specify series and number',
      rus: 'Укажите серию и номер',
      kz: 'Серия мен нөмірді көрсетіңіз',
    },
  },
  passportIsPermanentResidency: {
    eng: 'Resident card',
    rus: 'Вид на жительство',
    kz: 'Мекендеу қағазы',
  },
  passportDateIssuePermanentResidency: {
    label: {
      eng: 'Date of issue*',
      rus: 'Дата выдачи*',
      kz: 'Берілген күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of receipt',
      rus: 'Укажите дату получения',
      kz: 'Қабылдау күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  passportDateIssueRvp: {
    label: {
      eng: 'Date of issue*',
      rus: 'Дата выдачи*',
      kz: 'Берілген күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of receipt',
      rus: 'Укажите дату получения',
      kz: 'Қабылдау күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  passportDateBoardCrossing: {
    label: {
      eng: 'Border crossing date',
      rus: 'Дата пересечения границы',
      kz: 'Шекарадан өту күні',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the border crossing date',
      rus: 'Укажите дату дата пересечения границы',
      kz: 'Шекарадан өту күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  passportDateBoardCheck14: {
    label: {
      eng:
        'I confirm that the total time spent in the Republic of Kazakhstan is less than 14 days',
      rus: 'Подтверждаю, что общее время нахождения в РК менее 14 дней',
      kz:
        'Қазақстан Республикасында болған жалпы уақыт 14 күннен аз екенін растаймын',
    },
  },
  passportDateBoardCheck30: {
    label: {
      eng:
        'I confirm that the total time spent in the Republic of Kazakhstan is less than 30 days',
      rus: 'Подтверждаю, что общее время нахождения в РК менее 30 дней',
      kz:
        'Қазақстан Республикасында болған жалпы уақыт 30 күннен аз екенін растаймын',
    },
  },
  passportDateBoardCheck90: {
    label: {
      eng:
        'I confirm that the total time spent in the Republic of Kazakhstan is less than 90 days out of 180',
      rus: 'Подтверждаю, что общее время нахождения в РК менее 90 дней из 180',
      kz: 'ҚР-да болудың жалпы уақыты 180 күннің 90-нан аз екенін растаймын',
    },
  },
  passportDateBoardCheck90RF: {
    label: {
      eng:
        'I confirm that the total time spent in the Russian Federation is less than 90 days out of 180',
      rus: 'Подтверждаю, что общее время нахождения в РФ менее 90 дней из 180',
      kz: 'Ресей Федерациясында болудың жалпы уақыты 180 күннің 90-нан аз екенін растаймын',
    },
  },
  passportPersonNumber: {
    label: {
      eng: 'Personal number',
      rus: 'Персональный номер',
      kz: 'Жеке нөмір',
    },
    placeholder: {
      eng: 'Enter document number',
      rus: 'Введите номер документа',
      kz: 'Құжат нөмірін енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your personal number',
      rus: 'Укажите персональный номер',
      kz: 'Жеке нөміріңізді енгізіңіз',
    },
  },
  passportUdvNumber: {
    label: {
      eng: 'ID Number personality*',
      rus: 'Номер Уд. личности*',
      kz: 'Жеке куәлік нөмері*',
    },
    placeholder: {
      eng: 'ххххххххх',
      rus: 'ххххххххх',
      kz: 'ххххххххх',
    },
    invalidText: {
      eng: 'Enter your ID Number personality',
      rus: 'Укажите Номер Уд. личности',
      kz: 'Жеке ID нөмірі тұлға',
    },
  },
  passportIinNumber: {
    label: {
      eng: 'IIN',
      rus: 'ИИН',
      kz: 'ЖСН',
    },
    placeholder: {
      eng: 'xxxxxxxxxxxx',
      rus: 'xxxxxxxxxxxx',
      kz: 'xxxxxxxxxxxx',
    },
    invalidText: {
      eng: 'Enter your IIN',
      rus: 'Укажите ИИН',
      kz: 'Жеке ЖСН',
    },
  },
  PermanentResidencyNumber: {
    label: {
      eng: 'Series and number*',
      rus: 'Серия и номер*',
      kz: 'Сериясы мен нөмірі*',
    },
    placeholder: {
      eng: 'xx xxxxxxx',
      rus: 'xx xxxxxxx',
      kz: 'xx xxxxxxx',
    },
    invalidText: {
      eng: 'Specify series and number',
      rus: 'Укажите серию и номер',
      kz: 'Серия мен нөмірді көрсетіңіз',
    },
  },
  passportIinNumberRequired: {
    label: {
      eng: 'IIN*',
      rus: 'ИИН*',
      kz: 'ЖСН*',
    },
    placeholder: {
      eng: 'Enter document number',
      rus: 'Введите номер документа',
      kz: 'Құжат нөмірін енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your IIN',
      rus: 'Укажите ЖСН',
      kz: 'Жеке ЖСН',
    },
  },
  passportCardNumber: {
    label: {
      eng: 'ID card number',
      rus: 'Номер идентификационной карточки',
      kz: 'Жеке куәлік нөмірі',
    },
    placeholder: {
      eng: 'Enter document number',
      rus: 'Введите номер документа',
      kz: 'Құжат нөмірін енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your ID card number',
      rus: 'Укажите номер идентификационной карточки',
      kz: 'Жеке куәліктің нөмірін енгізіңіз',
    },
  },
  passportFirstNationality: {
    label: {
      eng: 'Nationality',
      rus: 'Национальность',
      kz: 'Ұлты',
    },
    placeholder: {
      eng: 'Nationality',
      rus: 'Национальность',
      kz: 'Ұлты',
    },
    invalidText: {
      eng: 'Specify nationality',
      rus: 'Укажите национальность',
      kz: 'Ұлтын көрсетіңіз',
    },
  },
  passportIssuedBy: {
    label: {
      eng: 'Authority*',
      rus: 'Кем выдан паспорт*',
      kz: 'Берген мекеме*',
    },
    placeholder: {
      eng: 'Enter the name of the authority that issued the document',
      rus: 'Введите наименование органа, выдавшего документ',
      kz: 'Құжатты шығарған органның атауын енгізіңіз',
    },
    invalidText: {
      eng: 'Specify who issued the passport',
      rus: 'Укажите кем выдан паспорт',
      kz: 'Паспортты кім бергенін көрсетіңіз',
    },
  },
  udvIssuedBy: {
    label: {
      eng: 'Who issued Ud. personality*',
      rus: 'Кем выдан Уд. личности*',
      kz: 'Ud шығарған. тұлға*',
    },
    placeholder: {
      eng: 'Enter the name of the authority that issued the document',
      rus: 'Введите наименование органа, выдавшего документ',
      kz: 'Құжатты шығарған органның атауын енгізіңіз',
    },
    invalidText: {
      eng: 'Specify who issued the passport',
      rus: 'Укажите кем выдан паспорт',
      kz: 'Паспортты кім бергенін көрсетіңіз',
    },
  },
  issuedBy: {
    label: {
      eng: 'Authority*',
      rus: 'Кем выдан*',
      kz: 'Берген мекеме*',
    },
    placeholder: {
      eng: 'Issuing authority',
      rus: 'Орган выдачи',
      kz: 'Берген мекеме',
    },
    invalidText: {
      eng: 'Specify who issued the document',
      rus: 'Укажите кем выдан документ',
      kz: 'Құжатты кім бергенін көрсетіңіз',
    },
  },
  passportAuthorityCode: {
    label: {
      eng: 'Department code*',
      rus: 'Код подразделения*',
      kz: 'Бөлім коды*',
    },
    placeholder: {
      eng: 'ххх-ххх',
      rus: 'ххх-ххх',
      kz: 'ххх-ххх',
    },
    invalidText: {
      eng: 'Specify department code',
      rus: 'Укажите код подразделения',
      kz: 'Бөлім кодын көрсетіңіз',
    },
  },
  passportCode: {
    label: {
      eng: 'Code*',
      rus: 'Код*',
      kz: 'Код*',
    },
    placeholder: {
      eng: 'ххх',
      rus: 'ххх',
      kz: 'ххх',
    },
    invalidText: {
      eng: 'Select code',
      rus: 'Выберите код',
      kz: 'Кодты таңдаңыз',
    },
  },
  passportTypeInput: {
    label: {
      eng: 'Document',
      rus: 'Документ*',
      kz: 'Құжат*',
    },
    placeholder: {
      eng: 'Select or enter your choice',
      rus: 'Выберите или введите свой вариант',
      kz: 'Таңдауыңызды таңдаңыз немесе енгізіңіз',
    },
    invalidText: {
      eng: 'Specify Document',
      rus: 'Укажите документ',
      kz: 'Құжатты көрсетіңіз',
    },
  },
  passportTypeValues: [
    {
      eng: 'Passport',
      rus: 'Паспорт',
      kz: 'Паспорт',
    },
  ],
  noOptionsMessage: {
    eng: 'not found',
    rus: 'не найдено',
    kz: 'табылмады',
  },
  addressRegisteredDate: {
    label: {
      eng: 'Registration date*',
      rus: 'Дата прописки*',
      kz: 'Тіркеу күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of registration',
      rus: 'Укажите дату прописки',
      kz: 'Тіркеу күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  addressRegisteredDateNoValidate: {
    label: {
      eng: 'Registration date',
      rus: 'Дата прописки',
      kz: 'Тіркеу күні',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of registration',
      rus: 'Укажите дату прописки',
      kz: 'Тіркеу күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  mainImageFile: {
    label: {
      eng: 'Passport scan (page with photo)*',
      rus: 'Скан паспорта (разворот с фотографией)*',
      kz: 'Паспортты сканерлеу (фотосуреті бар бет)*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload your passport photo (photo page)',
      rus: 'Загрузите фото паспорта (разворот с фотографией)',
      kz: 'Паспорттың фотосуретін жүктеңіз (фотопарақ)',
    },
  },
  addressImageFile: {
    label: {
      eng: 'Passport scan (page with registration)*',
      rus: 'Скан паспорта (разворот с регистрацией)*',
      kz: 'Паспортты сканерлеу (тіркеу бар бет)*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload your passport photo (page with registration',
      rus: 'Загрузите фото паспорта (разворот с регистрацией)',
      kz: 'Паспорт фотосуретін жүктеңіз (тіркеу бар бет)',
    },
  },
  udvFile: {
    label: {
      eng: 'Scan Identification*',
      rus: 'Скан Уд. личности*',
      kz: 'Жеке куәлік сканері*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  idFile: {
    label: {
      eng: 'ID card scan (photo)*',
      rus: 'Скан ID-карты (фотография)*',
      kz: 'Жеке куәлікті сканерлеу (фото)*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  residenceFileMainFiscal: {
    label: {
      eng: 'Scan of the document*',
      rus: 'Скан документа*',
      kz: 'Құжатты сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  udvFileMainFiscal: {
    label: {
      eng: 'Scan Od. hand-la personalities*',
      rus: 'Скан Уд. личности рук-ля (лицевая сторона)*',
      kz: 'Басшының жеке куәлігінің сканерленген көшірмесі (алдыңғы беті)*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  scanOfNotificationFirst: {
    label: {
      eng: 'Scan of 1st page of Notification*',
      rus: 'Скан первой страницы Уведомления*',
      kz: 'Хабарламаның бірінші бетін сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  scanOfNotificationSecond: {
    label: {
      eng: 'Scan of 2nd page of Notification*',
      rus: 'Скан второй страницы Уведомления*',
      kz: 'Хабарламаның екінші бетін сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  scanSoleRegistrationTicket: {
    label: {
      eng: 'Scan of 1st page of Ticket*',
      rus: 'Скан первой страницы Талона*',
      kz: 'Купонның бірінші бетін сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  scanSoleRegistrationTicketSecond: {
    label: {
      eng: 'Scan of 2nd page of Ticket*',
      rus: 'Скан второй страницы Талона*',
      kz: 'Купонның екінші бетін сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  udvFileMainAddressFiscal: {
    label: {
      eng: 'Scan Od. hand-la personalities (reverse side)*',
      rus: 'Скан Уд. личности рук-ля (обратная сторона)*',
      kz: 'Басшының жеке куәлігінің сканерленген көшірмесі (артқы беті)*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  residenceFileSecondFiscal: {
    label: {
      eng: 'Back side scan*',
      rus: 'Скан обратной стороны*',
      kz: 'Артқы жағын сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  internationalFile: {
    label: {
      eng: 'International passport scan (page with photo)*',
      rus: 'Скан загранпаспорта (разворот с фотографией)*',
      kz: 'Халықаралық төлқұжат (фотосуреті бар бет)*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload your international passport photo (page with photo)',
      rus: 'Загрузите фото загранпаспорта (разворот с фотографией)',
      kz: 'Халықаралық төлқұжат фотосуретін жүктеңіз (фотосуреті бар бет)',
    },
  },
  arrivalStampFile: {
    label: {
      eng: 'Scan of the page with a mark of arrival',
      rus: 'Cкан страницы с отметкой о прибытии',
      kz: 'Келу белгісі бар бетті сканерлеу',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload your international passport photo (page with arrival stamp)',
      rus: 'Загрузите фото загранпаспорта (разворот с отметкой о прибытии)',
      kz: 'Халықаралық төлқұжат фотосуретін жүктеңіз (келу мөрі бар бет)',
    },
  },
  visaImageFile: {
    label: {
      eng: 'Visa scan*',
      rus: 'Скан визы*',
      kz: 'Визаны сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload your visa scan',
      rus: 'Загрузите скан визы',
      kz: 'Виза сканерін жүктеп алыңыз',
    },
  },
  residenceFile: {
    label: {
      eng: 'Front side scan*',
      rus: 'Скан лицевой стороны*',
      kz: 'Алдыңғы жағы сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload your residence permit',
      rus: 'Загрузите скан ВНЖ',
      kz: 'Тұруға рұқсаттың сканерін жүктеңіз',
    },
  },
  residenceBacksideFile: {
    label: {
      eng: 'Back side scan*',
      rus: 'Скан обратной стороны*',
      kz: 'Артқы жағын сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload back side scan',
      rus: 'Загрузите обратной стороны',
      kz: 'Артқы жағын жүктеңіз',
    },
  },
  rvpFile: {
    label: {
      eng: 'Scan document*',
      rus: 'Cкан РВП*',
      kz: 'Құжатты сканерлеңіз*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo of the document',
      rus: 'Загрузитe фото документа',
      kz: 'Құжаттың фотосуретін жүктеңіз',
    },
  },
  vizaImageFile: {
    label: {
      eng: 'Scan Viza',
      rus: 'Скан VIZA *',
      kz: 'VISA сканерлеуі *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo of the document',
      rus: 'Загрузитe фото документа',
      kz: 'Құжаттың фотосуретін жүктеңіз',
    },
  },
  personImageFile: {
    label: {
      eng: 'Your photo',
      rus: 'Ваша фотография',
      kz: 'Сенің суретің',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo of the document',
      rus: 'Загрузитe фото документа',
      kz: 'Құжаттың фотосуретін жүктеңіз',
    },
  },
  additionalDocumentType: {
    label: {
      eng: 'Additional Document*',
      rus: 'Дополнительный документ*',
      kz: 'Қосымша құжат*',
    },
    placeholder: {
      eng: 'Select document type',
      rus: 'Выберите тип документа',
      kz: 'Құжат түрін таңдаңыз',
    },
    invalidText: {
      eng: 'Select document type',
      rus: 'Выберите тип документа',
      kz: 'Құжат түрін таңдаңыз',
    },
  },
  additionalDocumentTypeValues: [
    {
      eng: 'INN',
      rus: 'ИНН',
      kz: 'ҚАЛАЙЫ',
    },
    {
      eng: 'Military ID',
      rus: 'Военный билет',
      kz: 'Әскери билет',
    },
    {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
    {
      eng: 'International passport',
      rus: 'Загранпаспорт',
      kz: 'Халықаралық төлқұжат',
    },
    {
      eng: 'ID card',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
    {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
    {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  ],
  secondAdditionalDocumentType: {
    label: {
      eng: 'Second additional document',
      rus: 'Второй дополнительный документ',
      kz: 'Екінші қосымша құжат',
    },
    placeholder: {
      eng: 'Select document type',
      rus: 'Выберите тип документа',
      kz: 'Құжат түрін таңдаңыз',
    },
    invalidText: {
      eng: 'Select document type',
      rus: 'Выберите тип документа',
      kz: 'Құжат түрін таңдаңыз',
    },
  },
  secondAdditionalDocumentTypeNotRequired: {
    label: {
      eng: 'Second additional document',
      rus: 'Второй дополнительный документ',
      kz: 'Екінші қосымша құжат',
    },
    placeholder: {
      eng: 'Select document type',
      rus: 'Выберите тип документа',
      kz: 'Құжат түрін таңдаңыз',
    },
    invalidText: {
      eng: 'Select document type',
      rus: 'Выберите тип документа',
      kz: 'Құжат түрін таңдаңыз',
    },
  },
  additionalDocumentTypeValuesKz: [
    {
      eng: 'Passport',
      rus: 'Паспорт',
      kz: 'Төлқұжат',
    },
    {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
    {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
    {
      eng: 'Military ID',
      rus: 'Военный билет',
      kz: 'Әскери билет',
    },
    {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
    {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  ],
  additionalDocumentTypeValuesKzRf: [
    {
      eng: 'RVP',
      rus: 'РВП',
      kz: 'РВП',
    },
    {
      eng: 'Passport',
      rus: 'Паспорт',
      kz: 'Төлқұжат',
    },
    {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  ],
  DocumentTypeValuesKz: [
    {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  ],
  DocumentTypeValuesRfForKz: [
    {
      eng: 'Series and number',
      rus: 'Серия и номер',
      kz: 'Сериясы мен нөмірі',
    },
    {
      eng: 'Resident card',
      rus: 'Вид на жительство',
      kz: 'Мекендеу қағазы',
    },
    {
      eng: 'Passport',
      rus: 'Паспорт',
      kz: 'Паспорт',
    },
  ],
  DocumentTypeValuesRom: [
    {
      eng: 'Series and number',
      rus: 'Серия и номер',
      kz: 'Сериясы мен нөмірі',
    },
    {
      eng: 'Resident card',
      rus: 'Вид на жительство',
      kz: 'Мекендеу қағазы',
    },
    {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  ],
  DocumentTypeValuesUdvAbroad: [
    {
      eng: 'Series and number',
      rus: 'Серия и номер',
      kz: 'Сериясы мен нөмірі',
    },
    {
      eng: 'Resident card',
      rus: 'Вид на жительство',
      kz: 'Мекендеу қағазы',
    },
  ],
  DocumentTypeValuesTypePersonalities: [
    {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  ],
  DocumentTypeValuesTypePassport: [
    {
      eng: 'Passport',
      rus: 'Паспорт',
      kz: 'Паспорт',
    },
  ],
  documentsPhoto: {
    label: {
      eng: 'Document scan*',
      rus: 'Скан документа*',
      kz: 'Құжатты сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo of the document',
      rus: 'Загрузитe фото документа',
      kz: 'Құжаттың фотосуретін жүктеңіз',
    },
  },
  documentsPhotoNotRequired: {
    label: {
      eng: 'Document scan',
      rus: 'Скан документа',
      kz: 'Құжатты сканерлеу',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo of the document',
      rus: 'Загрузитe фото документа',
      kz: 'Құжаттың фотосуретін жүктеңіз',
    },
  },
  comments: {
    label: {
      eng: 'Your comments',
      rus: 'Ваши комментарии',
      kz: 'Пікірлеріңіз',
    },
    placeholder: {
      eng: 'Enter your comment',
      rus: 'Введите ваш комментарий',
      kz: 'Пікіріңізді енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your comment',
      rus: 'Укажите ваш комментарий',
      kz: 'Пікіріңізді енгізіңіз',
    },
  },
  countryResidencePermitNumber: {
    label: {
      eng: 'Please indicate country and number*',
      rus: 'Укажите страну и номер ВНЖ*',
      kz: 'Еліңіз бен шетелдіктің тұру ықтиярхатының номері*',
    },
    placeholder: {
      eng: 'Enter data',
      rus: 'Введите данные',
      kz: 'Деректерді енгізіңіз',
    },
    invalidText: {
      eng: 'Enter the country and number correctly',
      rus: 'Введите страну и номер корректно',
      kz: 'Ел мен нөмірді дұрыс енгізіңіз',
    },
  },
  countryResidencePermitDate: {
    label: {
      eng: 'Validity*',
      rus: 'Срок действия*',
      kz: 'Қолданылу мерзімі*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify an expiration date',
      rus: 'Укажите дату истечения срока',
      kz: 'Жарамдылық мерзімін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  jobOrStudy: {
    label: {
      eng: 'Employment *',
      rus: 'Занятость *',
      kz: 'Жұмыспен қамту *',
    },
    placeholder: {
      eng: 'Choose your activity',
      rus: 'Выберите ваш вид деятельности',
      kz: 'Өз әрекетіңізді таңдаңыз',
    },
    invalidText: {
      eng: 'Enter your comment',
      rus: 'Укажите ваш комментарий',
      kz: 'Пікіріңізді енгізіңіз',
    },
  },
  jobOrStudyValues: [
    {
      eng: 'I work and study',
      rus: 'Работаю и учусь',
      kz: 'Жұмыс және оқу',
    },
    {
      eng: "I'm working",
      rus: 'Работаю',
      kz: 'Жұмыс істеу',
    },
    {
      eng: "I'm studying",
      rus: 'Учусь',
      kz: 'Мен оқып жатырмын',
    },
  ],
  addressCountry: {
    label: {
      eng: 'Country*',
      rus: 'Страна*',
      kz: 'Мемлекет*',
    },
    noOptionsMessage: {
      eng: 'not found',
      rus: 'не найдено',
      kz: 'табылмады',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  addressRegion: {
    label: {
      eng: 'Region*',
      rus: 'Регион*',
      kz: 'Аймақ*',
    },
    placeholder: {
      eng: 'Choose region',
      rus: 'Введите регион',
      kz: 'Аймақты таңдаңыз',
    },
    invalidText: {
      eng: 'Enter the region correctly',
      rus: 'Введите регион корректно',
      kz: 'Аймақты дұрыс енгізіңіз',
    },
  },
  addressCity: {
    label: {
      eng: 'Locality*',
      rus: 'Город / Населенный пункт*',
      kz: 'Қала*',
    },
    placeholder: {
      eng: 'Enter your locality',
      rus: 'Введите населенный пункт',
      kz: 'Елді мекенді көрсетіңіз',
    },
    invalidText: {
      eng: 'Enter the locality correctly',
      rus: 'Введите город/населенный пункт корректно',
      kz: 'Қаланы дұрыс енгізіңіз',
    },
  },
  addressStreet: {
    label: {
      eng: 'Street*',
      rus: 'Улица*',
      kz: 'Көше*',
    },
    placeholder: {
      eng: 'Enter the street',
      rus: 'Введите улицу',
      kz: 'Көшені көрсетіңіз',
    },
    invalidText: {
      eng: 'Enter the street correctly',
      rus: 'Введите улицу корректно',
      kz: 'Көшеге дұрыс кіріңіз',
    },
  },
  addressRegisteredAddressInRow: {
    label: {
      eng: 'Enter the street correctly',
      rus: 'Адрес регистрации',
      kz: 'Тіркеу мекенжайы',
    },
    placeholder: {
      eng: 'Registration address',
      rus: 'Адрес регистрации',
      kz: 'Тіркеу мекенжайы',
    },
  },
  addressFactualAddressInRow: {
    label: {
      eng: 'Actual residential address',
      rus: 'Фактический адрес проживания',
      kz: 'Нақты тұрғылықты мекенжайы',
    },
    placeholder: {
      eng: 'Actual residential address',
      rus: 'Фактический адрес проживания',
      kz: 'Нақты тұрғылықты мекенжайы',
    },
  },
  addressBuilding: {
    label: {
      eng: 'House*',
      rus: 'Дом*',
      kz: 'Үй*',
    },
    placeholder: {
      eng: '0',
      rus: '0',
      kz: '0',
    },
    invalidText: {
      eng: 'Specify a house',
      rus: 'Укажите дом',
      kz: 'Үй нөмірін енгізіңіз',
    },
  },
  addressApartments: {
    label: {
      eng: 'Apartment*',
      rus: 'Квартира*',
      kz: 'Пәтер*',
    },
    placeholder: {
      eng: '0',
      rus: '0',
      kz: '0',
    },
    invalidText: {
      eng: 'Specify an apartment',
      rus: 'Укажите кв',
      kz: 'Пәтер нөмірін енгізіңіз',
    },
  },
  recommendationsName: {
    label: {
      eng: 'Full name',
      rus: 'ФИО',
      kz: 'Толық аты',
    },
    placeholder: {
      eng: 'Enter Last Name, First Name and Middle Name',
      rus: 'Введите Фамилию Имя и Отчество',
      kz: 'Тегі, аты және әкесінің атын енгізіңіз',
    },
  },
  recommendationsPhone: {
    label: {
      eng: 'Phone number',
      rus: 'Номер телефона',
      kz: 'Телефон нөмірі',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  subscribe: {
    label: {
      eng: 'Subscribe to newsletter',
      rus: 'Подписаться на рассылку',
      kz: 'Ақпараттық бюллетеньге жазылу',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  passportTitle: {
    eng: 'ID card',
    rus: 'Удостоверение личности',
    kz: 'Жеке куәлік',
  },
  addressRegisteredTitle: {
    eng: 'Registered address',
    rus: 'Адрес прописки',
    kz: 'Тұрғылықты жері',
  },
  addressFactualTitle: {
    eng: 'Residential address',
    rus: 'Адрес проживания',
    kz: 'Тұрғын үй мекен-жайы',
  },
  addressRegisteredCopyBtnDesc: {
    eng: 'Copy residential address',
    rus: 'Скопировать адрес проживания',
    kz: 'Тұрғылықты мекенжайды көшіріңіз',
  },
  addressFactualCopyBtnDesc: {
    eng: 'The address of registration and actual residence are the same',
    rus: 'Адрес регистрации и фактического проживания совпадают',
    kz: 'Тіркеу мекенжайы мен нақты тұрғылықты жері бірдей',
  },
  fiscalAddressFactualCopyBtnDesc: {
    eng: 'Same as legal address',
    rus: 'Совпадает с юридическим адресом',
    kz: 'Заңды мекенжаймен бірдей',
  },
  fiscalAddressFromFactualCopyBtnDesc: {
    eng: 'Same as factual address',
    rus: 'Совпадает с фактическим адресом',
    kz: 'Нақты мекен-жаймен сәйкес келеді',
  },
  fiscalContactsPersonCopyBtnDesc: {
    eng: 'Coincides with what is indicated in contacts',
    rus: 'Совпадает с указанным в контактах',
    kz: 'Контактілерде көрсетілгенмен сәйкес келеді',
  },
  fiscalContactsCopyBtnDesc: {
    eng: 'The contact person is the same as the current manager',
    rus: 'Контактное лицо совпадает с действующим руководителем',
    kz: 'Байланыс тұлғасы Қазіргі басшымен сәйкес келеді',
  },
  fiscalIpContactsCopyBtnDesc: {
    eng: 'The contact person is the same as the individual entrepreneur',
    rus: 'Контактное лицо совпадает с владельцем ИП',
    kz: 'Байланыстағы тұлға жеке кәсіпкермен бірдей',
  },
  noRentContractBtnDesc: {
    eng: 'No contract',
    rus: 'Нет договора',
    kz: 'Келісім-шарт жоқ',
  },
  noOffice: {
    eng: 'No office (premises)',
    rus: 'Нет офиса (помещения)',
    kz: 'Кеңсе жоқ (үй-жай)',
  },
  addressCopyButton: {
    eng: 'Copy',
    rus: 'Копировать',
    kz: 'Көшіру',
  },
  uploadPassportHeader: {
    eng: 'Copies of ID pages',
    rus: 'Копии страниц удостоверения личности',
    kz: 'Жеке куәлік беттерінің көшірмелері',
  },
  uploadPassportTextNonRussian: {
    eng:
      'Download copies of the pages of an identity document. We expect pages with a photo, address of residence, information about previously issued passports, if such pages are available.',
    rus:
      'Загрузите копии страниц документа, удостоверяющего личность. Мы ожидаем страницы с фотографией, адресом проживания, информацией о ранее выданных паспортах, если такие страницы имеются.',
    kz:
      'Жеке басты куәландыратын құжат беттерінің көшірмелерін жүктеп алыңыз. Фотосуреті, тұрғылықты жері мекенжайы, бұрын берілген төлқұжаттар туралы ақпарат бар беттерді күтеміз, егер мұндай беттер бар болса.',
  },
  uploadPassportTextRussian: {
    eng:
      'Download copies of the following pages:%li%Spread of 2-3 pages of passport (with photo)%li%Spread of pages with valid registration%li%Spread of pages with information about previously issued passports (18-19 pages), even if the pages are blank',
    rus:
      'Загрузите копии следующих страниц:%li%Разворот 2-3 страниц паспорта (с фотографией)%li%Разворот страниц с действующей регистрацией%li%Разворот страниц с информацией о ранее выданных паспортах (18-19 стр), даже если страницы пустые',
    kz:
      'Келесі беттердің көшірмелерін жүктеп алу:%li%Төлқұжаттың 2-3 бетінің таралуы (фотосуреті бар)%li%Жарамды тіркеуі бар беттердің таралуы%li%Бұрын берілген төлқұжаттар туралы ақпарат бар беттердің таралуы (18-19 бет), беттер бос болса да',
  },
  uploadDeleteButton: {
    eng: 'Delete all images',
    rus: 'Удалить все изображения',
    kz: 'Барлық кескіндерді жойыңыз',
  },
  uploading: {
    eng: 'Loading',
    rus: 'Загрузка',
    kz: 'Жүктеу',
  },
  uploadErrorId0: {
    eng: 'Images must be less than 10 megabytes.',
    rus: 'Изображения должны быть меньше 10 мегабайтов.',
    kz: 'Суреттер 10 мегабайттан аз болуы керек.',
  },
  uploadErrorId1: {
    eng: 'The images must be in the format .jpg, .png, .bmp, pdf or doc',
    rus: 'Изображения должны быть в формате .jpg, .png, .bmp, pdf или doc',
    kz: 'Суреттер форматта болуы керек .jpg, .png, .bmp, pdf немесе doc',
  },
  uploadErrorId2: {
    eng:
      'Internal server error. Please, try again later or send scans by email:',
    rus:
      'Ошибка сервера. Пожалуйста, попробуйте позже или отправьте файлы на email:',
    kz:
      'Сервер қатесі. Әрекетті кейінірек қайталаңыз немесе файлдарды электрондық пошта арқылы жіберіңіз:',
  },
  recommendationsLabel: {
    eng: 'Which of our clients can recommend you?',
    rus: 'Кто из наших клиентов может Ваc порекомендовать?',
    kz: 'Клиенттеріміздің қайсысы сізге ұсына алады?',
  },
  recommendations: {
    label: {
      eng: 'Which of our clients can recommend you?',
      rus: 'Кто из наших клиентов может Ваc порекомендовать?',
      kz: 'Клиенттеріміздің қайсысы сізге ұсына алады?',
    },
    placeholder: {
      eng: 'Enter your full name separated by commas',
      rus: 'Введите ФИО через запятую',
      kz: 'Толық аты-жөніңізді үтірмен бөліп енгізіңіз',
    },
  },
  uploadPortfolioHeader: {
    eng: 'Portfolio',
    rus: 'Портфолио',
    kz: 'Портфолио',
  },
  uploadPortfolioText: {
    eng:
      'You can attach examples of your work to the questionnaire if they cannot be viewed on the Internet.',
    rus:
      'Вы можете приложить к анкете примеры ваших работ, если их нельзя посмотреть в интернете.',
    kz:
      'Жұмысыңыздың мысалдарын Интернетте қарау мүмкін болмаса, сауалнамаға қоса аласыз.',
  },
  almostThereMessage: {
    eng:
      'Almost finished! It remains to check whether everything is correct and send the questionnaire.',
    rus:
      'Почти закончили! Осталось проверить, всё ли верно и отправить анкету.',
    kz:
      'Аяқтауға жақын! Барлығының дұрыстығын тексеру және сауалнаманы жіберу қалады.',
  },
  checkAndSubmitBtn: {
    eng: 'Send',
    rus: 'Отправить',
    kz: 'Жіберу',
  },
  summaryGoToButton: {
    eng: 'Return',
    rus: 'Вернуться',
    kz: 'Қайту',
  },
  submitLoading: {
    eng: 'The survey is being sent, please wait...',
    rus: 'Анкета отправляется, подождите...',
    kz: 'Сауалнама жіберілуде, күте тұрыңыз...',
  },
  validationError: {
    eng:
      'Not all required fields are filled in',
    rus:
      'Не все обязательные поля заполнены',
    kz:
      'Барлық міндетті өрістер толтырылмайды',
  },
  validationErrorClientExist: {
    eng:
      'The entered data is already in the database, please contact our managers.',
    rus:
      'Введенные данные уже есть в базе, обратитесь к менеджерам',
    kz:
      'Енгізілген деректер базада бар, менеджерлерге хабарласыңыз',
  },
  validationNoImagesError: {
    eng: 'Must upload images',
    rus: 'Нужно обязательно загрузить изображения',
    kz: 'Суреттерді жүктеп салу керек',
  },
  summaryNoImagesMessage: {
    eng: 'No images uploaded',
    rus: 'Нет загруженных изображений',
    kz: 'Жүктеп салынған суреттер жоқ',
  },
  submitSucess: {
    eng:
      'Your Client Questionnaire has been received and is already under consideration!\nIt will take 1 business day to complete and review your Questionnaire after confirming the data, if the information provided is sufficient to make a decision on the coverage limit and it is reliable.\nIMPORTANT: if the data in the questionnaire is not enough to accept decisions about the coverage limit - we will contact you to clarify the information. In this case, consideration of the questionnaire may take up to 4 days.\n\nWe will definitely send you information on the limit to your email address!',
    rus:
      'Ваша Анкета клиента получена и уже находится на рассмотрении!\nОформление и рассмотрение Вашей Анкеты после подтверждения данных займет 1 рабочий день, если предоставленной информации достаточно для принятия решения о лимите покрытия и она является достоверной.\nВАЖНО: если данных в анкете недостаточно для принятия решения о лимите покрытия – мы свяжемся с Вами для уточнения информации. В этом случае рассмотрение анкеты может занять до 4-х суток.\n\nМы обязательно вышлем Вам информацию по лимиту на Ваш электронный адрес!',
    kz:
      ' клиентінің сауалнамасы алынды және қазірдің өзінде қарастырылуда!\nЕгер берілген ақпарат қамту шегі туралы шешім қабылдау үшін жеткілікті болса және ол сенімді болса, деректерді растағаннан кейін Сауалнамаңызды толтыру және қарау 1 жұмыс күнін алады. \nМАҢЫЗДЫ: егер сауалнамадағы деректер қамту шегі туралы шешім қабылдау үшін жеткіліксіз болса - ақпаратты нақтылау үшін сізбен хабарласамыз. Бұл жағдайда сауалнаманы қарау 4 күнге дейін созылуы мүмкін.\n\nШектеу туралы ақпаратты міндетті түрде электрондық пошта мекенжайыңызға жібереміз!',
  },
  submitSucessHeader: {
    eng: 'Questionnaire sent successfully',
    rus: 'Анкета успешно отправлена',
    kz: 'Сауалнама сәтті жіберілді',
  },
  submitError: {
    eng: 'Something went wrong. Please refresh the page and resubmit the form.',
    rus:
      'Что-то пошло не так. Обновите пожалуйста страницу, и повторите отправку формы.',
    kz: 'Бірдеңе дұрыс болмады. Бетті жаңартып, пішінді қайта жіберіңіз.',
  },
  refillFormButton: {
    eng: 'Fill out another form',
    rus: 'Заполнить ещё одну анкету',
    kz: 'Басқа пішінді толтырыңыз',
  },
  contactsMessengersTitle: {
    rus: "К номеру привязаны:",
    eng: "Linked to the number:",
    kz: "Нөмірге байланысты:",
  },
  contactsMessengersDescription: {
    rus: "Укажите хотя бы один мессенджер для связи:",
    eng: "Specify at least one messenger for communication:",
    kz: "Байланыс үшін кем дегенде бір мессенджерді көрсетіңіз:",
  },
  messengersTitle: {
    rus: "Мессeнджеры",
    eng: "Messengers",
    kz: "Мессeнджеры",
  },
  workWebsite: {
    label: {
      eng: 'Working site',
      rus: 'Рабочий сайт',
      kz: 'Жұмыс алаңы',
    },
    placeholder: {
      eng: 'https://...',
      rus: 'https://...',
      kz: 'https://...',
    },
  },
  instagram: {
    label: {
      eng: 'Instagram',
      rus: 'Instagram',
      kz: 'Instagram',
    },
    placeholder: {
      eng: 'https://www.instagram.com/...',
      rus: 'https://www.instagram.com/...',
      kz: 'https://www.instagram.com/...',
    },
  },
  vimeo: {
    label: {
      eng: 'Vimeo',
      rus: 'Vimeo',
      kz: 'Vimeo',
    },
    placeholder: {
      eng: 'https://vimeo.com/...',
      rus: 'https://vimeo.com/...',
      kz: 'https://vimeo.com/...',
    },
  },
  youtube: {
    label: {
      eng: 'Youtube',
      rus: 'Youtube',
      kz: 'Youtube',
    },
    placeholder: {
      eng: 'https://youtube.com/...',
      rus: 'https://youtube.com/...',
      kz: 'https://youtube.com/...',
    },
  },
  additionalLinks: {
    label: {
      eng: 'Other (here you can add links to "file storage")',
      rus: 'Другое (здесь можно добавить ссылки на "файловое хранилище")',
      kz: 'Басқа (мұнда «файл қоймасына» сілтемелер қосуға болады)',
    },
    placeholder: {
      eng: 'https://',
      rus: 'https://',
      kz: 'https://',
    },
  },
  personalWebsite: {
    label: {
      eng: 'Web site',
      rus: 'Веб-сайт',
      kz: 'Веб сайт',
    },
    placeholder: {
      eng: 'https://www.example.com',
      rus: 'https://www.example.com',
      kz: 'https://www.example.com',
    },
    invalidText: {
      eng: 'Enter the website link correctly',
      rus: 'Введите ссылку веб-сайта корректно',
      kz: 'Веб-сайт сілтемесін дұрыс енгізіңіз',
    },
  },
  socialLink: {
    label: {
      eng: 'Link / Account*',
      rus: 'Ссылка / Аккаунт*',
      kz: 'Сілтеме / Тіркелгі*',
    },
    placeholder: {
      eng: 'https://www.vk.com/example',
      rus: 'https://www.vk.com/example',
      kz: 'https://www.vk.com/example',
    },
    invalidText: {
      eng: 'Enter the link or account correctly',
      rus: 'Введите ссылку или аккаунт корректно',
      kz: 'Сілтемені немесе тіркелгіні дұрыс енгізіңіз',
    },
  },
  vk: {
    label: {
      eng: 'VK',
      rus: 'VK',
      kz: 'VK',
    },
    placeholder: {
      eng: 'https://vk.com/...',
      rus: 'https://vk.com/...',
      kz: 'https://vk.com/...',
    },
  },
  summaryPersonalDataTitle: {
    eng: 'Personal data',
    rus: 'Личные данные',
    kz: 'Жеке деректер',
  },
  facebook: {
    label: {
      eng: 'Facebook',
      rus: 'Facebook',
      kz: 'Facebook',
    },
    placeholder: {
      eng: 'https://facebook.com/...',
      rus: 'https://facebook.com/...',
      kz: 'https://facebook.com/...',
    },
  },
  telegram: {
    label: {
      eng: 'Telegram',
      rus: 'Телеграм',
      kz: 'Telegram',
    },
    placeholder: {
      eng: '@example',
      rus: '@example',
      kz: '@example',
    },
    invalidText: {
      eng: 'Enter your login correctly',
      rus: 'Введите логин корректно',
      kz: 'Логиніңізді дұрыс енгізіңіз',
    },
  },
  otherProducts: {
    eng: 'Other / Not in the list',
    rus: 'Другое / Нет в списке',
    kz: 'Басқа / Тізімде жоқ',
  },
  feedback: {
    label: {
      eng: 'Comment',
      rus: 'Комментарий',
      kz: 'Пікір',
    },
    placeholder: {
      eng: 'Enter a comment',
      rus: 'Введите комментарий',
      kz: 'Пікір енгізіңіз',
    },
  },
  closeButton: {
    eng: 'close',
    rus: 'Закрыть',
    kz: 'жабық',
  },
  goBackButton: {
    eng: 'Back',
    rus: 'Назад',
    kz: 'Артқа',
  },
  acceptButton: {
    eng: 'Accept',
    rus: 'Принять',
    kz: 'Қабылдау',
  },
  resetStateButton: {
    eng: 'Accept',
    rus: 'Сбросить всё',
    kz: 'Барлығын қалпына келтіру',
  },
  resetStateWarning: {
    eng:
      'This action will delete all the information you entered. If you really want to do this, click the button again.',
    rus:
      'Это действие удалит всю введённую вами информацию. Если вы действительно хотите это сделать, нажмите на кнопку ещё раз.',
    kz:
      'Бұл әрекет сіз енгізген барлық ақпаратты жояды. Егер мұны шынымен жасағыңыз келсе, түймені қайтадан басыңыз.',
  },
  selectYear: {
    eng: 'Select year',
    rus: 'Выберите год',
    kz: 'Жылды таңдаңыз',
  },
  selectMonth: {
    eng: 'Select month',
    rus: 'Выберите месяц',
    kz: 'Айды таңдаңыз',
  },
  selectDay: {
    eng: 'Select day',
    rus: 'Выберите день',
    kz: 'Күнді таңдаңыз',
  },
  profileType_PhysicalButton: {
    eng: 'Individual',
    rus: 'Физическое лицо',
    kz: 'Жеке тұлға',
  },
  profileType_FiscalButton: {
    eng: 'Legal entity',
    rus: 'Юридическое лицо',
    kz: 'Заңды тұлға',
  },
  profileType_FiscalIPButton: {
    eng: 'Individual entrepreneur',
    rus: 'Индивидуальный предприниматель',
    kz: 'Жеке кәсіпкер',
  },
  F_IIN: {
    label: {
      eng: 'IIN*',
      rus: 'ИИН*',
      kz: 'ЖСН*',
    },
    placeholder: {
      eng: 'хххххххххх',
      rus: 'хххххххххх',
      kz: 'хххххххххх',
    },
    invalidText: {
      eng: 'Enter IIN correctly',
      rus: 'Введите ИИН корректно',
      kz: 'ЖСН дұрыс енгізіңіз',
    },
  },
  F_INN: {
    label: {
      eng: 'TIN*',
      rus: 'ИНН*',
      kz: 'ҚАЛАЙЫ*',
    },
    placeholder: {
      eng: 'хххххххххх',
      rus: 'хххххххххх',
      kz: 'хххххххххх',
    },
  },
  F_KBe: {
    label: {
      eng: 'KBE',
      rus: 'КБ',
      kz: 'КБе',
    },
    placeholder: {
      eng: 'хх',
      rus: 'хх',
      kz: 'хх',
    },
    invalidText: {
      eng: 'Enter KBE correctly',
      rus: 'Введите КБ корректно',
      kz: 'КБ дұрыс енгізіңіз',
    },
  },
  F_KPP: {
    label: {
      eng: 'KPP *',
      rus: 'КПП *',
      kz: 'KPP *',
    },
    placeholder: {
      eng: 'ххххххххх',
      rus: 'ххххххххх',
      kz: 'ххххххххх',
    },
  },
  countryRegistration: {
    label: {
      eng: 'Company registration country*',
      rus: 'Страна регистрации компании*',
      kz: 'Компанияның тіркелген елі*',
    },
    noOptionsMessage: {
      eng: 'not found',
      rus: 'не найдено',
      kz: 'табылмады',
    },
    placeholder: {
      eng: '',
      rus: '',
      kz: '',
    },
  },
  F_FiscalType: {
    label: {
      eng: 'Type of ownership*',
      rus: 'Правовая форма*',
      kz: 'Меншік түрі*',
    },
    placeholder: {
      eng: 'Individual entrepreneur/Legal entity',
      rus: 'ИП/Юр. лицо *',
      kz: 'жеке кәсіпкер/заңды тұлға*',
    },
  },
  soleTraderType: {
    label: {
      eng: 'Select the type of individual entrepreneur*',
      rus: 'Выберите тип ИП*',
      kz: 'Жеке кәсіпкердің түрін таңдаңыз*',
    },
    placeholder: {
      eng: 'Select the type',
      rus: 'Выберите тип ИП',
      kz: 'Жеке кәсіпкердің түрін таңдаңыз',
    },
  },
  F_FiscalTypeValues: [
    {
      eng: 'Individual Entrepreneur (IP)',
      rus: 'Индивидуальный предприниматель (ИП)',
      kz: 'Жеке кәсіпкер (IP)',
    },
    {
      eng: 'Limited Liability Company (LLC)',
      rus: 'Общество с ограниченной ответственностью (ООО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
    {
      eng: 'Joint Stock Company (JSC)',
      rus: 'Акционерное общество (АО)',
      kz: 'Акционерлік қоғам (АҚ)',
    },
    {
      eng: 'Public Joint Stock Company (PJSC)',
      rus: 'Публичное акционерное общество (ПАО)',
      kz: 'Қоғамдық акционерлік қоғам (ЖАҚ)',
    },
    {
      eng: 'Closed Joint Stock Company (CJSC)',
      rus: 'Закрытое акционерное общество (ЗАО)',
      kz: 'Жабық акционерлік қоғам (ЖАҚ)',
    },
  ],
  F_FiscalTypeValuesKz: [
    {
      eng: 'Individual Entrepreneur (IP)',
      rus: 'Индивидуальный предприниматель (ИП)',
      kz: 'Жеке кәсіпкер (IP)',
    },
    {
      eng: 'Limited Liability Partnership (LLP)',
      rus: 'Товарищество с ограниченной ответственностью (ТОО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
  ],
  F_FiscalTypeValuesRom: [
    {
      eng: 'Individual Entrepreneur (IP)',
      rus: 'Индивидуальный предприниматель (ИП)',
      kz: 'Жеке кәсіпкер (IP)',
    },
    {
      eng: 'Limited Liability Partnership (LLP)',
      rus: 'Товарищество с ограниченной ответственностью (ООО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
  ],
  F_FiscalTypeValuesKgz: [
    {
      eng: 'Individual Entrepreneur (IP)',
      rus: 'Индивидуальный предприниматель (ИП)',
      kz: 'Жеке кәсіпкер (IP)',
    },
    {
      eng: 'Limited Liability Company (LLC)',
      rus: 'Общество с ограниченной ответственностью (ОсОО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
  ],
  F_FullName: {
    label: {
      eng: 'Full name*',
      rus: 'Полное наименование*',
      kz: 'Толық аты*',
    },
    placeholder: {
      eng: 'OOO «London industrial company»',
      rus: 'ООО «Московская промышленная компания»',
      kz: 'ООО «Мәскеу өнеркәсіптік компаниясы»',
    },
  },
  noFullName: {
    eng: 'No name',
    rus: 'Нет наименования',
    kz: 'Есімі жоқ',
  },
  F_ShortName: {
    label: {
      eng: 'Name abbreviated*',
      rus: 'Сокращенное наименование*',
      kz: 'Аты қысқартылған*',
    },
    placeholder: {
      eng: 'ООО «MPK»',
      rus: 'ООО «МПК»',
      kz: 'ООО «МПК»',
    },
  },
  vatCertificate: {
    label: {
      eng: 'Certificate about VAT registration',
      rus: 'Свид. о постановке по НДС (серия и номер)',
      kz: 'Сертификат ҚҚС бойынша тіркеу туралы',
    },
    placeholder: {
      eng: 'xxxxxxxxxxxx',
      rus: 'xxxxxxxxxxxx',
      kz: 'xxxxxxxxxxxx',
    },
    invalidText: {
      eng: 'Enter the series and number correctly',
      rus: 'Введите серию и номер корректно',
      kz: 'Серия мен нөмірді дұрыс енгізіңіз',
    },
  },
  vatCertificateDate: {
    label: {
      eng: 'VAT registration date',
      rus: 'Дата регистрации НДС',
      kz: 'ҚҚС тіркеу күні',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify VAT registration date',
      rus: 'Укажите дату регистрации НДС',
      kz: 'Қайта ҚҚС тіркеу күні',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  reregistrationCertificate: {
    label: {
      eng: 'Certificate about Mrs. overreg. legal faces (series and number)',
      rus: 'Свид. о гос. перерег. юр. лица (серия и номер)',
      kz:
        'Сертификат ханым туралы артық реттеу. заңды беттер (серия және нөмір)',
    },
    placeholder: {
      eng: 'хххх хххххх',
      rus: 'хххх хххххх',
      kz: 'хххх хххххх',
    },
  },
  reregisteredDate: {
    label: {
      eng: 'Re-registration date',
      rus: 'Дата перерегистрации',
      kz: 'Қайта тіркеу күні',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of re-registration',
      rus: 'Укажите дату перерегистрации',
      kz: 'Қайта тіркеу күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  certificateRegistrationNumber: {
    label: {
      eng: 'Certificate number',
      rus: 'Рег. номер свидетельства',
      kz: 'СРег. сертификат нөмірі',
    },
    placeholder: {
      eng: 'Enter number',
      rus: 'Введите номер',
      kz: 'Нөмірді енгізіңіз',
    },
  },
  registrationTicket: {
    label: {
      eng: 'Ticket number* ',
      rus: 'Номер Талона*',
      kz: 'Талон нөмірі* ',
    },
    placeholder: {
      eng: 'Enter number',
      rus: 'Введите номер',
      kz: 'Нөмірді енгізіңіз',
    },
    invalidText: {
      eng: 'Enter the Ticket Number correctly',
      rus: 'Введите Номер Талона корректно',
      kz: 'Талон нөмірін дұрыс енгізіңіз',
    },
  },
  registrationNotice: {
    label: {
      eng: 'Incoming reg. Notification №.*',
      rus: 'Входящий рег. № Уведомления*',
      kz: 'Кіріс рег. Хабарлама №*',
    },
    placeholder: {
      eng: 'Enter number',
      rus: 'Введите номер',
      kz: 'Нөмірді енгізіңіз',
    },
    invalidText: {
      eng: 'Enter the number correctly',
      rus: 'Введите номер корректно',
      kz: 'Нөмірді дұрыс енгізіңіз',
    },
  },
  receptionNoticeDate: {
    label: {
      eng: 'Date of notification*',
      rus: 'Дата подачи Уведомления*',
      kz: 'Хабарландыру күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of receipt of notification',
      rus: 'Укажите дату приёма уведомления',
      kz: 'Хабарламаны алған күнін көрсету',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  registrationNoticeDate: {
    label: {
      eng: 'Date of receipt of notification*',
      rus: 'Дата приёма Уведомления*',
      kz: 'Хабарламаны алу күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of receipt of notification',
      rus: 'Укажите дату приёма уведомления',
      kz: 'Хабарламаны алған күнін көрсету',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  oKED: {
    label: {
      eng: 'OKED',
      rus: 'ОКЭД',
      kz: 'ОКЭД',
    },
    placeholder: {
      eng: 'xxxxx',
      rus: 'xxxxx',
      kz: 'xxxxx',
    },
    invalidText: {
      eng: 'Enter OKED correctly',
      rus: 'Введите ОКЭД корректно',
      kz: 'OKED дұрыс енгізіңіз',
    },
  },
  F_OGRN: {
    label: {
      eng: 'OGRN *',
      rus: 'ОГРН *',
      kz: 'ОГРН *',
    },
    placeholder: {
      eng: 'ххххххххххххх',
      rus: 'ххххххххххххх',
      kz: 'ххххххххххххх',
    },
  },
  F_OGRNIP: {
    label: {
      eng: 'OGRNIP*',
      rus: 'ОГРНИП*',
      kz: 'ОГРНИП*',
    },
    placeholder: {
      eng: 'OGRNIP *',
      rus: 'ОГРНИП *',
      kz: 'OGRNIP *',
    },
  },
  oKPO: {
    label: {
      eng: 'OKPO',
      rus: 'ОКПО',
      kz: 'ОКПО',
    },
    placeholder: {
      eng: 'xxxxxxxxxx',
      rus: 'xxxxxxxxxx',
      kz: 'xxxxxxxxxx',
    },
    invalidText: {
      eng: 'Enter OKPO Code correctly',
      rus: 'Введите Код ОКПО корректно',
      kz: 'OKPO кодын дұрыс енгізіңіз',
    },
  },
  F_OKATO: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'OKATO *',
      rus: 'ОКАТО *',
      kz: 'OKATO *',
    },
  },
  F_OKOPF: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'OKOPF *',
      rus: 'ОКОПФ *',
      kz: 'OKOPF *',
    },
  },
  F_addressRegistered: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Legal address *',
      rus: 'Юридический адрес *',
      kz: 'Заңды мекен-жайы',
    },
  },
  F_addressFactual: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Factual address',
      rus: 'Фактический адрес *',
      kz: 'Нақты мекен-жайы *',
    },
  },
  F_BankRS: {
    label: {
      eng: 'Checking account *',
      rus: 'Расчетный счет *',
      kz: 'Есептік жазбаны тексеру *',
    },
    placeholder: {
      eng: 'хххххххххххххххххххх',
      rus: 'хххххххххххххххххххх',
      kz: 'хххххххххххххххххххх',
    },
  },
  F_BankRS_KZT: {
    label: {
      eng: 'IIC / IBAN*',
      rus: 'ИИК / IBAN*',
      kz: 'IIC/IBAN*',
    },
    placeholder: {
      eng: 'хххххххххххххххххххх',
      rus: 'хххххххххххххххххххх',
      kz: 'хххххххххххххххххххх',
    },
    invalidText: {
      eng: 'Enter IIC / IBAN correctly',
      rus: 'Введите ИИК / IBAN корректно',
      kz: 'IIC/IBAN кодын дұрыс енгізіңіз',
    },
  },
  F_BankBIK: {
    label: {
      eng: 'Bank code',
      rus: 'БИК *',
      kz: 'BIK *',
    },
    placeholder: {
      eng: 'ххххххххх',
      rus: 'ххххххххх',
      kz: 'ххххххххх',
    },
  },
  F_BankBIK_KZT: {
    label: {
      eng: 'BIC / SWIFT code*',
      rus: 'БИК / SWIFT код*',
      kz: 'BIC / SWIFT коды*',
    },
    placeholder: {
      eng: 'ххххххххх',
      rus: 'ххххххххх',
      kz: 'ххххххххх',
    },
    invalidText: {
      eng: 'Enter the BIC / SWIFT code correctly',
      rus: 'Введите БИК / SWIFT код корректно',
      kz: 'BIC / SWIFT кодын дұрыс енгізіңіз',
    },
  },
  F_BankName: {
    label: {
      eng: 'Bank branch name*',
      rus: 'Название филиала банка*',
      kz: 'Банк филиалының атауы*',
    },
    placeholder: {
      eng: 'OOO «Moscow industrial company»',
      rus: 'ООО «Московская промышленная компания»',
      kz: 'ООО «Мәскеу өнеркәсіптік компаниясы»',
    },
  },
  F_BankName_KZT: {
    label: {
      eng: 'Name of the bank*',
      rus: 'Наименование банка*',
      kz: 'Банктің атауы*',
    },
    placeholder: {
      eng: 'АО "Kaspi Bank"',
      rus: 'АО "Kaspi Bank"',
      kz: 'АО "Kaspi Bank"',
    },
    invalidText: {
      eng: 'Enter the bank name correctly',
      rus: 'Введите наименование банка корректно',
      kz: 'Банк атауын дұрыс енгізіңіз',
    },
  },
  F_BankKS: {
    label: {
      eng: 'Correspondent account *',
      rus: 'Корреспондентский счёт *',
      kz: 'Корреспонденттік шот *',
    },
    placeholder: {
      eng: 'хххххххххххххххххххх',
      rus: 'хххххххххххххххххххх',
      kz: 'хххххххххххххххххххх',
    },
  },
  F_BankRNN: {
    label: {
      eng: 'RNN*',
      rus: 'РНН*',
      kz: 'РНН*',
    },
    placeholder: {
      eng: 'хххххххххххххххххххх',
      rus: 'хххххххххххххххххххх',
      kz: 'хххххххххххххххххххх',
    },
  },
  F_BankBIN: {
    label: {
      eng: 'BIN*',
      rus: 'БИН*',
      kz: 'БИН*',
    },
    placeholder: {
      eng: 'xxxxxxxxxxxx',
      rus: 'xxxxxxxxxxxx',
      kz: 'xxxxxxxxxxxx',
    },
    invalidText: {
      eng: 'Enter your BIN correctly',
      rus: 'Введите БИН корректно',
      kz: 'БСН-ді дұрыс енгізіңіз',
    },
  },
  F_BankKBe: {
    label: {
      eng: 'KBE*',
      rus: 'Кбе*',
      kz: 'KBE*',
    },
    placeholder: {
      eng: 'хх',
      rus: 'хх',
      kz: 'хх',
    },
  },
  F_Bank_Email: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Email *',
      rus: 'Электронная почта *',
      kz: 'Электрондық пошта *',
    },
  },
  Bank_AutoFill: {
    eng: 'Automatic filling of the name of the bank branch by BIC',
    rus: 'Автоматическое заполнение названия филиала банка по БИК',
    kz: 'БСК бойынша банк филиалының атауын автоматты түрде толтыру',
  },
  F_TaxType: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Form of taxation',
      rus: 'Форма налогообложения',
      kz: 'Салық салу нысаны',
    },
  },
  F_TaxTypeValues: [
    {
      eng: 'ОSNО *',
      rus: 'ОСНО *',
      kz: 'ОСНО *',
    },
    {
      eng: 'USN *',
      rus: 'УСН *',
      kz: 'УСН *',
    },
    {
      eng: 'ESHN',
      rus: 'ЕСХН *',
      kz: 'ЕСХН *',
    },
    {
      eng: 'Patent *',
      rus: 'Патент *',
      kz: 'Патент *',
    },
  ],
  F_Website: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Site/social',
      rus: 'Сайт',
      kz: 'Веб-сайт',
    },
  },
  F_Contact: {
    label: {
      eng: 'Position*',
      rus: 'Должность*',
      kz: 'Лауазымы*',
    },
    placeholder: {
      eng: 'Enter your position',
      rus: 'Введите должность',
      kz: 'Өз позицияңызды енгізіңіз',
    },
    invalidText: {
      eng: 'Enter your position correct',
      rus: 'Введите должность корректно',
      kz: 'Лауазымды дұрыс енгізіңіз',
    },
  },
  purposeOfLease: {
    label: {
      eng: 'Purpose of equipment rental*',
      rus: 'Цель аренды оборудования*',
      kz: 'Жабдықты жалға алу мақсаты*',
    },
    placeholder: {
      eng: 'Specify for what tasks you need to rent equipment',
      rus: 'Укажите, для каких задач вам необходимо арендовать оборудование',
      kz: 'Қандай тапсырмалар үшін жабдықты жалға алу керектігін көрсетіңіз',
    },
    invalidText: {
      eng: 'Enter the rental purpose correctly',
      rus: 'Введите цель аренды корректно',
      kz: 'Жалдау мақсатын дұрыс енгізіңіз',
    },
  },
  F_WorkPhoneNumber: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Phone',
      rus: 'Телефон *',
      kz: 'Телефон*',
    },
  },
  F_ContactPhoneNumber: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Phone number of the contact person *',
      rus: 'Телефон контактного лица *',
      kz: 'Байланыстағы тұлғаның телефон нөмірі *',
    },
  },
  F_Contact_email: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Email of the contact person *',
      rus: 'Email контактного лица *',
      kz: 'Байланыс тұлғасының электрондық поштасы *',
    },
  },
  F_Email: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'E-mail',
      rus: 'E-mail',
      kz: 'E-mail',
    },
    invalidText: {
      eng: 'Enter E-mail',
      rus: 'Укажите эллектроную почту',
      kz: 'Электрондық поштаңызды енгізіңіз',
    },
  },

  F_ContactsProxie: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Contacts of the responsible person',
      rus: 'Контакты ответственного лица',
      kz: 'Жауапты тұлғаның байланыстары',
    },
  },
  F_OGRN_Image: {
    label: {
      eng: 'Scan OGRN*',
      rus: 'Скан ОГРН*',
      kz: 'OGRN сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_OGRNIP_Image: {
    label: {
      eng: 'Scan OGRNIP *',
      rus: 'Скан ОГРНИП *',
      kz: 'OGRNIP сканерлеу *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_INN_Image: {
    label: {
      eng: 'Scan TIN*',
      rus: 'Скан ИНН*',
      kz: 'СТН сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_IP_INN_Image: {
    label: {
      eng:
        'Scanned copy of the certificate of tax registration - TIN of an individual *',
      rus:
        'Сканкопию свидетельства о постановке на налоговый учет- ИНН физического лица *',
      kz:
        'Салық есебіне қою туралы куәліктің сканерленген көшірмесі – жеке тұлғаның СТН*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_Charter_Image: {
    label: {
      eng: 'The charter of the legal faces *',
      rus: 'Устав юр. лица *',
      kz: 'Заңды тұлғаның жарғысы *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_Protocol_Image: {
    label: {
      eng:
        'Minutes of the meeting of founders or decision to establish a company *',
      rus: 'Протокол собрания учредителей или решение о создании общества *',
      kz:
        'Құрылтайшылар жиналысының немесе серіктестік құру туралы шешімнің хаттамасы *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_EGRUL_Image: {
    label: {
      eng: 'Scan of an extract from the USRLE not older than 3 months *',
      rus: 'Скан выписки из ЕГРЮЛ не старше 3х месяцев *',
      kz: '3 айға дейінгі БМЗТБ үзінді *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_EGRIP_Image: {
    label: {
      eng: 'Scanned extract from USRIP not older than 3 months *',
      rus: 'Скан выписки из ЕГРИП не старше 3х месяцев *',
      kz: 'USRIP-тен 3 айдан аспайтын сканерленген үзінді *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_Order_Image: {
    label: {
      eng: 'Order on the appointment of the General Director *',
      rus: 'Приказ о назначении генерального директора *',
      kz: 'Бас директорды тағайындау туралы бұйрық *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_Passport_Director_Image: {
    label: {
      eng:
        'Scanned copy of the original passport of the General Director with the registration page *',
      rus:
        'Сканкопия оригинала паспорта генерального директора со страницей прописки *',
      kz:
        'Тіркеу парағы бар бас директор төлқұжатының сканерленген көшірмесі *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_IP_Passport_Director_Image: {
    label: {
      eng:
        'Scanned copy of the original passport of the General Director with the registration page *',
      rus:
        'Сканкопия оригинала паспорта генерального директора со страницей прописки *',
      kz:
        'Тіркеу парағы бар бас директор төлқұжатының сканерленген көшірмесі *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_Rent_Contract_Image: {
    label: {
      eng: 'Scan of the lease agreement for the premises (office)*',
      rus: 'Скан договора аренды помещения (офиса)*',
      kz: 'Үй-жайды (офисті) жалдау шартының сканері*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_IP_Rent_Contract_Image: {
    label: {
      eng: 'Premises lease agreement (rental of office or production space) *',
      rus:
        'Договор аренды помещения (аренда офиса или производственных площадей) *',
      kz: 'Үй-жайды жалдау шарты (кеңсе немесе өндірістік алаңды жалға алу) *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  },
  F_Directors_Email: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Email',
      rus: 'Электронная почта',
      kz: 'Электрондық пошта',
    },
  },
  F_Directors_Phone: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Phone',
      rus: 'Телефон',
      kz: 'Телефон',
    },
  },
  F_Directors_FullName: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'CEO *',
      rus: 'Генеральный директор *',
      kz: 'БАС АТҚАРУШЫ ДИРЕКТОР *',
    },
  },
  headFirstname: {
    label: {
      eng: "Manager's name*",
      rus: 'Имя руководителя*',
      kz: 'Менеджердің аты*',
    },
    placeholder: {
      eng: 'John',
      rus: 'Иван',
      kz: 'Паленше',
    },
    invalidText: {
      eng: 'Enter name correctly',
      rus: 'Введите имя корректно',
      kz: 'Атыңызды дұрыс енгізіңіз',
    },
  },
  headLastname: {
    label: {
      eng: 'Surname of the head*',
      rus: 'Фамилия руководителя*',
      kz: 'Басшының тегі*',
    },
    placeholder: {
      eng: 'Doe',
      rus: 'Васильев',
      kz: 'Паленшеев',
    },
    invalidText: {
      eng: 'Enter last name correctly',
      rus: 'Введите фамилию корректно',
      kz: 'Фамилияңызды дұрыс енгізіңіз',
    },
  },
  headPatronymic: {
    label: {
      eng: 'Middle name of the head*',
      rus: 'Отчество руководителя*',
      kz: 'Басшының әкесінің аты*',
    },
    placeholder: {
      eng: 'John',
      rus: 'Сергеевич',
      kz: 'Паленшеулы',
    },
    invalidText: {
      eng: 'Enter patronymic correctly',
      rus: 'Введите отчество корректно',
      kz: 'Атаңыздың атын дұрыс енгізіңіз',
    },
  },
  Fiscal_Warning_Genitive: {
    eng:
      'When drawing up a contract, a rule is established for writing a full name in the genitive case.',
    rus:
      'При оформлении договора установлено правило написания ФИО в родительном падеже.',
    kz:
      'Шарт жасау кезінде тектік жағдайда толық аты-жөнін жазу ережесі белгіленеді.',
  },
  Fiscal_Warning_Genitive_Example: {
    eng: 'For example: Doe John John',
    rus: 'Например: Васильева Ивана Сергеевича',
    kz: 'Мысалы: Паленшеева Паленше Паленшеулы',
  },
  Fiscal_Warning_Scan_Directors_Passport: {
    eng:
      'Uploading passport scans can significantly improve bail conditions, take it seriously',
    rus:
      'Загрузка сканов паспорта может существенно улучшить залоговые условия, отнеситесь к этому серьезно',
    kz:
      'Төлқұжат сканерлеуін жүктеп салу кепіл шарттарын айтарлықтай жақсарта алады, оны байыппен қабылдаңыз',
  },
  Fiscal_Warning_Scan_Directors_Passport_KZT: {
    eng:
      'Uploading scans of documents can significantly improve bail conditions, take it seriously',
    rus:
      'Загрузка сканов документов может существенно улучшить залоговые условия, отнеситесь к этому серьезно',
    kz:
      'Құжаттардың сканерлеуді жүктеп салу кепіл шарттарын жақсартуы мүмкін, оны байыппен қабылдаңыз',
  },
  headFirstnameGenitive: {
    label: {
      eng: "Manager's name*",
      rus: 'Имя (в родит. пад.)*',
      kz: 'Менеджердің аты (Ілік септік)*',
    },
    placeholder: {
      eng: 'John',
      rus: 'Ивана',
      kz: 'Паленше',
    },
    invalidText: {
      eng: 'Enter name correctly',
      rus: 'Введите имя корректно',
      kz: 'Атыңызды дұрыс енгізіңіз',
    },
  },
  headLastnameGenitive: {
    label: {
      eng: 'Surname of the head*',
      rus: 'Фамилия (в родит. пад.)*',
      kz: 'Басшының тегі (Ілік септік)*',
    },
    placeholder: {
      eng: 'Doe',
      rus: 'Васильева',
      kz: 'Паленшеева',
    },
    invalidText: {
      eng: 'Enter last name correctly',
      rus: 'Введите фамилию корректно',
      kz: 'Фамилияңызды дұрыс енгізіңіз',
    },
  },
  headPatronymicGenitive: {
    label: {
      eng: 'Middle name of the head*',
      rus: 'Отчество (в родит. пад.)*',
      kz: 'Бастың әкесінің аты*',
    },
    placeholder: {
      eng: 'John',
      rus: 'Сергеевича',
      kz: 'Паленшеулы',
    },
    invalidText: {
      eng: 'Enter patronymic correctly',
      rus: 'Введите отчество корректно',
      kz: 'Атаңыздың атын дұрыс енгізіңіз',
    },
  },
  F_Scan_Directors_Passport: {
    label: {
      eng: "Scan of the manager's passport *",
      rus: 'Скан паспорта руководителя (разворот с фото)*',
      kz: 'Менеджердің төлқұжатының сканері *',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  F_Scan_Directors_Passport_Address: {
    label: {
      eng: "Scan of the manager's passport *",
      rus: 'Скан паспорта руководителя (разворот с рег.)*',
      kz: 'Менеджердің төлқұжатын сканерлеу *',
    },
    placeholder: {
      eng: 'Select or drag file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  F_RegisteredDocumentScan: {
    label: {
      eng: 'Scan of registration document*',
      rus: 'Скан документа регистрации*',
      kz: 'Тіркеу құжатын сканерлеу*',
    },
    placeholder: {
      eng: 'Select or drag file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  registeredDate: {
    label: {
      eng: 'Date of registration*',
      rus: 'Дата регистрации*',
      kz: 'Тіркеу күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'ГГГГ.ДД.ММ',
    },
    invalidText: {
      eng: 'Specify the date of registration',
      rus: 'Укажите дату регистрации',
      kz: 'Тіркеу күнін көрсетіңіз',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  F_Office_Number: {
    label: {
      eng: 'Office*',
      rus: 'Офис*',
      kz: 'Кеңсе*',
    },
    placeholder: {
      eng: '0',
      rus: '0',
      kz: '0',
    },
    invalidText: {
      eng: 'Enter the office',
      rus: 'Укажите офис',
      kz: 'Кеңсені көрсетіңіз',
    },
  },
  addressFactualPostCode: {
    label: {
      eng: 'Index*',
      rus: 'Индекс*',
      kz: 'Индекс*',
    },
    placeholder: {
      eng: 'хххххх',
      rus: 'хххххх',
      kz: 'хххххх',
    },
    invalidText: {
      eng: 'Enter the index correctly',
      rus: 'Введите индекс корректно',
      kz: 'Индексті дұрыс енгізіңіз',
    },
  },
  F_Adress_Index: {
    label: {
      eng: 'Index*',
      rus: 'Индекс*',
      kz: 'Индекс*',
    },
    placeholder: {
      eng: 'хххххх',
      rus: 'хххххх',
      kz: 'хххххх',
    },
    invalidText: {
      eng: 'Enter the index correctly',
      rus: 'Введите индекс корректно',
      kz: 'Индексті дұрыс енгізіңіз',
    },
  },
  headPosition: {
    label: {
      eng: 'Head position*',
      rus: 'Должность руководителя*',
      kz: 'Бас позициясы*',
    },
    placeholder: {
      eng: 'Enter the position of the head',
      rus: 'Введите должность руководителя',
      kz: 'Бастың орнын енгізіңіз',
    },
    invalidText: {
      eng: "Enter the manager's position correctly",
      rus: 'Введите должность руководителя корректно',
      kz: 'Менеджердің орнын дұрыс енгізіңіз',
    },
  },
  headPositionGenitive: {
    label: {
      eng: 'Head position*',
      rus: 'Должность руководителя (в родит. пад.)*',
      kz: 'Бас позициясы (Ілік септік)*',
    },
    placeholder: {
      eng: 'Enter the position of the head',
      rus: 'Введите должность руководителя',
      kz: 'Бастың орнын енгізіңіз',
    },
    invalidText: {
      eng: "Enter the manager's position correctly",
      rus: 'Введите должность руководителя корректно',
      kz: 'Менеджердің орнын дұрыс енгізіңіз',
    },
  },
  foundingDocument: {
    label: {
      eng: 'What does the leader act on?',
      rus: 'На основании чего действует руководитель*',
      kz: 'Көшбасшы не істейді?',
    },
    placeholder: {
      eng: 'Choose',
      rus: 'Выбрать',
      kz: 'Таңдау',
    },
  },
  foundingDocument_values: [
    {
      eng: 'Charter',
      rus: 'Устав',
      kz: 'Жарғы',
    },
    {
      eng: 'Proxy',
      rus: 'Доверенность',
      kz: 'Сенімхат',
    },
  ],
  foundingDocument_Image: {
    label: {
      eng: 'Document confirmed. powers of the head*',
      rus: 'Документ, подтв. полномочия руководителя*',
      kz: 'Құжат расталды. бастың өкілеттігі*',
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
    invalidText: {
      eng: 'Upload a photo',
      rus: 'Загрузите фото',
      kz: 'фотосуретін жүктеңіз',
    },
  },
  headProxyNumber: {
    label: {
      eng: 'Proxy number*',
      rus: 'Номер доверенности*',
      kz: 'Сенімхат нөмірі',
    },
    placeholder: {
      eng: 'хххххххххх',
      rus: 'хххххххххх',
      kz: 'хххххххххх',
    },
    invalidText: {
      eng: 'Specify from 1 to 10 characters',
      rus: 'Укажите от 1 до 10 символов',
      kz: '1 ден 10 таңбаға дейін көрсетіңіз',
    },
  },
  headProxyStart: {
    label: {
      eng: 'Date of proxy issue*',
      rus: 'Дата выдачи доверенности*',
      kz: 'Сенімхаттың берілген күні*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'дд.мм.гггг',
    },
    invalidText: {
      eng: 'Enter date of proxy issue',
      rus: 'Укажите дату выдачи доверенности',
      kz: 'Сенімхат берілген күнді көрсетіңіз*',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  headProxyEnd: {
    label: {
      eng: 'Expiration date of proxy*',
      rus: 'Срок окончания доверенности*',
      kz: 'Сенімхаттың қолданылу мерзімі*',
    },
    placeholder: {
      eng: 'dd.mm.yyyy',
      rus: 'дд.мм.гггг',
      kz: 'дд.мм.гггг',
    },
    invalidText: {
      eng: 'Enter expiration date of proxy',
      rus: 'Укажите срок окончания доверенности',
      kz: 'Сенімхаттың аяқталу мерзімін көрсетіңіз*',
    },
    invalidTextCorrect: {
      eng: 'Invalid date',
      rus: 'Некорректная дата',
      kz: 'Күні дұрыс емес',
    },
  },
  F_Ip_FullName: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Individual entrepreneur *',
      rus: 'Индивидуальный предприниматель *',
      kz: 'Жеке кәсіпкер *',
    },
  },
  F_Booker: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Chief Accountant *',
      rus: 'Главный бухгалтер *',
      kz: 'Бас бухгалтер *',
    },
  },
  F_Proxies_Phone: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'Phone number',
      rus: 'Номер телефона',
      kz: 'Телефон нөмірі',
    },
  },
  F_Proxies_FullName: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'FULL NAME *',
      rus: 'ФИО *',
      kz: 'ТОЛЫҚ АТЫ *',
    },
  },
  F_Proxies_Passport_ImageFile: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng:
        'Scanned copies of the original passport of the Trustee with the registration page *',
      rus:
        'Сканкопии оригинала паспорта Доверенного лица со страницей прописки *',
      kz:
        'Тіркеу парағы бар Сенімгер тұлға төлқұжатының сканерленген көшірмелері *',
    },
  },
  F_Proxies_Order_ImageFile: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng:
        'Document confirming the legal relationship of the Tenant with the Trustee *',
      rus:
        'Документ, подтверждающий правоотношения Арендатора с Доверенным лицом *',
      kz:
        'Жалға алушының сенімгерлік басқарушымен құқықтық қатынасын растайтын құжат *',
    },
  },
  uploadF_DocHeader: {
    eng:
      'Please upload copies of the statutory documents and indicate the contacts of the responsible persons.',
    rus:
      'Пожалуйста, загрузите копии уставных документов и укажите контакты ответственных лиц.',
    kz:
      'Жарғылық құжаттардың көшірмелерін жүктеп, жауапты тұлғалардың байланыстарын көрсетіңіз.',
  },
  uploadF_DocText: {
    eng:
      "Please note that you can upload only those documents that you have or go straight to the next step, but when checking the company's data in the Security Service, the lack of documents may adversely affect the results of the check and increase the time that will be required in the future for approval of the agreement%li%Copy of the certificate of state registration %li%Copy of the tax registration certificate%li%Copy of the charter%li%Copy of the decision of the sole founder or Minutes of the general meeting %li%Power of attorney%li%Extract from the Unified State Register of Legal Entities %li%Copy passport of the general director% li% Certificate of application of the simplified tax system (in the case of applying the simplified tax system)",
    rus:
      'Обратите внимание, что вы можете загрузить только те документы, которые у вас есть, или сразу перейти к следующему шагу, но при проверке данных компании в Службе безопасности отсутствие документов может негативно сказаться на результатах проверки и увеличит срок, который потребуется в дальнейшем для согласования договора%li%Копия свидетельства о государственной регистрации %li%Копия свидетельства о постановке на учет в налоговой%li%Копия устава%li%Копия Решения единственного учредителя или Протокола общего собрания %li%Доверенность%li%Выписка из ЕГРЮЛ %li%Копия паспорта генерального директора%li%Свидетельство о применении УСН (в случае применения УСН)',
    kz:
      'Сізде бар құжаттарды ғана жүктеп салуға немесе тікелей келесі қадамға өтуге болатынын ескеріңіз, бірақ қауіпсіздік қызметінде компания деректерін тексеру кезінде құжаттардың болмауы тексеру нәтижелеріне кері әсер етіп, уақытты ұзартуы мүмкін. Келісімді бекіту үшін болашақта талап етіледі%li%Мемлекеттік тіркеу туралы куәліктің көшірмесі%li%Салықтық тіркеу туралы куәліктің көшірмесі%li%Жарғының көшірмесі%li%Жалғыз құрылтайшы шешімінің көшірмесі немесе хаттама жалпы жиналыс %li%Сенімхат%li%Заңды тұлғалардың бірыңғай мемлекеттік тізілімінен үзінді көшірме %li%Бас директор паспортының көшірмесі% li% оңайлатылған салық жүйесін қолдану туралы анықтама (оңайлатылған салықты қолданған жағдайда жүйесі)',
  },
  uploadF_DocTextIP: {
    eng:
      "Please note that you can upload only those documents that you have or go straight to the next step, but when checking the company's data in the  Security Service, the lack of documents may adversely affect the results of the check and increase the time that will be required in the future for approval of the contract%li%Copy of the certificate of state registration%li%Copy of the certificate of tax registration%li%Power of attorney%li%Extract from the USRIP%li%Copy of the passport of an individual entrepreneur (page 1)%li%Copy of the passport of an individual entrepreneur ( registration)%li%Consent to data processing",
    rus:
      'Обратите внимание, что вы можете загрузить только те документы, которые у вас есть, или сразу перейти к следующему шагу, но при проверке данных компании в Службе безопасности отсутствие документов может негативно сказаться на результатах проверки и увеличит срок, который потребуется в дальнейшем для согласования договора%li%Копия свидетельства о государственной регистрации %li%Копия свидетельства о постановке на учет в налоговой%li%Доверенность%li%Выписка из ЕГРИП%li%Копия паспорта индивидуального предпринимателя (стр.1)%li%Копия паспорта индивидуального предпринимателя (прописка)%li%Согласие на обработку данных',
    kz:
      'Сізде бар құжаттарды ғана жүктеп салуға немесе тікелей келесі қадамға өтуге болатынын ескеріңіз, бірақ қауіпсіздік қызметінде компания деректерін тексеру кезінде құжаттардың болмауы тексеру нәтижелеріне кері әсер етіп, уақытты ұзартуы мүмкін. Келісімді бекіту үшін болашақта талап етіледі%li%Мемлекеттік тіркеу туралы куәліктің көшірмесі%li%Салықтық тіркеу туралы куәліктің көшірмесі%li%Жарғының көшірмесі%li%Жалғыз құрылтайшы шешімінің көшірмесі немесе хаттама жалпы жиналыс %li%Сенімхат%li%Заңды тұлғалардың бірыңғай мемлекеттік тізілімінен үзінді көшірме %li%Бас директор паспортының көшірмесі% li% оңайлатылған салық жүйесін қолдану туралы анықтама (оңайлатылған салықты қолданған жағдайда жүйесі)',
  },

  uploadF_ContactHeader: {
    eng:
      'Please indicate your full name, contact details, upload scans of passports of authorized persons, and attach scans of consents to data processing. At the stage of initial verification of the company, you can skip this step and we will return to the issue of collecting this data when negotiating the contract. For contact persons, you can issue an Appendix to the contract for the entire duration of the contract, or issue one-time urgent powers of attorney. Choose one of the options:',
    rus:
      'Пожалуйста, укажите ФИО, контактные данные, загрузите сканы паспортов доверенных лиц, а также приложите сканы согласий на обработку данных. На этапе первичной проверки компании вы можете пропустить этот шаг и мы вернемся к вопросу сбора этих данных при согласовании договора. На контактных лиц можно оформить Приложение к договору на весь срок действия договора, либо оформлять разовые срочные доверенности. Выберете один из вариантов:',
    kz:
      'Толық аты-жөніңізді, байланыс деректеріңізді көрсетіңіз, уәкілетті тұлғалардың төлқұжаттарының сканерленген нұсқасын жүктеп салыңыз және деректерді өңдеуге келісім сканерлерін тіркеңіз. Компанияны бастапқы тексеру кезеңінде сіз бұл қадамды өткізіп жібере аласыз және біз келісім-шарт бойынша келіссөздер кезінде бұл деректерді жинау мәселесіне қайта ораламыз. Байланыстағы тұлғалар үшін сіз келісім-шарттың барлық мерзіміне келісім-шартқа Қосымшаны бере аласыз немесе бір реттік жедел сенімхаттар бере аласыз. Опциялардың бірін таңдаңыз:',
  },

  Download_Form1: {
    eng: 'DOWNLOAD FORM OF POWER OF ATTORNEY',
    rus: 'СКАЧАТЬ ФОРМУ ДОВЕРЕННОСТИ',
    kz: 'СЕНІМДІЛІКТІҢ Пішіндісін ЖҮКТЕП АЛУ',
  },
  Download_Form2: {
    eng: 'DOWNLOAD CONSENT FORM',
    rus: 'СКАЧАТЬ ФОРМУ СОГЛАСИЯ',
    kz: 'КЕЛІСІМ БЕРУ ПІШІН ЖҮКТЕП АЛУ',
  },
  Annex_Button: {
    eng: 'I WILL MAKE AN APP',
    rus: 'БУДУ ОФОРМЛЯТЬ ПРИЛОЖЕНИЕ',
    kz: 'ҚОЛДАНБА ЖАСАЙМЫН',
  },

  Fiscal_Doc_Upload_Button1: {
    eng: 'Passport',
    rus: 'Паспорт',
    kz: 'Паспорт',
  },
  Fiscal_Doc_Upload_Button3: {
    eng: 'Power of attorney',
    rus: 'Доверенность',
    kz: 'Сенімхат',
  },
  Fiscal_Doc_Upload_Button4: {
    eng: 'Consent to data processing',
    rus: 'Согласие на обработку данных',
    kz: 'Деректерді өңдеуге келісім беру',
  },
  Fiscal_Region: {
    eng: 'Region',
    rus: 'Регион',
    kz: 'Аймақ',
  },
  Fiscal_Requisites: {
    eng: 'About company',
    rus: 'О компании',
    kz: 'Компания жайлы',
  },
  Fiscal_Company_Name_Header: {
    eng: 'Limited Liability Company (LLC)',
    rus: 'Общество с ограниченной ответственностью (ООО)',
    kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
  },
  Fiscal_IP_Name_Header: {
    eng: 'Individual Entrepreneur (IE)',
    rus: 'Индивидуальный предприниматель (ИП)',
    kz: 'Жеке кәсіпкер (ЖК)',
  },
  Fiscal_Current_Leader_Header: {
    eng: 'Current leader',
    rus: 'Действующий руководитель',
    kz: 'Қазіргі көшбасшы',
  },
  Fiscal_Bank_Requisites: {
    eng: 'Bank details',
    rus: 'Банковские Реквизиты',
    kz: 'Банк деректемелері',
  },
  Fiscal_Scanned_Documents: {
    eng: 'Document scans',
    rus: 'Сканы документов',
    kz: 'Құжатты сканерлеу',
  },
  Fiscal_Directors_Contacts: {
    eng: 'Contact details of the CEO',
    rus: 'Контактные данные генерального директора',
    kz: 'Бас директордың байланыс деректері',
  },
  Fiscal_Proxies_Contacts: {
    eng: 'List of Trustees',
    rus: 'Список доверенных лиц',
    kz: 'Қамқоршылардың тізімі',
  },
  Confirm_phone: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'CONFIRM PHONE',
      rus: 'ПОДТВЕРДИТЬ ТЕЛЕФОН',
      kz: 'ТЕЛЕФОНДЫ РАСТАҢЫЗ',
    },
  },
  Confirm_mail: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'CONFIRM E-MAIL',
      rus: 'ПОДТВЕРДИТЬ E-MAIL',
      kz: 'ЭЛЕКТРОНДЫ ПОШТАНЫ РАСТАУ',
    },
  },
  Confirm_button: {
    eng: 'SEND',
    rus: 'ОТПРАВИТЬ',
    kz: 'ЖІБЕРУ',
  },
  Confirm_mail_hint: {
    eng: 'Enter the code sent to your e-mail',
    rus: 'Введите код, отправленный на э-почту',
    kz: 'Электрондық поштаңызға жіберілген кодты енгізіңіз',
  },
  Confirm_phone_hint: {
    eng: 'Enter the code sent to your phone',
    rus: 'Введите код, отправленный на телефон',
    kz: 'Телефоныңызға жіберілген кодты енгізіңіз',
  },
  Confirm_input: {
    label: {
      eng: '',
      rus: '',
      kz: '',
    },
    placeholder: {
      eng: 'CODE',
      rus: 'КОД',
      kz: 'КОД',
    },
  },

  Confirm_error: {
    eng: 'Something went wrong? Please report a problem at',
    rus: 'Что-то пошло не так? Пожалуйста, сообщите о проблеме на',
    kz: 'Бірдеңе дұрыс болмады ма? Мәселе туралы мына мекенжайға хабарлаңыз',
  },
  Confirm_error_mail: {
    eng: 'client@rentaphoto.ru',
    rus: 'client@rentaphoto.ru',
    kz: 'client@rentaphoto.ru',
  },
  Confirm_mail_success: {
    eng: 'Mail confirmed',
    rus: 'Почта подтверждена',
    kz: 'Пошта расталды',
  },
  Confirm_phone_success: {
    eng: 'Phone confirmed',
    rus: 'Телефон подтвержден',
    kz: 'Телефон расталды',
  },
  Confirm_change_data: {
    eng: 'To change the data',
    rus: 'Изменить данные',
    kz: 'Деректерді өзгерту үшін',
  },

  Confirm_remember1: {
    eng: 'Your e-mail and/or phone number is not verified',
    rus: 'Ваш e-mail и/или телефон не подтвержден',
    kz: 'Электрондық поштаңыз және/немесе телефон нөміріңіз расталмаған',
  },
  Confirm_remember2: {
    eng: 'Please confirm your details',
    rus: 'Пожалуйста, подтвердите данные',
    kz: 'Мәліметтеріңізді растаңыз',
  },

  serverError: {
    eng: 'Internal server error. Please, try again later or contact us',
    rus: 'Ошибка сервера. Пожалуйста, попробуйте позже или свяжитесь с нами',
    kz:
      'Сервер қатесі. Әрекетті кейінірек қайталаңыз немесе бізге хабарласыңыз',
  },

  successSubmit: {
    head: {
      eng: 'Thank you for filling out the survey!',
      rus: 'Спасибо за заполнение анкеты!',
      kz: 'Сауалнаманы толтырғаныңыз үшін рахмет!',
    },
    header: {
      eng: 'Your application has been sent for review',
      rus: 'Ваша заявка отправлена на рассмотрение',
      kz: 'Сіздің өтінішіңіз қарауға жіберілді',
    },
    label1: {
      eng: 'Verifying your details may take',
      rus: 'Проверка ваших данных может занять',
      kz: 'Деректерді тексеру қажет болуы мүмкін',
    },
    label2: {
      eng: 'up to 5 calendar days',
      rus: 'до 5-ти календарных дней',
      kz: '5 күнтізбелік күнге дейін',
    },
    label3: {
      eng: ', but we do it faster.',
      rus: ', но мы делаем это быстрее.',
      kz: 'бірақ біз мұны тезірек жасаймыз.',
    },
    label4: {
      eng:
        'After considering the application, the manager will contact you and inform you about the decision and collateral conditions.',
      rus:
        'После рассмотрения заявки менеджер свяжется с вами и сообщит о решении и условиях гарантии.',
      kz:
        'Өтінішті қарағаннан кейін менеджер сізге хабарласып, шешім мен кепілдік шарттары туралы хабарлайды',
    },
  },
  checkProductMessage: {
    eng: 'Product available at this rental',
    rus: 'Товар в наличии в данном прокате',
    kz: 'Өнімді осы жалға алуға болады',
  },
  noCitizenshipsLink: {
    eng: 'Didn’t find my country',
    rus: 'Нет моей страны',
    kz: 'Елімді таппадым',
  },
  noCitizenshipsDesc: {
    eng:
      'If your country is not in the list, specify its name and we will add it.',
    rus:
      'Если вашей страны нет в списке, укажите ее название, и мы добавим ее.',
    kz:
      'Егер сіздің еліңіз тізімде болмаса, оның атын көрсетіңіз, біз оны қосамыз.',
  },
  identityExistHead: {
    eng: 'You dont need to fill out a questionnaire!',
    rus: 'Вам не нужно заполнять анкету!',
    kz: 'Сауалнаманы толтырудың қажеті жоқ!'
  },
  identityExistDesc: {
    eng: 'You have already used our services, so just contact the manager',
    rus: 'Вы уже пользовались нашими услугами, поэтому просто свяжитесь с менеджером',
    kz: 'Сіз біздің қызметтерді пайдаландыңыз, сондықтан менеджерге хабарласыңыз'
  },
  identityCallback: {
    eng: 'Contact the manager',
    rus: 'Связаться с менеджером',
    kz: 'Менеджерге хабарласыңыз'
  },
  noCitizenshipsPlaceholder: {
    eng: 'Country name',
    rus: 'Название страны',
    kz: 'Елдің атауы',
  },
  noCitizenshipsButton: {
    eng: 'Add',
    rus: 'Добавить',
    kz: 'Қосу',
  },
  citizenshipsInvalid: {
    eng: 'Fill in the email and mobile phone fields',
    rus: 'Заполните поля email и мобильный телефон',
    kz: 'Электрондық пошта және ұялы телефон өрістерін толтырыңыз',
  },
  voiceAndText: {
    rus: "Удобно говорить и писать", 
    eng: "It is convenient to speak and write", 
    kz: "Сөйлеуге және жазуға ыңғайлы"
  },
  voice: {
    rus: "Удобно говорить",
    eng: "It's convenient to talk", 
    kz: "Сөйлеуге ыңғайлы"
  },
  text: {
    rus: "Удобно писать", 
    eng: "It is convenient to write", 
    kz: "Жазуға ыңғайлы"
  },
  morning: {
    rus: "Утром с 8:00 до 12:00", 
    eng: "In the morning from 8:00 to 12:00", 
    kz: "Таңертең 8:00-ден 12:00-ге дейін" 
  },
  day: {
    rus: "Днем с 12:00 до 18:00", 
    eng: "In the afternoon from 12:00 to 18:00", 
    kz: "Түстен кейін 12:00-ден 18:00-ге дейін"
  },
  evening: {
    rus: "Вечером с 18:00 до 23:00",
    eng: "In the evening from 18:00 to 23:00", 
    kz: "Кешке 18:00-ден 23:00-ге дейін"
  },
  night: {
    rus: "Ночью с 23:00 до 07:00", 
    eng: "At night from 23:00 to 07:00", 
    kz: "Түнде 23:00-ден 07:00-ге дейін"
  },
}

export default accountForm

import React, { ReactNode } from "react";
import styles from './InfoMessage.module.scss'
import cn from 'classnames'
import AlarmImg from '../../images/alarm.svg'
import InfoImg from '../../images/info.svg'


interface InfoMessageProps {
    text: ReactNode
    type: "alarm" | "default"
}
export default function InfoMessage({text, type="default"}:InfoMessageProps) {
    return <div className={cn(styles.error_message, type === 'alarm' ? styles.alarm : styles.default)}>
    {type === "alarm" ? <img src={AlarmImg} alt={'alarm'}></img> : <img src={InfoImg} alt={'info'}></img>}
    {text}
  </div>
}
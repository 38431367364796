import { validate as emailValidator } from 'email-validator'
import { parseDate, yearsAgo } from '../../utils/helpers'
import {
  State,
  RUSSIAN_PASSPORT,
  profileType_Fiscal,
} from '../../state-actions-reducers'
import { citizenshipsRf } from '../../content/countries'
import { Option } from '../../types/common-types'
import { getPassportTypeTranslate } from '../../hooks/useGetPassportTypeTranslate'
import { additionalDocumentTypeValues } from '../../content/additionalDocumentType'

interface VlidateDateArgs {
  current: Date | string
  min?: Date
  max?: Date
  required?: boolean
}

export function validateDate({
  current,
  min,
  max,
  required,
}: VlidateDateArgs): boolean {
  // By default allow empty strings. Make stronger checking on submitting
  const currentValue = parseDate(current)
  if (!currentValue) {
    return !required
  }
  if (min && max) {
    return min <= currentValue && currentValue <= max
  }
  if (min) {
    return min <= currentValue
  }
  if (max) {
    return currentValue <= max
  }
  return true
}

export function validateDatesRange(from: Date | string, to: Date | string) {
  const smallerDate = parseDate(from)
  const biggerDate = parseDate(to)
  if (!smallerDate || !biggerDate) {
    return false
  }
  return smallerDate < biggerDate
}

export function validateCollegeDates(
  startDate: string | Date,
  endDate: string | Date,
  required = false
) {
  if (!startDate || !endDate) return !required
  // Check if dates is valid
  return validateDatesRange(startDate, endDate)
}

export function smartValidateString(string: string): boolean {
  const trimmedString = string.trim()
  const letter = trimmedString.match(/[a-zA-Zа-яёА-ЯЁ]/g)?.length ?? 0
  const nums = trimmedString.match(/[0-9]/g)?.length ?? 0
  const symbols =
    trimmedString.match(
      /[\<\>\@\!\#\$\%\^\&\*\(\)\_\+\[\]\{\}\?\:\;\|\'\"\,\.\/\~\`\\\-\=\']/g
    )?.length ?? 0
  return (
    /[a-zA-Zа-яёА-ЯЁ]/g.test(trimmedString) &&
    symbols <= letter * 2 &&
    nums <= letter * 4
  )
}

// By default allow empty strings
// Will make stronger validation on submitting
export function validateString(
  string: string,
  maxLength: number,
  required?: boolean,
  regexp?: RegExp
): boolean {
  const minLength = required ? 1 : 0
  const trimmedString = string.trim()
  return (
    trimmedString.length >= minLength &&
    trimmedString.length <= maxLength &&
    Boolean(trimmedString.match(regexp || ''))
  )
}

export function validateAddress(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function validateBankAccount(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function validateName(
  name: string,
  required?: boolean,
  regexp?: RegExp
): boolean {
  return validateString(name, 200, required, regexp)
}

export function birthPlace(place: string, required?: boolean): boolean {
  return validateString(place, 300, required)
}

export function stringDigitsLength(
  digits: string,
  digitslength: number,
  required = true
): boolean {
  return (
    validateString(digits, 100, false) &&
    ((required && digits.length == digitslength) || !required)
  )
}

export function passportDigits(digits: string, required?: boolean): boolean {
  return validateString(digits, 100, required)
}

export function passportText(text: string, required?: boolean): boolean {
  return validateString(text, 400, required)
}

export function validateEmail(text: string, required?: boolean): boolean {
  if (!text && !required) return true
  return validateString(text, 100, required) && emailValidator(text)
}

export function validateReferences(
  values: string[],
  required = false
): boolean {
  if (!required && values.length === 0) return true
  if (required && values.length === 0) return false
  if (values.length < 20) {
    return values.every(value => {
      return validateString(value, 200)
    })
  }
  return false
}

const URL_REGEXP = /^(https?:\/\/)?(www\.)?[-a-zа-яё0-9@:%._\+~#=]{2,256}\.[a-zа-яё]{1,4}(?::[0-9]+)?(\/?[-a-zа-яё0-9@:%_\+~#]+(\.[a-zа-яё0-9]+)?)+(\?[-а-яёa-z0-9@:%._\+~#]+(=[-a-zа-яё0-9@:%._\+~#]+)?((\&[-a-zа-яё0-9@:%._\+~#]+(=[-a-zа-яё0-9@:%._\+~#]+)?)+)?)?/gi
export function validateUrl(
  url: string,
  maxLength = 300,
  required = false
): boolean {
  if (!url && !required) return true
  if (url) {
    if (typeof url === 'string') {
      if (url.length < maxLength) {
        return Boolean(url.match(URL_REGEXP))
      }
    }
  }
  return false
}

export function validateSocialLink(value: string, required = false): boolean {
  return validateUrl(value, 300, required)
  return false
}

export function validatePhone(value: string): boolean {
  return validateString(value, 24, true) && value.length >= 11
}

export function validatePassportNumber(
  value: string,
  required = false,
  minLength?: number,
  maxLength?: number
) {
  return (
    validateString(value, maxLength || 10, required) &&
    value.replace(/[^a-zA-Zа-яА-Я0-9 ]/g, '').length >= (minLength || 7)
  )
}

export function validateStepPhysicalRus(step: number, state: State): string[] {
  let selectedCitizenship, countryRegistration
  citizenshipsRf.map((country: Option) => {
    if (country.value === state.citizenship)
      selectedCitizenship = country.label.rus
    if (country.value === state.countryRegistration)
      countryRegistration = country.label.rus
  })
  console.log('selectedCitizenship', selectedCitizenship)
  switch (step) {
    case 1:
      let citizenshipFields = []
      let addtionalDocumentsFields = []
      let secondAddtionalDocumentsFields = []
      if (selectedCitizenship === 'Россия') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 11, 11) &&
              'passportNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            !validatePassportNumber(state.passportAuthorityCode, true, 6, 7) &&
              'passportAuthorityCode',
            state.mainImageFile.length === 0 && 'mainImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(
              state.passportInternationalNumber,
              true,
              10,
              10
            ) && 'passportInternationalNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Беларусь') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 10, 10) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(state.passportPermanentResidencyNumber, true, 10, 10) &&
            'passportPermanentResidencyNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
        if (getPassportTypeTranslate(+state.passportType) === 'ID карта') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportCardNumber, true, 10, 10) &&
              'passportCardNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
      }
      if (selectedCitizenship === 'Украина') {
        if (
          getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(
              state.passportInternationalNumber,
              true,
              9,
              9
            ) && 'passportInternationalNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(state.passportPermanentResidencyNumber, true, 10, 10) &&
            'passportPermanentResidencyNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Литва') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 8, 8) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
        // if (getPassportTypeTranslate(+state.passportType) === 'Уд. личности') {
        //   citizenshipFields.push(
        //     !validateString(state.passportCardNumber, 30, true) &&
        //       'passportCardNumber',
        //     !validateDate({
        //       current: state.passportExpiryDate,
        //       min: new Date(),
        //       max: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
        //       required: true,
        //     }) && 'passportExpiryDate',
        //     state.mainImageFile.length === 0 && 'mainImageFile'
        //   )
        // }
      }
      if (selectedCitizenship === 'Индия') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 9, 9) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
        // if (getPassportTypeTranslate(+state.passportType) === 'Уд. личности') {
        //   citizenshipFields.push(
        //     !validateString(state.passportCardNumber, 30, true) &&
        //       'passportCardNumber',
        //     !validateDate({
        //       current: state.passportExpiryDate,
        //       min: new Date(),
        //       max: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
        //       required: true,
        //     }) && 'passportExpiryDate',
        //     state.mainImageFile.length === 0 && 'mainImageFile'
        //   )
        // }
      }
      if (selectedCitizenship === 'Швеция') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 8, 8) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
        // if (getPassportTypeTranslate(+state.passportType) === 'Уд. личности') {
        //   citizenshipFields.push(
        //     !validateString(state.passportCardNumber, 30, true) &&
        //       'passportCardNumber',
        //     !validateDate({
        //       current: state.passportExpiryDate,
        //       min: new Date(),
        //       max: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
        //       required: true,
        //     }) && 'passportExpiryDate',
        //     state.mainImageFile.length === 0 && 'mainImageFile'
        //   )
        // }
      }
      if (selectedCitizenship === 'Кыргызстан') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 10, 10) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
        if (getPassportTypeTranslate(+state.passportType) === 'ID карта') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportCardNumber, true, 10, 10) &&
              'passportCardNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
      }
      if (selectedCitizenship === 'Великобритания') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 9, 10) &&
              'passportNumber',
            ['GBR', 'GBD', 'GBO', 'GBS', 'GBP', 'GBN'].indexOf(state.passportAuthorityCode) < 0 &&
            'passportAuthorityCode',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Румыния') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 8, 8) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
        // if (getPassportTypeTranslate(+state.passportType) === 'Уд. личности') {
        //   citizenshipFields.push(
        //     !validateString(state.passportCardNumber, 30, true) &&
        //       'passportCardNumber',
        //     !validateDate({
        //       current: state.passportExpiryDate,
        //       min: new Date(),
        //       max: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
        //       required: true,
        //     }) && 'passportExpiryDate',
        //     state.mainImageFile.length === 0 && 'mainImageFile'
        //   )
        // }
      }
      if (selectedCitizenship === 'Германия') {
        if (
          getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(
              state.passportInternationalNumber,
              true,
              9,
              9
            ) && 'passportInternationalNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Таджикистан') {
        if (
          getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(
              state.passportInternationalNumber,
              true,
              9,
              10
            ) && 'passportInternationalNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Китай') {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, true, 10, 11) &&
            'passportNumber',
          !validateString(state.passportCardNumber, 30, true) &&
            'passportCardNumber',
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          !validatePassportNumber(state.passportAuthorityCode, true, 6, 7) &&
            'passportAuthorityCode',
          !validateDate({
            current: state.passportExpiryDate,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'passportExpiryDate',
          state.mainImageFile.length === 0 && 'mainImageFile',
          state.addressImageFile.length === 0 && 'addressImageFile'
        )
      }
      if (selectedCitizenship === 'Казахстан') {
        if (getPassportTypeTranslate(+state.passportType) === 'Уд. личности') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportCardNumber, true, 9, 9) &&
              'passportCardNumber',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            state.mainImageFile.length === 0 && 'mainImageFile',
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Паспорт'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(
              state.passportNumber,
              true,
              10,
              10
            ) && 'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(state.passportPermanentResidencyNumber, true, 10, 10) &&
            'passportPermanentResidencyNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Соединенные Штаты') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 9, 9) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Турция') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 10, 10) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile',
            state.visaImageFile.length === 0 && 'visaImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(
              state.passportPermanentResidencyNumber,
              true,
              10,
              10
            ) && 'passportPermanentResidencyNumber',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (selectedCitizenship === 'Нигерия') {
        if (getPassportTypeTranslate(+state.passportType) === 'Паспорт') {
          citizenshipFields.push(
            !validatePassportNumber(state.passportNumber, true, 10, 10) &&
              'passportNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile',
            state.visaImageFile.length === 0 && 'visaImageFile'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (
        selectedCitizenship === 'Бразилия' ||
        selectedCitizenship === 'Италия'
      ) {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, true, 10, 11) &&
            'passportNumber',
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          state.mainImageFile.length === 0 && 'mainImageFile',
          state.addressImageFile.length === 0 && 'addressImageFile'
        )
      }
      if (
        selectedCitizenship === 'Австралия' ||
        selectedCitizenship === 'Азербайджан' ||
        selectedCitizenship === 'Армения' ||
        selectedCitizenship === 'Соединенное Королевство' ||
        selectedCitizenship === 'Испания' ||
        selectedCitizenship === 'Сирийская Арабская Республика' ||
        selectedCitizenship === 'Узбекистан' ||
        selectedCitizenship === 'Франция' ||
        selectedCitizenship === 'Швейцария'
      ) {
        if (
          getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт'
        ) {
          citizenshipFields.push(
            !validatePassportNumber(
              state.passportInternationalNumber,
              true,
              9,
              10
            ) && 'passportInternationalNumber',
            !validateDate({
              current: state.passportExpiryDate,
              min: new Date(),
              max: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ),
              required: true,
            }) && 'passportExpiryDate',
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportIAt,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportIAt',
            state.mainImageFile.length === 0 && 'mainImageFile'
            // !state.passportDateBoardCheck && 'passportDateBoardCheck'
          )
        }
        if (
          getPassportTypeTranslate(+state.passportType) === 'Вид на жительство'
        ) {
          citizenshipFields.push(
            !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
            !validateDate({
              current: state.passportDateIssuePermanentResidency,
              min: yearsAgo(130),
              max: new Date(),
              required: true,
            }) && 'passportDateIssuePermanentResidency',
            state.residenceImageFile.length === 0 && 'residenceImageFile',
            state.addressImageFile.length === 0 && 'addressImageFile'
          )
        }
      }
      if (
        selectedCitizenship === 'Болгария' ||
        selectedCitizenship === 'Израиль' ||
        selectedCitizenship === 'Исландия' ||
        selectedCitizenship === 'Молдова, Республика' ||
        selectedCitizenship === 'Нидерланды' ||
        selectedCitizenship === 'Норвегия' ||
        selectedCitizenship === 'Южная Африка' ||
        selectedCitizenship === 'Вьетнам' ||
        selectedCitizenship === 'Грузия' ||
        selectedCitizenship === 'Индонезия' ||
        selectedCitizenship === 'Корея, Республика' ||
        selectedCitizenship === 'Финляндия'
      ) {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, true, 10, 11) &&
            'passportNumber',
          citizenshipFields.push(
            !validateString(state.passportPersonNumber, 30, true) &&
              'passportPersonNumber'
          ),
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          !validateDate({
            current: state.passportExpiryDate,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'passportExpiryDate',
          state.mainImageFile.length === 0 && 'mainImageFile',
          state.addressImageFile.length === 0 && 'addressImageFile'
        )
      }
      if (state.additionalDocumentType === '12') {
        addtionalDocumentsFields.push(
          !validateDate({
            current: state.additionalDocumentDateExpiry,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'additionalDocumentDateExpiry',
          !smartValidateString(state.additionalDocumentComment) &&
            'additionalDocumentComment'
        )
      }
      if (state.secondAdditionalDocumentType === '12') {
        secondAddtionalDocumentsFields.push(
          !validateDate({
            current: state.secondAdditionalDocumentDateExpiry,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'secondAdditionalDocumentDateExpiry',
          !smartValidateString(state.secondAdditionalDocumentComment) &&
            'secondAdditionalDocumentComment'
        )
      }

      if (state.additionalDocumentType !== '6' &&
        state.additionalDocumentType !== '10' &&
        state.additionalDocumentType !== '12') {
        addtionalDocumentsFields.push(
          !validateDate({
            current: state.additionalDocumentDateExpiry,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'additionalDocumentDateExpiry'
        )
      }
      if (state.secondAdditionalDocumentType !== '6' &&
        state.secondAdditionalDocumentType !== '10' &&
        state.additionalDocumentType !== '12') {
        secondAddtionalDocumentsFields.push(
          !validateDate({
            current: state.secondAdditionalDocumentDateExpiry,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'secondAdditionalDocumentDateExpiry'
        )
      }
      return [
        // Array of invalid field names
        // validate(): boolean && 'field name' -> null or 'field name'
        // Then this array filtered with the Boolean()
        !validateString(state.citizenship, 3, true, /^[0-9]{3}$/) &&
          'citizenship',
        !validateString(state.mainCity, 100, true) && 'mainCity',
        !smartValidateString(state.firstName) && 'firstName',
        !smartValidateString(state.lastName) && 'lastName',
        !smartValidateString(state.patronymic) &&
          !state.noPatronymic &&
          'patronymic',
        !smartValidateString(state.latinFirstname) && 'latinFirstname',
        !smartValidateString(state.latinLastname) && 'latinLastname',
        !smartValidateString(state.latinPatronymic) &&
          !state.noPatronymic &&
          'latinPatronymic',
        !smartValidateString(state.birthPlace) && 'birthPlace',
        !validateDate({
          current: state.birthDate,
          min: yearsAgo(130),
          max: yearsAgo(14),
          required: true,
        }) && 'birthDate',
        !validateString(state.addressFactualCountry, 300, true) &&
          'addressFactualCountry',
        !smartValidateString(state.addressFactualRegion) &&
          'addressFactualRegion',
        !smartValidateString(state.addressFactualCity) && 'addressFactualCity',
        !smartValidateString(state.addressFactualStreet) &&
          'addressFactualStreet',
        !validateString(state.addressFactualBuilding, 300, true) &&
          'addressFactualBuilding',
        !validateString(state.addressFactualApartments, 300, true) &&
          'addressFactualApartments',
        !validateDate({
          current: state.addressRegisteredDate,
          min: yearsAgo(130),
          max: new Date(),
          required: true,
        }) &&
          selectedCitizenship !== 'Казахстан' &&
          'addressRegisteredDate',
        !validateString(state.addressRegisteredCountry, 300, true) &&
          'addressRegisteredCountry',
        !smartValidateString(state.addressRegisteredRegion) &&
          'addressRegisteredRegion',
        !smartValidateString(state.addressRegisteredCity) &&
          'addressRegisteredCity',
        !smartValidateString(state.addressRegisteredStreet) &&
          'addressRegisteredStreet',
        !validateString(state.addressRegisteredBuilding, 300, true) &&
          'addressRegisteredBuilding',
        !validateString(state.addressRegisteredApartments, 300, true) &&
          'addressRegisteredApartments',
        !validateString(state.additionalDocumentType, 50, true) &&
          'additionalDocumentType',
        // !validateString(state.secondAdditionalDocumentType, 50, true) &&
        //   'secondAdditionalDocumentType',
        state.sex === '0' && 'sex',
        ...citizenshipFields,
        ...addtionalDocumentsFields,
        // ...secondAddtionalDocumentsFields,
        state.documentsPhotoOne.length === 0 && 'documentsPhotoOne',
        state.secondAdditionalDocumentType !== '0' && state.documentsPhotoTwo.length === 0 && 'documentsPhotoTwo',
        state.passportPersonNumber.replace(/[^0-9]/g, '').length > 0 &&
          state.passportPersonNumber.replace(/[^0-9]/g, '').length < 12 &&
          getPassportTypeTranslate(+state.passportType) !== 'Вид на жительство' && 
          'passportPersonNumber',
      ].filter(Boolean) as string[]
    case 2:
      let job: Array<string | boolean> = [],
        study: Array<string | boolean> = []
      if (
        state.jobOrStudy === 'Работаю' ||
        state.jobOrStudy === 'Работаю и учусь' ||
        state.jobOrStudy === "I' m Working" ||
        state.jobOrStudy === 'I work and study' ||
        state.jobOrStudy === 'Жұмыс істеу' ||
        state.jobOrStudy === 'Жұмыс және оқу'
      )
        job = [
          !smartValidateString(state.occupationInstitution) &&
            'occupationInstitution',
          !smartValidateString(state.occupationPosition) &&
            'occupationPosition',
          !state.workPhoneNumbers.some(number => validatePhone(number)) &&
            'workPhoneNumbers',
        ]
      if (
        state.jobOrStudy === 'Учусь' ||
        state.jobOrStudy === 'Работаю и учусь' ||
        state.jobOrStudy === "I'm studying" ||
        state.jobOrStudy === 'I work and study' ||
        state.jobOrStudy === 'Мен оқып жатырмын' ||
        state.jobOrStudy === 'Жұмыс және оқу'
      )
        study = [
          !smartValidateString(state.collegeName) && 'collegeName',
          !smartValidateString(state.collegeProfession) && 'collegeProfession',
          !validateDate({
            current: state.collegeStartedAt,
            max: new Date(),
            required: true,
          }) && 'collegeStartedAt',
          !validateDate({
            current: state.collegeEndedAt,
            required: true,
          }) && 'collegeEndedAt',
        ]
      return [
        !state.emails.some(
          email => validateEmail(email, true) && state.isEmailOk
        ) && 'emails',
        !state.mobilePhoneNumbers.some(
          number => validatePhone(number) && state.isPhoneOk
        ) && 'mobilePhoneNumbers',
        state?.contactPhoneSeconds?.[0]?.length > 0 &&
          !state?.contactPhoneSeconds?.some(number => validatePhone(number)) &&
          'contactPhoneSeconds',
        // state?.telegram[0]?.replace(/[^A-Za-z0-9@_]/g, '').length > 0 &&
        //   (state?.telegram[0]?.replace(/[^A-Za-z0-9_]/g, '').length < 5 ||
        //     (state?.telegram[0]?.match(/[a-zA-Zа-яёА-ЯЁ]/g) || '')?.length <
        //       1) &&
        //   'telegram',
        state?.personalWebsite[0]?.length > 0 &&
          !validateUrl(state?.personalWebsite[0], 300, true) &&
          'personalWebsite',
        !state.messengers.some(messenger =>
          validateString(messenger.type, 200, true)
        ) && 'messengersType',
        !state.messengers.some(messenger =>
          validateString(messenger.link, 200, true)
        ) && 'messengersLink',
        !state.additionalLinksDescription.some(desc =>
          validateString(desc, 300, true)
        ) && 'additionalLinksDescription',
        !state.additionalLinks.some(links =>
          validateString(links, 200, true)
        ) && 'additionalLinks',
        !validateString(state.jobOrStudy, 30, true) && 'jobOrStudy',
        ...job,
        ...study,
      ].filter(Boolean) as string[]
    case 3:

    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    default:
      return []
  }
}

export function validateForm(state: State): string[] {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce(
    (result: string[], step: number): string[] => [
      ...result,
      ...validateStepPhysicalRus(step, state),
    ],
    []
  )
}

export function trimForm(state: State): State {
  return Object.keys(state).reduce((trimmedState: State, key) => {
    switch (key) {
      case 'mobilePhoneNumbers':
      case 'F_WorkPhoneNumber':
      case 'contactPhones':
      case 'addressRegisteredPhones':
      case 'addressFactualPhones':
      case 'workPhoneNumbers':
        // Filter out invalid values
        return {
          ...trimmedState,
          [key]: state[key].filter(validatePhone),
        }
      case 'emails':
        return {
          ...trimmedState,
          emails: state.emails.filter(email => validateEmail(email, true)),
        }
      case 'F_Email':
        return {
          ...trimmedState,
          emails: state.emails.filter(email => validateEmail(email, true)),
        }
      case 'vk':
      case 'facebook':
      case 'youtube':
      case 'vimeo':
      case 'instagram':
      case 'workWebsite':
      case 'personalWebsite':
      case 'additionalLinks':
      case 'addressRegisteredDate':
        return {
          ...trimmedState,
          addressRegisteredDate: validateDate({
            current: state.addressRegisteredDate,
            max: new Date(),
          })
            ? state.addressRegisteredDate
            : '',
        }
      case 'isStudent':
        return {
          ...trimmedState,
          collegeStartedAt: state.isStudent ? state.collegeStartedAt : '',
          collegeEndedAt: state.isStudent ? state.collegeEndedAt : '',
        }
      default:
        return trimmedState
    }
  }, state)
}

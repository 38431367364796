import React, { useEffect, useMemo, useState } from 'react'
import Select, { Props } from 'react-select'
import { ValueType, ActionMeta } from 'react-select/src/types'

import SelectInputDropdownButton from '../Inputs/SelectInputDropdownButton'
import { SelectInputProps, Option } from '../../types/common-types'
import { generateId } from '../../utils/helpers'
import selectArrow from '../../images/select-arrow.svg'
import Creatable, { CreatableProps } from 'react-select/creatable';

import styles from '../Inputs/SelectInput.module.scss'
import CustomSelect from './CustomSelectInput'

function SelectInput({
  disabled,
  lang,
  content,
  noOptionsMessage,
  onChange,
  value,
  optionValue,
  options,
  valid,
  className,
  customInputId,
  components,
  help,
  prefix,
  creatable,
  isSearchable,
  ...props
}: SelectInputProps) {
  const id = useMemo(generateId, [])
  const [optionsList, setOptionsList] = useState([{ label: '', value: '' }])
  useEffect(() => {
    let list = options.map(option => {
      return { value: option.value, label: option?.label?.[lang] ?? '' }
    })
    setOptionsList(list)
  }, [lang, options, value])
  const selected = useMemo(() => {
    return (
      optionsList.find(e => {
        return e.value === value
      }) || null
    )
  }, [value, optionsList])
  const selectedOptionValue = useMemo(() => {
    return optionValue?.value ? optionValue : null
  }, [optionValue])
  
  const [showHelp, setShowHelp] = useState(false)
  return (
    <label data-test-id={props.testName} htmlFor={customInputId || id} className={styles.label}>
      {content.label[lang] != '' ? (
        <p className={styles.labelText}>
          {content.label[lang]}
          {help ? (
            <span
              className={styles.help}
              onClick={() => {
                setShowHelp(!showHelp)
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.12642 11.2572V11.1975C8.13305 10.5645 8.19934 10.0607 8.32528 9.68617C8.45123 9.31164 8.63021 9.00838 8.86222 8.77637C9.09422 8.54436 9.37263 8.33058 9.69744 8.13503C9.89299 8.01571 10.0687 7.87485 10.2244 7.71245C10.3802 7.54673 10.5028 7.35615 10.5923 7.14071C10.6851 6.92528 10.7315 
            6.68664 10.7315 6.4248C10.7315 6.09999 10.6553 5.81827 10.5028 5.57963C10.3504 5.341 10.1465 5.15705 9.89134 5.02779C9.63613 4.89853 9.35275 
            4.8339 9.04119 4.8339C8.76941 4.8339 8.50758 4.89024 8.25568 5.00293C8.00379 5.11562 7.79332 5.29294 7.62429 5.53489C7.45526 5.77684 7.35748 6.09337 
            7.33097 6.48446H6.07812C6.10464 5.92102 6.25047 5.43877 6.51562 5.03773C6.78409 4.63669 7.13707 4.33011 7.57457 4.11799C8.01539 3.90587 8.50426 3.7998 9.04119 3.7998C9.62453 3.7998 10.1316 3.91581 10.5625 4.14782C10.9967 4.37982 11.3314 4.69801 11.5668 5.10236C11.8054 5.50672 11.9247 5.96742 11.9247 6.48446C11.9247 6.84905 11.8684 7.17883 11.7557 7.47381C11.6463 7.76879 11.4872 8.03229 11.2784 8.26429C11.0729 8.4963 10.8243 8.70179 10.5327 8.88077C10.241 9.06306 10.0073 9.2553 9.83168 9.45748C9.65601 9.65634 9.52841 9.89332 9.44886 10.1684C9.36932 10.4435 9.32623 10.7865 9.3196 11.1975V11.2572H8.12642ZM8.76278 14.2004C8.51752 14.2004 8.30705 14.1125 8.13139 13.9369C7.95573 13.7612 7.8679 13.5508 7.8679 13.3055C7.8679 13.0602 7.95573 12.8498 8.13139 12.6741C8.30705 12.4984 8.51752 12.4106 8.76278 12.4106C9.00805 12.4106 9.21851 12.4984 9.39418 12.6741C9.56984 12.8498 9.65767 13.0602 9.65767 
            13.3055C9.65767 13.4679 9.61624 13.617 9.53338 13.7529C9.45384 13.8888 9.34612 13.9982 9.21023 14.0811C9.07765 14.1606 8.9285 14.2004 8.76278 14.2004Z"
                  fill={showHelp ? '#A0D7F3' : '#D0D1D3'}
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="17"
                  height="17"
                  rx="8.5"
                  stroke={showHelp ? '#A0D7F3' : '#D0D1D3'}
                />
              </svg>
              {showHelp ? <span>{help[lang]}</span> : null}
            </span>
          ) : (
            false
          )}
        </p>
      ) : (
        ''
      )}
      {/* <p className={styles.labelText}>{content.label[lang]}</p> */}
      <CustomSelect
        components={{
          DropdownIndicator: SelectInputDropdownButton,
          ...components,
        }}
        inputId={customInputId || id}
        noOptionsMessage={({ inputValue }) =>
          `${inputValue} ${noOptionsMessage[lang]}`
        }
        placeholder={noOptionsMessage[lang]}
        isDisabled={disabled}
        options={optionsList}
        isSearchable={!!isSearchable}
        creatable={!!creatable}
        formatCreateLabel={(inputValue: string) => inputValue}
        value={selectedOptionValue ? selectedOptionValue : selected}
        onChange={e => {
          console.log('change', e)
          onChange(e?.value ?? '')
        }}
        blurInputOnSelect
        captureMenuScroll
        className={`${styles.inputContainer} ${styles.label} ${
          valid ? '' : styles.invalid
        }`}
        classNamePrefix={prefix ?? styles.inputItem}
        styles={{
          valueContainer: styles => ({
            ...styles,
            boxSizing: 'border-box',
            fontStyle: 'normal',
            fontSize: '15px',
            height: '52px',
            padding: '17px 20px',
            borderRadius: '7px',
            fontWeight: 'normal',
          }),
          control: (provided, { isFocused }) => ({
            ...provided,
            border: '1px solid #E4E5E7',
            borderRadius: '7px',
            outline: '0',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            background: isFocused ? '#F8F8F8' : '#fffff',
          }),
          option: (styles, { isSelected }) => ({
            ...styles,
            border: 'none',
            color: isSelected ? '#3E85FD' : '#222222',
            background: isSelected ? '#f6fbfe' : '#fffff',
            '&:hover': {
              background: '#f6fbfe',
            },
            fontWeight: '400',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: '#b8b8b8',
          }),
          indicatorsContainer: styles => ({
            background: `url(${selectArrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: 'center',
            width: '13.34px',
            height: '7.66px',
            margin: '0 19.33px 0 0',
          }),
        }}
      />
      {!valid && content.invalidText ? (
        <span data-test-id={props.testErrorName} className={styles.invalidText}>{content.invalidText[lang]}</span>
      ) : null}
    </label>
  )
}

export default SelectInput
